import styled from "styled-components";
import { Form as ANTForm } from "antd";
import { themeProp } from "../theme";
import TooltipIcon from "./TooltipIcon";

const Form = styled(ANTForm).attrs(() => ({}))`
  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-form-item:last-of-type {
    margin-bottom: 0;
  }

  .ant-form-item-required::before {
    display: none !important;
  }

  & .ant-form-item.checkbox {
    margin-bottom: 0;
  }

  &.login.ant-form-horizontal {
    max-width: 435px;

    .ant-form-item-control {
      max-width: 100%;
      width: 100%;
    }
  }

  & .ant-form-item-label {
    max-height: 30px;
  }

  & .ant-form-item-control-input {
    align-items: flex-start;
  }
`;

export default Form;

export const FormItem = styled(ANTForm.Item)`
  &.ant-form-item,
  &.ant-form-item label {
    font-family: ${themeProp("typography.body.fontFamily")};
  }
`;

export const FormItemLabel = ({ label, tooltip }) => {
  return (
    <>
      {label}&nbsp;
      <TooltipIcon title={tooltip} />
    </>
  );
};
