import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";
import { themeProp } from "../../../theme";
import Card, { CardHeader, CardBody } from "../../../components/Card";
import Heading from "../../../components/Heading";
import TooltipIcon from "../../../components/TooltipIcon";
import Text from "../../../components/Text";
import Toggle from "../../../components/Toggle";
import Currency from "../../../components/Currency";
import { useString as s } from "../../../components/StringProvider";
import { CategoryName } from "../../../components/Discovery";
import TextBody from "../../../components/TextBody";
import {
  selectDiscoveryCurrency,
  selectDiscoveryId,
  selectDiscoveryKPIs,
  selectDiscoveryReportId,
  selectDiscoveryROI,
  selectDiscoveryScenario
} from "../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { selectImpactValue } from "../../../store/selectors/discovery";
import { saveDiscoveryROIBenefits } from "../../../store/actions/discoveries";
import { previewReportCashflow } from "../../../store/actions/reports";
import { Space } from "antd";

const selectBenefits = (kpis) =>
  _.chain(kpis)
    .filter((kpi) => kpi.hasImpactValue)
    .filter((kpi) => kpi.selected && kpi.relevant)
    .value();

const selectBenefitTotal = (kpis, categoryCodes, oneTimeBenefit, impactValue) =>
  kpis
    .filter((kpi) => categoryCodes.includes(kpi.definition.categoryCode))
    .filter((kpi) => oneTimeBenefit.includes(!!kpi.definition.oneTimeBenefit))
    .reduce((sum, kpi) => sum + kpi[impactValue], 0);

const ROIBenefit = ({
  discoveryKPIs,
  discoveryId,
  reportId,
  roi,
  saveDiscoveryROIBenefits,
  previewReportCashflow,
  reportMode,
  scenario,
  currency
}) => {
  const impactValue = useMemo(() => selectImpactValue(scenario), [scenario]);
  const benefits = useMemo(
    () => selectBenefits(discoveryKPIs),
    [discoveryKPIs]
  );
  const allCategories = useMemo(
    () => _.chain(benefits).groupBy("definition.categoryCode").keys().value(),
    [benefits]
  );
  const [selected, setSelected] = useState(
    allCategories.reduce(
      (result, item) => ({
        ...result,
        [item]: roi.benefits ? _.defaultTo(roi.benefits[item], true) : true
      }),
      {}
    )
  );
  const benefitTotal = useMemo(
    () =>
      selectBenefitTotal(
        benefits,
        allCategories.filter((categoryCode) => selected[categoryCode]),
        [false],
        impactValue
      ),
    [benefits, allCategories, selected, impactValue]
  );
  const oneTimeBenefitTotal = useMemo(
    () =>
      selectBenefitTotal(
        benefits,
        allCategories.filter((categoryCode) => selected[categoryCode]),
        [true],
        impactValue
      ),
    [benefits, allCategories, selected, impactValue]
  );
  const toggleRow = (key) => (value) => {
    const newSelected = {
      ...selected,
      [key]: value
    };
    setSelected(newSelected);

    if (!reportMode) {
      saveDiscoveryROIBenefits({ discoveryId, benefits: newSelected });
    } else {
      previewReportCashflow({ reportId, benefits: newSelected });
    }
  };
  const showOneTimeBenefit = !!benefits.find(
    (kpi) => kpi.definition.oneTimeBenefit
  );
  const oneTimeBenefit = s(
    "discovery.roi.summary.annualBenefit.oneTime",
    "Total One-Time Benefit"
  );

  useEffect(() => {
    setSelected(
      allCategories.reduce(
        (result, item) => ({
          ...result,
          [item]: roi.benefits ? _.defaultTo(roi.benefits[item], true) : true
        }),
        {}
      )
    );
  }, [roi, benefits]);

  return (
    <Card data-cy={"financial-benefits-box"}>
      <CardHeader>
        <Heading data-cy={"financial-benefits-box-header"} level="h5" bold>
          {s(
            "discovery.roi.summary.annualBenefit.header",
            "Financial Benefits"
          )}{" "}
          <TooltipIcon
            dataCy={"financial-benefits-box-header-tooltip-icon"}
            title={s(
              "discovery.roi.summary.annualBenefit.tooltip",
              "Which Categories of benefit should be included in the evaluation e.g. is there a category that only has softer benefits that are unlikely to be realised and should be excluded?"
            )}
          />
        </Heading>
        <Text variant="small">
          {s("discovery.roi.summary.annualBenefit.subheader", "by category")}
        </Text>
      </CardHeader>
      <CardBody>
        <Space size={16} direction={"vertical"} style={{ width: "100%" }}>
          {allCategories.map((categoryCode, index) => (
            <ToggleRow
              label={<CategoryName categoryCode={categoryCode} />}
              checked={selected[categoryCode]}
              onChange={(value) => toggleRow(categoryCode)(value)}
              key={index}
            >
              <Currency
                currency={currency}
                data-cy={`category-amount-${categoryCode}`}
              >
                {Math.round(
                  selectBenefitTotal(
                    benefits,
                    [categoryCode],
                    [true, false],
                    impactValue
                  )
                )}
              </Currency>
            </ToggleRow>
          ))}
        </Space>
        <Divider />
        <Space size={16} direction={"vertical"} style={{ width: "100%" }}>
          <Total>
            <TotalLabel>
              {s(
                "discovery.roi.summary.annualBenefit.total",
                "Total Annual Benefit"
              )}
            </TotalLabel>
            <TotalValue>
              <Currency currency={currency} data-cy={"category-total-amount"}>
                {Math.round(benefitTotal)}
              </Currency>
            </TotalValue>
          </Total>
          {showOneTimeBenefit && (
            <Total>
              <TotalLabel>{oneTimeBenefit}</TotalLabel>
              <TotalValue>
                <Currency
                  currency={currency}
                  data-cy={"category-one-time-benefit-amount"}
                >
                  {Math.round(oneTimeBenefitTotal)}
                </Currency>
              </TotalValue>
            </Total>
          )}
        </Space>
      </CardBody>
    </Card>
  );
};

const ToggleRow = ({ label, children, checked, onChange }) => {
  return (
    <ToggleRowContainer>
      <Toggle checked={checked} onChange={onChange} />
      <ToggleRowLabel>{label}</ToggleRowLabel>
      <ToggleRowText>{children}</ToggleRowText>
    </ToggleRowContainer>
  );
};
const ToggleRowContainer = styled.div`
  display: flex;
`;
const ToggleRowLabel = styled(TextBody)`
  margin-left: 8px;
`;
const ToggleRowText = styled(Text).attrs({
  variant: "h4"
})`
  text-align: right;
  flex: 1;
`;
const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${themeProp("palette.secondary")};
  margin-top: 16px;
  margin-bottom: 8px;
`;
const Total = styled.div`
  display: flex;
  justify-content: space-between;
`;
const TotalLabel = styled(Text)`
  font-weight: 700;
`;
const TotalValue = styled(Text).attrs({ variant: "h4" })`
  font-weight: bold;
`;

ROIBenefit.propTypes = {
  reportMode: PropTypes.bool
};

const mapStateToProps = (state) => ({
  scenario: selectDiscoveryScenario(state),
  discoveryKPIs: selectDiscoveryKPIs(state),
  discoveryId: selectDiscoveryId(state),
  reportId: selectDiscoveryReportId(state),
  roi: selectDiscoveryROI(state),
  currency: selectDiscoveryCurrency(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveDiscoveryROIBenefits,
      previewReportCashflow
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ROIBenefit
);
