import httpService from "./http.service";

const companyResearch = ({
  email,
  companyName,
  companyWebsite,
  productName,
  marketSegment,
  top5Competitors
}) =>
  httpService
    .post(`/ai/companyResearch`, {
      email,
      companyName,
      companyWebsite,
      productName,
      marketSegment,
      top5Competitors
    })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  researchCompany: companyResearch
};

export default methods;
