import React from "react";
import styled from "styled-components";
import { useString as s } from "../../../components/StringProvider";
import { themeProp } from "../../../theme";
import Heading from "../../../components/Heading";
import Card, { CardHeader, CardBody } from "../../../components/Card";
import Metric from "../../../components/Metric";
import Currency from "../../../components/Currency";
import { useMobileMediaQuery } from "../../../components/Responsive";
import {
  selectDiscoveryCashflow,
  selectDiscoveryCurrency,
  selectDiscoveryROI
} from "../../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import * as formatting from "../../../utils/formatting";
import { useSetting } from "../../../components/SettingsProvider";

const ROIStatistics = ({ cashflow, roi, currency, useMaxWidth = true }) => {
  const isMobile = useMobileMediaQuery();
  const {
    totalInvestment,
    totalBenefit,
    totalNetBenefit,
    annualNetBenefit,
    returnOnInvestmentPercentage,
    paybackPeriod,
    paybackPeriodMonths,
    isMaxPaybackPeriod
  } = cashflow;
  const locale = useSetting("locale", "en-GB");
  const { evaluationPeriod } = roi;
  const moreThanText = s(
    "discovery.roi.summary.statistics.paybackPeriod.moreThan",
    "More than {paybackPeriod, select, 1 {{paybackPeriod} Year} other {{paybackPeriod} Years}}",
    { paybackPeriod }
  );
  const lessThanText = s(
    "discovery.roi.summary.statistics.paybackPeriod.lessThan",
    "Approx. {paybackPeriodMonths, select, 1 {{paybackPeriodMonths} Month} other {{paybackPeriodMonths} Months}}",
    { paybackPeriodMonths, paybackPeriod }
  );

  return (
    <Container
      useMaxWidth={useMaxWidth}
      className={isMobile ? "mobile" : undefined}
    >
      <CardHeader>
        <Header level="h5" bold>
          {evaluationPeriod}{" "}
          {s(
            "discovery.roi.summary.statistics.header",
            "Year Return On Investment"
          )}
        </Header>
      </CardHeader>
      <CardBody>
        <Body>
          <Column>
            <Metric
              label={s(
                "discovery.roi.summary.statistics.metric1.label",
                "Investment"
              )}
            >
              <Currency
                format="short"
                currency={currency}
                data-cy={"investment-amount"}
              >
                {totalInvestment}
              </Currency>
            </Metric>
            <Metric
              label={s(
                "discovery.roi.summary.statistics.metric2.label",
                "Benefit"
              )}
            >
              <Currency
                format="short"
                currency={currency}
                data-cy={"benefit-amount"}
              >
                {totalBenefit}
              </Currency>
            </Metric>
            <Separator />
            <Metric
              label={s(
                "discovery.roi.summary.statistics.metric3.label",
                "Net Benefit"
              )}
              large
              active
            >
              <Currency
                format="short"
                currency={currency}
                data-cy={"net-benefit-amount"}
              >
                {totalNetBenefit}
              </Currency>
            </Metric>
          </Column>
          <Column>
            <Metric
              label={s("discovery.roi.summary.statistics.metric4.label", "ROI")}
              help={s(
                "discovery.roi.summary.statistics.metric4.tooltip",
                "ROI = (Total Benefit - Total Investment) / Total Investment"
              )}
              tooltipColor={"surface"}
              large
              active
              data-cy={"roi-percentage"}
            >
              {formatting.formatROIPercentage({
                value: returnOnInvestmentPercentage,
                options: { locale }
              })}
            </Metric>
            <Separator />
            <Metric
              label={s(
                "discovery.roi.summary.statistics.metric5.label",
                "Payback"
              )}
              help={s(
                "discovery.roi.summary.statistics.metric5.tooltip",
                "Simple estimate of the payback period to the nearest month"
              )}
              tooltipColor={"surface"}
              data-cy={"payback"}
            >
              {isMaxPaybackPeriod ? moreThanText : lessThanText}
            </Metric>
            <Metric
              label={s(
                "discovery.roi.summary.statistics.metric6.label",
                "Annual Net Benefit"
              )}
              help={s(
                "discovery.roi.summary.statistics.metric6.tooltip",
                "Annual net benefit once the solution is in steady state"
              )}
              tooltipColor={"surface"}
            >
              <Currency
                format="short"
                currency={currency}
                data-cy={"annual-net-benefit"}
              >
                {annualNetBenefit}
              </Currency>
            </Metric>
          </Column>
        </Body>
      </CardBody>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  currency: selectDiscoveryCurrency(state),
  roi: selectDiscoveryROI(state),
  cashflow: selectDiscoveryCashflow(state)
});

export default compose(connect(mapStateToProps))(ROIStatistics);

const Container = styled(Card)`
  &:not(.mobile) {
    max-width: ${({ useMaxWidth }) => (useMaxWidth ? "450px" : null)};
  }

  display: flex;
  flex-direction: column;
  background: ${themeProp("palette.secondary")};
  color: white;
`;
const Header = styled(Heading)`
  color: white;
  opacity: 0.75;
  margin-bottom: 16px;
`;
const Body = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 150px;
  margin-right: 16px;
  &:last-child {
    margin-right: 0;
  }
`;
const Separator = styled.div`
  height: 1px;
  background: ${themeProp("palette.gray2")};
  width: 32px;
  margin: 8px 0;
`;
