import Scenarios from "./components/Scenarios";
import DelayTimelineOptions from "./components/DelayTimelineOptions";

const getDelayValue = ({ value, delayTimeline }) => {
  switch (delayTimeline) {
    case DelayTimelineOptions.YEAR:
      return value;
    case DelayTimelineOptions.QUARTER:
      return value / 4;
    case DelayTimelineOptions.MONTH:
      return value / 12;
  }
};

export const getValue = ({ kpi, scenario, delayTimeline }) => {
  if (kpi.hasImpactValue) {
    switch (scenario) {
      case Scenarios.MINIMUM:
        return getDelayValue({ value: kpi.lowImpactValue, delayTimeline });
      case Scenarios.POTENTIAL:
        return getDelayValue({
          value: kpi.potentialImpactValue,
          delayTimeline
        });
      default:
        return getDelayValue({ value: kpi.expectedImpactValue, delayTimeline });
    }
  } else {
    switch (scenario) {
      case Scenarios.MINIMUM:
        return getDelayValue({ value: kpi.lowImpactPercentage, delayTimeline });
      case Scenarios.POTENTIAL:
        return getDelayValue({
          value: kpi.highImpactPercentage,
          delayTimeline
        });
      default:
        const value = (kpi.lowImpactPercentage + kpi.highImpactPercentage) / 2;
        return getDelayValue({ value, delayTimeline });
    }
  }
};
