import types from "../actionTypes";
import LoadingState from "../../utils/loading-state";

const INITIAL_STATE = {
  loadingState: LoadingState.UNINITIALIZED,
  users: [],
  error: null,
  operationId: false,
  operationUserId: false
};

const users = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.USERS_FETCH_REQUEST:
      return {
        ...state,
        loadingState: LoadingState.IN_PROGRESS,
        error: null
      };
    case types.USERS_FETCH_SUCCESS:
      return {
        ...state,
        loadingState: LoadingState.COMPLETED,
        users: action.payload.data.users,
        pagination: action.payload.data.pagination
      };
    case types.USERS_FETCH_FAILURE:
      return {
        ...state,
        loadingState: LoadingState.FAILED,
        error: action.payload.error
      };
    case types.USERS_DISCOVERY_OWNERS_FETCH_REQUEST:
      return {
        ...state,
        loadingState: LoadingState.IN_PROGRESS,
        error: null
      };
    case types.USERS_DISCOVERY_OWNERS_FETCH_SUCCESS:
      return {
        ...state,
        loadingState: LoadingState.COMPLETED,
        usersDiscoveryOwners: action.payload.data
      };
    case types.USERS_DISCOVERY_OWNERS_FETCH_FAILURE:
      return {
        ...state,
        loadingState: LoadingState.FAILED,
        error: action.payload.error
      };
    case types.USERS_DEACTIVATE_SUCCESS:
    case types.USERS_REACTIVATE_SUCCESS:
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: replaceUserInUsers({
          users: state.users,
          user: action.payload
        })
      };

    case types.CREATE_USER_SUCCESS:
      return {
        ...state
      };

    case types.START_ACCESS_MGMT_OPERATION:
      return {
        ...state,
        operationId: action.payload.operationId,
        operationUserId: action.payload.userId,
        operationRoleId: action.payload.roleId
      };

    case types.STOP_ACCESS_MGMT_OPERATION:
      return {
        ...state,
        operationId: false,
        operationUserId: false,
        operationRoleId: false
      };

    default:
      return state;
  }
};

const replaceUserInUsers = ({ users, user }) => {
  return users.reduce(
    (list, item) =>
      item.userId === user.userId ? [...list, user] : [...list, item],
    []
  );
};

export const selectUsersLoadingState = (usersData) => usersData.loadingState;
export const selectAllUsers = (usersData) => usersData.users;
export const selectUser = (usersData) => usersData.user;
export const selectUserFromUsers = (usersData, userId) =>
  usersData.users.find((user) => user.userId === userId);
export const selectUsersDiscoveryOwners = (usersData) =>
  usersData.usersDiscoveryOwners;
export const selectUsersPagination = (usersData) => usersData.pagination;
export const selectUsersOperationId = (usersData) => usersData.operationId;
export const selectUsersOperationUserId = (usersData) =>
  usersData.operationUserId;
export const selectUsersOperationRoleId = (usersData) =>
  usersData.operationRoleId;

export default users;
