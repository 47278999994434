import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Text } from "../../components";
import { compose } from "redux";
import { useString as s } from "../../components/StringProvider";
import Link from "../../components/Link";
import { EmailInput, PasswordInput } from "../../components/Input";
import { selectRequestError } from "../../store/reducers";
import Heading from "../../components/Heading";
import Form, { FormItem } from "../../components/Form";
import NewButton from "../../components/NewButton";
import Hyperlink from "../../components/Hyperlink";
import ButtonGroup from "../../components/ButtonGroup";
import LoginFormContainer from "../Login/LoginFormContainer";
import ContinueWithSalesforce from "../Login/ContinueWithSSO";
import actionTypes from "../../store/actionTypes";

const SignUpForm = ({ onFinish, registrationError }) => {
  const privacyText = s(
    "signup.page.privacy",
    "By signing up, you’re agreeing to our"
  );
  const emailValidation = s(
    "signup.page.email.validation",
    "Please input your email"
  );
  const emailPlaceholder = s("signup.page.email.placeholder", "Your email");
  const passwordValidationEmpty = s(
    "signup.page.password.validation.empty",
    "Please input your password"
  );
  const passwordValidationMinLength = s(
    "signup.page.password.validation.minLength",
    "Please enter a password of at least 8 characters"
  );
  const passwordPlaceholder = s("signup.page.password.placeholder", "Password");
  const privacyPolicyText = s("signup.page.privacyPolicy", "Privacy Policy");
  const confirmPasswordPlaceholder = s(
    "signup.confirmPassword.placeholder",
    "Confirm password"
  );
  const confirmPasswordValidationError = s(
    "signup.confirmPassword.validation",
    "Please confirm your password"
  );
  const confirmPasswordMismatchError = s(
    "signup.confirmPassword.mismatch",
    "Confirm password must match password"
  );

  const registrationErrors = {
    "error.registerUser.invalidDomain": s(
      "error.registerUser.invalidDomain",
      "Sorry, you cannot sign up from this domain."
    ),
    "error.registerUser.emailInUse": s(
      "error.registerUser.emailInUse",
      "Sorry, this email is already in use."
    )
  };
  const defaultRegistrationError = s(
    "error.registerUser.failed",
    "Sorry, registration failed."
  );

  const [error, setError] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    if (registrationError) {
      setError(
        registrationErrors[registrationError?.data?.message] ||
          defaultRegistrationError
      );
    }
  }, [registrationError]);

  const onChange = () => {
    setError("");
  };

  return (
    <LoginFormContainer>
      <Heading level={"h2"}>
        {s("signup.page.header", "Welcome to {companyName}")}
      </Heading>
      <div className={"subheader"}>
        {s(
          "signup.page.subheader",
          "Create your account by filling out the form below"
        )}
      </div>
      <ContinueWithSalesforce />
      <Form
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        className={"login"}
        layout={"vertical"}
      >
        <FormItem
          name="email"
          rules={[
            {
              required: true,
              message: emailValidation,
              type: "email"
            }
          ]}
        >
          <EmailInput placeholder={emailPlaceholder} onChange={onChange} />
        </FormItem>
        <FormItem
          name="password"
          rules={[
            {
              required: true,
              message: passwordValidationEmpty
            },
            {
              min: 8,
              message: passwordValidationMinLength
            }
          ]}
          hasFeedback
        >
          <PasswordInput
            placeholder={passwordPlaceholder}
            onChange={onChange}
          />
        </FormItem>

        <FormItem
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: confirmPasswordValidationError
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(confirmPasswordMismatchError));
              }
            })
          ]}
          hasFeedback
          dependencies={["password"]}
        >
          <PasswordInput
            autoComplete="new-password"
            placeholder={confirmPasswordPlaceholder}
          />
        </FormItem>

        {!!error && (
          <div className={"error-wrapper"}>
            <Text color={"error"}>{error}</Text>
          </div>
        )}

        <Privacy>
          <Text variant={"small"} color="gray4">
            {privacyText}{" "}
            <Hyperlink to={"https://cuvama.com/privacy-policy/"}>
              {privacyPolicyText}
            </Hyperlink>
          </Text>
        </Privacy>

        <ButtonGroup mobile={true}>
          <NewButton type={"submit"}>
            {s("signup.page.button", "Sign Up")}
          </NewButton>
        </ButtonGroup>
      </Form>
      <div className={"sign-in"}>
        {s("signup.page.textfield1", "Already registered?")}{" "}
        <Link to="/login" data-cy={"log-in-link"}>
          {s("signup.page.textfield1.link", "Sign in")}
        </Link>
      </div>
    </LoginFormContainer>
  );
};

const Privacy = styled.div`
  margin-top: 48px;
  margin-bottom: 24px;
`;

function mapStateToProps(state) {
  return {
    registrationError: selectRequestError(
      state,
      actionTypes.REGISTER_USER_REQUEST
    )
  };
}

export default compose(withRouter, connect(mapStateToProps))(SignUpForm);
