import { useString as s } from "../../components/StringProvider";
import { notification, Space } from "antd";
import TextBody from "../../components/TextBody";
import AboutThisCard from "../../components/Discovery/AboutThisCard";
import { useEffect } from "react";
import {
  selectDiscoveryDifference,
  selectDiscoveryKPI
} from "../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import { Text } from "../../components";
import styled from "styled-components";
import ScrollZone from "../../components/ScrollZone";
import { useMobileOrTabletMediaQuery } from "../../components/Responsive";

const Lists = {
  ADDED: "added",
  REMOVED: "removed",
  UPDATED: "updated"
};

Lists.ALL = [Lists.REMOVED, Lists.ADDED, Lists.UPDATED];

const KPIDifferenceNotification = ({ difference, state }) => {
  const isMobileOrTablet = useMobileOrTabletMediaQuery();
  const header = s(
    "discovery.kpi.page.differenceNotification.header",
    "Outcomes list was updated to match Solutions in scope. Here’s what has changed."
  );
  const viewDetails = s(
    "discovery.kpi.page.differenceNotification.viewDetails",
    "View Details"
  );
  const listHeaders = {};

  listHeaders[Lists.ADDED] = s(
    "discovery.kpi.page.differenceNotification.added",
    "Outcomes Added:"
  );
  listHeaders[Lists.REMOVED] = s(
    "discovery.kpi.page.differenceNotification.removed",
    "Outcomes Removed:"
  );
  listHeaders[Lists.UPDATED] = s(
    "discovery.kpi.page.differenceNotification.updated",
    "Outcomes Updated:"
  );

  useEffect(() => {
    if (
      difference &&
      (difference.kpis.added.length ||
        difference.kpis.removed.length ||
        difference.kpis.updated.length)
    ) {
      notification.info({
        description: (
          <Space direction={"vertical"} size={16} style={{ width: "100%" }}>
            <TextBody>{header}</TextBody>
            <AboutThisCard text={viewDetails}>
              <ListContainer>
                <Space direction={"vertical"} size={16}>
                  {Lists.ALL.map((listName) =>
                    difference.kpis[listName].length > 0 ? (
                      <Space
                        direction={"vertical"}
                        size={8}
                        key={listName}
                        style={{ marginBottom: "8px" }}
                      >
                        <Text variant={"bMedium"}>
                          <b>{listHeaders[listName]}</b>
                        </Text>
                        {difference.kpis[listName].map((kpiCode) => (
                          <Text key={kpiCode}>
                            {" "}
                            {selectDiscoveryKPI(state, kpiCode).definition.name}
                          </Text>
                        ))}
                      </Space>
                    ) : null
                  )}
                </Space>
              </ListContainer>
            </AboutThisCard>
          </Space>
        ),
        placement: "bottomLeft",
        bottom: isMobileOrTablet ? 120 : 50,
        duration: 0
      });
    }
  }, [difference]);

  return null;
};

const ListContainer = styled(ScrollZone)`
  max-height: 200px;
  overflow-y: scroll;
`;

const mapStateToProps = (state) => {
  return {
    difference: selectDiscoveryDifference(state),
    state
  };
};

export default compose(connect(mapStateToProps))(KPIDifferenceNotification);
