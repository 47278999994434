import Form, { FormItem } from "../../components/Form";
import { EmailInput } from "../../components/Input";
import { useString as s } from "../../components/StringProvider";
import NewButton from "../../components/NewButton";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Text } from "../../components";
import LoginFormContainer, { FormContainer } from "../Login/LoginFormContainer";
import Heading from "../../components/Heading";
import ButtonGroup from "../../components/ButtonGroup";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

const CheckEmailForm = ({ form, onFinish, error, invitation }) => {
  const header = s(
    "invitationCheckEmail.page.header",
    "Welcome to {discoveryName}",
    { discoveryName: invitation.discovery.name }
  );
  const subheader = s(
    "invitationCheckEmail.page.subheader",
    "Please enter your email to open discovery"
  );
  const continueButton = s(
    "invitationCheckEmail.page.continue.label",
    "Continue"
  );

  const emailValidationError = s(
    "invitationCheckEmail.page.email.validation",
    "Please input your email"
  );
  const emailPlaceholder = s(
    "invitationCheckEmail.page.email.placeholder",
    "Your email"
  );
  const [emailError, setEmailError] = useState(error);

  useEffect(() => {
    if (error) {
      setEmailError(error);
      form.validateFields(["email"]);
    }
  }, [error]);

  const onEmailFocus = () => {
    setEmailError("");
  };

  return (
    <LoginFormContainer>
      <FormContainer>
        <Heading level={"h2"} data-cy={"page-header"}>
          {header}
        </Heading>
        <div className={"subheader"} data-cy={"page-subheader"}>
          <Text>{subheader}</Text>
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          className={"login"}
          layout={"vertical"}
        >
          <FormItem
            name="email"
            rules={[
              { required: true, message: emailValidationError, type: "email" },
              () => ({
                validator() {
                  if (!emailError) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(new Error(emailError));
                  }
                }
              })
            ]}
          >
            <EmailInput
              placeholder={emailPlaceholder}
              autoComplete={"email"}
              onFocus={onEmailFocus}
            />
          </FormItem>

          <ButtonGroup mobile={true}>
            <NewButton type={"submit"} data-cy={"check-email-button"}>
              {continueButton}
            </NewButton>
          </ButtonGroup>
        </Form>
      </FormContainer>
    </LoginFormContainer>
  );
};

CheckEmailForm.propTypes = {
  form: PropTypes.object.isRequired,
  error: PropTypes.string,
  onFinish: PropTypes.func.isRequired,
  invitation: PropTypes.object.isRequired
};

export default compose(withRouter)(CheckEmailForm);
