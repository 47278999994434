const ValidationWorkflowTypes = {
  CONFIGURATION: "configuration",
  VERIFY_TEMPLATE: "verify-template",
  CRM_ACCOUNTS: "crm-accounts",
  POWERPOINT_TEMPLATE: "powerpoint-template",
  AI_EMAIL_TEMPLATES: "ai-email-templates",
  BULK_USER_UPLOAD: "bulk-user-upload"
};

ValidationWorkflowTypes.ALL = Object.values(ValidationWorkflowTypes);

export default ValidationWorkflowTypes;
