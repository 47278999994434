import PropTypes from "prop-types";
import { Switch as ANTSwitch } from "antd";
import styled from "styled-components";
import { themeProp } from "../theme";

const ToggleContainer = styled.div`
  .ant-switch {
    width: 48px;
    height: 26px;
  }
  .ant-switch[aria-checked="false"] .ant-switch-handle {
    top: 4px;
    left: 6px;
  }
  .ant-switch-checked {
    background-color: ${themeProp("palette.secondary")};
    .ant-switch-handle::before {
      top: 2px;
      right: 2px;
      bottom: -2px;
      left: -2px;
    }
  }
`;

const Toggle = ({ ...props }) => (
  <ToggleContainer>
    <ANTSwitch {...props} />
  </ToggleContainer>
);
Toggle.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func
};

export default Toggle;
