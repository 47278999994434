import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import {
  selectRequestData,
  selectRequestState
} from "../../../../store/reducers";
import { useEffect, useState } from "react";
import {
  getSalesforceConfiguration,
  deleteSalesforceConfiguration
} from "../../../../store/actions/config";
import types from "../../../../store/actionTypes";
import useLoadingState from "../../../../utils/use-loading-state";
import { useString as s } from "../../../../components/StringProvider";
import { message } from "antd";
import SalesforceConnectionForm from "./SalesforceConnectionForm";
import loginStateService from "../../../../services/login-state.service";
import ConnectionLayout from "../../../../components/Layout/ConnectionLayout";
import DisconnectModal from "../DisconnectModal";

const SalesforceConnection = ({
  salesforceConfiguration,
  loadingState,
  getSalesforceConfiguration,
  deleteSalesforceConfiguration,
  deleteLoadingState
}) => {
  const [editing, setEditing] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const getError = s(
    "generalAdmin.page.salesforceConnection.messages.getError",
    "Failed to fetch connection information"
  );
  const header = s(
    "generalAdmin.page.salesforceConnection.header",
    "Enter the connection details you generated in Salesforce to activate the connection between Cuvama and Salesforce."
  );
  const disconnectHeader = s(
    "disconnectSalesforce.popup.header",
    "Disconnect Salesforce"
  );
  const disconnectSubheader = s(
    "disconnectSalesforce.popup.subheader",
    "Do you want to disconnect from Salesforce and delete your settings?"
  );

  useEffect(() => {
    getSalesforceConfiguration();
  }, []);

  useEffect(() => {
    setEditing(!salesforceConfiguration);
  }, [salesforceConfiguration]);

  useLoadingState(
    loadingState,
    () => {},
    () => {
      message.error(getError);
    }
  );

  const onSubmit = (values) => {
    const state = loginStateService.saveState(values);
    const url = new URL(`${values.baseUrl}/services/oauth2/authorize`);

    url.searchParams.append("client_id", values.clientId);
    url.searchParams.append("response_type", "code");
    url.searchParams.append(
      "redirect_uri",
      `${window.location.origin}/organizations/general/salesforce-connection-callback`
    );
    url.searchParams.append("state", state);
    window.location.href = url.href;
  };

  const onEdit = () => {
    setEditing(true);
  };

  const onCancelEdit = () => {
    setEditing(false);
  };

  const onDisconnect = () => {
    setDeleteModalVisible(true);
  };

  return (
    <ConnectionLayout
      header={header}
      isConnected={salesforceConfiguration && salesforceConfiguration.connected}
    >
      <SalesforceConnectionForm
        salesforceConfiguration={salesforceConfiguration}
        onFinish={onSubmit}
        onCancel={onCancelEdit}
        onDisconnect={onDisconnect}
        onEdit={onEdit}
        readOnly={!editing}
      />
      {deleteModalVisible && (
        <DisconnectModal
          onClose={() => setDeleteModalVisible(false)}
          deleteLoadingState={deleteLoadingState}
          header={disconnectHeader}
          subHeader={disconnectSubheader}
          handleDelete={deleteSalesforceConfiguration}
        />
      )}
    </ConnectionLayout>
  );
};

const mapStateToProps = (state) => ({
  loadingState: selectRequestState(
    state,
    types.GET_SALESFORCE_CONFIGURATION_REQUEST
  ),
  salesforceConfiguration: selectRequestData(
    state,
    types.GET_SALESFORCE_CONFIGURATION_REQUEST
  ),
  deleteLoadingState: selectRequestState(
    state,
    types.DELETE_SALESFORCE_CONFIGURATION_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSalesforceConfiguration,
      deleteSalesforceConfiguration
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SalesforceConnection
);
