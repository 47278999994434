import { useString as s } from "../../../../components/StringProvider";
import TextBody from "../../../../components/TextBody";
import { FileDragger, FileUpload } from "../components";
import React, { useEffect, useState } from "react";
import {
  selectRequestData,
  selectRequestState
} from "../../../../store/reducers";
import actionTypes from "../../../../store/actionTypes";
import { bindActionCreators, compose } from "redux";
import {
  createValidation,
  deployValidation
} from "../../../../store/actions/validations";
import { connect } from "react-redux";
import useLoadingState from "../../../../utils/use-loading-state";
import { Space, message } from "antd";
import UploadForm from "./UploadForm";
import ValidationStatuses from "../../../../utils/validation-statuses";
import UpdateConfigurationForm from "./UpdateConfigurationForm";
import { ValidationErrors } from "../components/";
import { Text } from "../../../../components";
import { selectLatestVersionNumber } from "../../../../store/selectors/versions";
import { getLatestVersion } from "../../../../store/actions/versions";
import styled from "styled-components";
import UpdateConfigurationModal from "./UpdateConfigurationModal";
import { withRouter } from "react-router-dom";
import { selectLatestTemplateVersion } from "../../../../store/selectors/config";
import ValidationWorkflowTypes from "../../../../utils/validation-workflow-types";

const States = {
  READY: "ready",
  UPLOAD_COMPLETE: "upload_complete",
  VALIDATING: "validating",
  VALIDATION_FAILED: "validation_failed",
  VALIDATION_COMPLETE: "validation_complete",
  VALIDATION_COMPLETE_WITH_ERRORS: "validation_complete_with_errors"
};

const UpdateConfiguration = ({
  createFileData,
  createValidationLoadingState,
  validation,
  createValidation,
  getLatestVersion,
  latestVersionNumber,
  deployValidation,
  deployValidationLoadingState,
  latestTemplateVersion
}) => {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(States.READY);
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);
  const header = s(
    "manageConfiguration.page.updateConfiguration.description",
    "To update your Organization Configuration, including the list of challenges, KPIs, required metrics, KPIs impact and ROI formulas, please upload your master data file for verification:"
  );
  const versionText = s(
    "manageConfiguration.page.updateConfiguration.version",
    "Latest master data version: "
  );
  const templateText = s(
    "manageConfiguration.page.updateConfiguration.templateVersion",
    "Latest template version: "
  );
  const deploymentError = s(
    "manageConfiguration.page.updateConfiguration.messages.deploymentError",
    "Error while deploying version"
  );

  useEffect(() => {
    getLatestVersion();
  }, []);

  useLoadingState(
    createValidationLoadingState,
    () => {
      setState(
        validation.status === ValidationStatuses.VALIDATED
          ? States.VALIDATION_COMPLETE
          : States.VALIDATION_COMPLETE_WITH_ERRORS
      );
    },
    () => {
      setState(States.VALIDATION_FAILED);
    }
  );

  useLoadingState(
    deployValidationLoadingState,
    () => {},
    () => message.error(deploymentError)
  );

  const onStartUpload = (file) => {
    setFileList((list) => [...list, file]);
    return false;
  };

  const onFinishForm = ({ updateType, description }) => {
    setState(States.VALIDATING);
    createValidation({
      fileId: createFileData.file._id,
      updateType,
      description,
      workflowType: ValidationWorkflowTypes.CONFIGURATION
    });
  };

  const onDelete = () => {
    setFileList([]);
    setState(States.READY);
  };

  const onUploadComplete = () => {
    setState(States.UPLOAD_COMPLETE);
  };

  const onUpdateConfiguration = () => {
    setLoading(true);
    setConfirmationModalVisible(false);
    deployValidation({ validationId: validation._id });
  };

  return (
    <Container>
      <Space size={16} direction={"vertical"} style={{ width: "100%" }}>
        <Space size={8} direction={"vertical"}>
          <TextBody>{header}</TextBody>
          {fileList.length === 0 ? (
            <Space direction={"horizontal"}>
              <Text variant={"small"}>
                {templateText} <b>{latestTemplateVersion}</b>
              </Text>
              <Text variant={"small"}>
                {versionText} <b>{latestVersionNumber}</b>
              </Text>
            </Space>
          ) : null}
        </Space>
        {fileList.length === 0 ? (
          <FileDragger onStartUpload={onStartUpload} />
        ) : (
          <div>
            {fileList.map((file, index) => (
              <FileUpload
                key={index}
                file={file}
                onDelete={onDelete}
                onUploadComplete={onUploadComplete}
              />
            ))}
          </div>
        )}
        {state === States.UPLOAD_COMPLETE ? (
          <UploadForm onFinish={onFinishForm} />
        ) : null}
        {state === States.VALIDATION_COMPLETE_WITH_ERRORS ? (
          <ValidationErrors validation={validation} />
        ) : null}
        {state === States.VALIDATION_COMPLETE ? (
          <UpdateConfigurationForm
            onDiscard={onDelete}
            onUpdateConfiguration={() => setConfirmationModalVisible(true)}
            loading={loading}
          />
        ) : null}
      </Space>
      {confirmationModalVisible ? (
        <UpdateConfigurationModal
          onClose={() => setConfirmationModalVisible(false)}
          onConfirm={onUpdateConfiguration}
        />
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 32px;
`;

const mapStateToProps = (state) => ({
  createFileData: selectRequestData(state, actionTypes.CREATE_FILE_REQUEST),
  createValidationLoadingState: selectRequestState(
    state,
    actionTypes.CREATE_VALIDATION_REQUEST
  ),
  validation: selectRequestData(state, actionTypes.CREATE_VALIDATION_REQUEST),
  deployValidationLoadingState: selectRequestState(
    state,
    actionTypes.DEPLOY_VALIDATION_REQUEST
  ),
  latestVersionNumber: selectLatestVersionNumber(state),
  latestTemplateVersion: selectLatestTemplateVersion(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createValidation,
      getLatestVersion,
      deployValidation
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(UpdateConfiguration);
