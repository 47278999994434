import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import LoginForm from "./LoginForm";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { useTheme } from "../../components/ThemeProvider";
import { SplitPageLayout } from "../../components/Layout";
import Page from "../../components/Page";
import {
  isAuthenticated,
  isLoginWithUnconfirmedUser,
  selectLoginLoadingState,
  selectRequestData,
  selectRequestError
} from "../../store/reducers";
import { useString as s } from "../../components/StringProvider";
import redirectToOAuthLogin from "../../utils/redirect-to-oauth-login";
import { selectAuthConfiguration } from "../../store/selectors/config";
import actionType from "../../store/actionTypes";
import Loader from "../../components/Loader";
import useLoadingState from "../../utils/use-loading-state";
import { login } from "../../store/actions/authentication";

const Login = ({
  history,
  location,
  isAuthenticated,
  loadingState,
  isLoginWithUnconfirmedUser,
  authConfiguration,
  identityProviderRecord,
  login,
  ssoError,
  invitation
}) => {
  const [error, setError] = useState("");
  const loginError = s(
    "login.page.messages.error",
    "Incorrect username or password"
  );

  const redirectTo =
    location && location.state && location.state.from
      ? location.state.from.pathname +
        location.state.from.search +
        location.state.from.hash
      : { pathname: "/MyDiscoveries" };

  useEffect(() => {
    if (isAuthenticated) {
      const redirectPath = invitation
        ? `/discoveries/${invitation.discoveryId}/select-challenges`
        : redirectTo;

      history.push(redirectPath);
    }
  }, [isAuthenticated, invitation]);

  useEffect(() => {
    if (identityProviderRecord) {
      redirectToOAuthLogin({
        authConfiguration,
        stateData: location,
        identityProvider: identityProviderRecord
          ? identityProviderRecord.identityProvider
          : undefined
      });
    }
  }, [identityProviderRecord]);

  useLoadingState(
    loadingState,
    () => {},
    () => {
      if (isLoginWithUnconfirmedUser) {
        history.push("/verification-code");
      } else {
        setError(loginError);
      }
    },
    () => {
      setError("");
    }
  );

  const onFinish = ({ email, password }) => {
    login({ email, password });
  };

  return !identityProviderRecord ? (
    <Page>
      <SplitPageLayout image={useTheme("assets.login_cover")}>
        <LoginForm
          error={error}
          ssoError={ssoError}
          location={location}
          onFinish={onFinish}
          invitation={invitation}
        />
      </SplitPageLayout>
    </Page>
  ) : (
    <Loader />
  );
};

Login.propTypes = {
  invitation: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    loadingState: selectLoginLoadingState(state),
    isLoginWithUnconfirmedUser: isLoginWithUnconfirmedUser(state),
    authConfiguration: selectAuthConfiguration(state),
    identityProviderRecord: selectRequestData(
      state,
      actionType.IDENTITY_PROVIDER_SUCCESS
    ),
    ssoError: selectRequestError(state, actionType.SSO_LOGIN_FAILURE)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Login);
