import Menu, { MenuItem } from "../../../../components/Menu";
import React from "react";
import { useString as s } from "../../../../components/StringProvider";
import PropTypes from "prop-types";
import Icon from "../../../../components/Icon";
import Dropdown from "../../../../components/Dropdown";
import { Operations } from "../AccessManagementActions";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import NewButton from "../../../../components/NewButton";
import { startAccessManagementOperation } from "../../../../store/actions/users";
import { useHistory } from "react-router-dom";

const RoleActionsDropdown = ({
  roleId,
  isSystemRole,
  startAccessManagementOperation
}) => {
  let history = useHistory();
  const duplicateRoleText = s(
    "accessManagement.page.roles.duplicateRole",
    "Duplicate"
  );
  const deleteRoleText = s(
    "accessManagement.page.roles.deleteRole",
    "Delete role"
  );
  const actionsText = s("accessManageactement.page.roles.name", "Actions");

  const onClick = (e) => {
    const operationId = e.key;
    if (operationId === Operations.DUPLICATE_ROLE) {
      history.push(`/organizations/access-management/roles/create/${roleId}`);
    } else if (operationId === Operations.DELETE_ROLE) {
      startAccessManagementOperation({ operationId, roleId });
    }
  };

  const renderMenu = () => (
    <Menu onClick={onClick}>
      <MenuItem
        key={Operations.DUPLICATE_ROLE}
        icon={<Icon name={"duplicateRole"} />}
      >
        {duplicateRoleText}
      </MenuItem>
      {!isSystemRole && (
        <MenuItem
          key={Operations.DELETE_ROLE}
          icon={<Icon name={"deleteRole"} />}
        >
          {deleteRoleText}
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <Dropdown
      overlay={renderMenu()}
      placement={"bottomLeft"}
      trigger={["click"]}
    >
      <NewButton
        type={"iconSecondary"}
        className="ant-dropdown-link"
        tooltip={actionsText}
        onClick={(e) => e.preventDefault()}
      >
        <Icon name={"dropdown"} size={"medium"} colour={"gray4"} />
      </NewButton>
    </Dropdown>
  );
};

RoleActionsDropdown.propTypes = {
  roleId: PropTypes.string.isRequired,
  startAccessManagementOperation: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startAccessManagementOperation
    },
    dispatch
  );

export default compose(connect(null, mapDispatchToProps))(RoleActionsDropdown);
