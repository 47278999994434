import Menu, { MenuItem } from "../../components/Menu";
import React, { useEffect, useState } from "react";
import { useString as s } from "../../components/StringProvider";
import Icon from "../../components/Icon";
import Dropdown from "../../components/Dropdown";
import styled from "styled-components";
import NewButton from "../../components/NewButton";
import Link from "../../components/Link";
import MySettingsModal from "../../Pages/MyDiscoveries/MySettingsModal";
import { selectUser } from "../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { themeProp } from "../../theme";
import pusherService from "../../services/pusher.service";
import ChannelNames from "../../utils/channel-names";
import httpService from "../../services/http.service";
import { logout } from "../../store/actions/authentication";
import Tooltip from "../Tooltip";
import { Userpilot } from "userpilot";
import { RESOURCE_CENTER } from "../../utils/user-pilot-util";

const SETTINGS = "account-menu-settings";
const SUPPORT = "account-menu-help";
const LOGOUT = "account-menu-logout";

const AccountMenuDropdown = ({ user, logout }) => {
  const helpText = s("discoveries.dashboard.help.button", "Help");
  const mySettingsText = s(
    "discoveries.dashboard.my_settings.button",
    "My Settings"
  );
  const accountMenuTooltipText = s("account.tooltip", "Account");

  const [mySettingsModalVisible, setMySettingsModalVisible] = useState(false);

  const showMySettingsModal = () => {
    setMySettingsModalVisible(true);
  };

  const triggerUserpilotResourceCenter = () => {
    Userpilot.trigger(RESOURCE_CENTER);
  };

  useEffect(() => {
    const tenantId = httpService.getTenantId();
    const channelName = ChannelNames.AUTH({ userId: user.userId, tenantId });

    if (!pusherService.isSubscribed(channelName)) {
      pusherService.subscribe({
        channelName,
        eventName: "logout",
        callback: () => {
          logout();
        }
      });
    }
  }, []);

  const settingsIcon = <Icon name={"settings"} />;
  const supportIcon = <Icon name={"helpGrey"} />;
  const logOutIcon = <Icon name={"logOut"} colour={"primary"} />;
  const email = user.email;

  const accountIconAndEmail = (
    <AccountWrapper>
      <IconWrapper>
        <Icon name={"account"} size={"large"} colour={"gray3"} />
      </IconWrapper>
      <EmailWrapper>
        <Tooltip title={email} placement={"left"}>
          {email}
        </Tooltip>
      </EmailWrapper>
    </AccountWrapper>
  );

  const renderMenu = () => (
    <AccountMenu>
      {accountIconAndEmail}
      <MenuItem
        data-cy={SETTINGS}
        key={SETTINGS}
        icon={settingsIcon}
        onClick={showMySettingsModal}
      >
        {mySettingsText}
      </MenuItem>
      <MenuItem
        key={SUPPORT}
        data-cy={SUPPORT}
        icon={supportIcon}
        onClick={triggerUserpilotResourceCenter}
        className={"discovery-dropdown-menu-help"}
      >
        {helpText}
      </MenuItem>
      <Menu.Divider />
      <MenuItem key={LOGOUT} data-cy={LOGOUT} icon={logOutIcon}>
        <Link to="/logout" color={"primary"}>
          Log Out
        </Link>
      </MenuItem>
    </AccountMenu>
  );

  return (
    <>
      <Dropdown
        overlay={renderMenu()}
        placement={"bottomRight"}
        trigger={["click"]}
      >
        <NewButton
          type={"iconSecondary"}
          data-cy={"account-menu"}
          tooltip={accountMenuTooltipText}
        >
          <Icon name={"account"} size={"large"} />
        </NewButton>
      </Dropdown>
      <MySettingsModal
        visible={mySettingsModalVisible}
        onClose={() => setMySettingsModalVisible(false)}
      />
    </>
  );
};

const IconWrapper = styled.div`
  display: block;
  text-align: center;
  margin-top: 10px;
`;

const EmailWrapper = styled.div`
  display: block;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 10px;
  color: ${themeProp("palette.gray3")};
  padding-left: 10px;
  padding-right: 10px;
`;

const AccountWrapper = styled.div`
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const AccountMenu = styled(Menu)`
  margin-top: 10px;
  max-width: 220px;
  min-width: 220px;
`;

const mapStateToProps = (state) => ({
  user: selectUser(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AccountMenuDropdown
);
