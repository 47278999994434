import React, { useEffect, useState } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { Dropdown, Space } from "antd";
import styled from "styled-components";
import { updateDiscoverySettings } from "../../store/actions/discoveries";
import { DiscoveryFooter, DiscoveryTitleBar } from "../../components/Discovery";
import { useString as s } from "../../components/StringProvider";
import Icon from "../../components/Icon";
import Heading from "../../components/Heading";
import {
  hasPermission,
  selectChallengeLibraryOpen,
  selectDiscoveryChallenges,
  selectDiscoveryId,
  selectDiscoverySetting,
  selectMasterDataMap
} from "../../store/reducers";
import Menu, { MenuItem } from "../../components/Menu";
import NewButton from "../../components/NewButton";
import DiscoveryContentWrapper from "../Discovery/DiscoveryContentWrapper";
import Toggle from "../../components/Toggle";
import TextBody from "../../components/TextBody";
import { selectDiscoveryChallengeGroups } from "../../store/selectors/groups-of-challenges";
import ShowCardOptions from "../../utils/show-card-options";
import ViewChallengesBy from "../../utils/view-challenges-by";
import ViewChallengesByGroups from "./ViewChallengesByGroups";
import ViewChallengesByImportance from "./ViewChallengesByImportance";
import {
  GoToChallengeSummaryButton,
  GoToSelectKPIsButton
} from "../../components/DiscoveryButtons";
import { useMobileMediaQuery } from "../../components/Responsive";
import Permissions from "../../utils/permissions";
import ViewTypicalChallenges from "./ViewTypicalChallenges";
import DeleteChallengeModal from "./DeleteChallengeModal";
import { ChallengeLibrary } from "../../components/Library";
import SelectChallengesProvider from "./SelectChallengesProvider";
import DiscoverySettings from "../../utils/discoverySettings";
import DiscoverySubHeader from "../Discovery/DiscoverySubHeader";
import { Phases } from "../../utils/phases";
const challengeLibraryWidth = 378;

const SelectChallenges = ({
  discoveryId,
  showChallenges,
  updateDiscoverySettings,
  viewBy,
  canSeeChallengeSummary,
  challengeLibraryOpen,
  canSeeChallengeLibrary,
  canSeeLeadGeneration
}) => {
  const isMobile = useMobileMediaQuery();
  const showSelectedOnlyText = s(
    "discovery.challenges.page.showSelectedOnly",
    "Show selected only"
  );
  const typicalFirst = s(
    "discovery.challenges.page.viewBy.typicalFirst",
    "Default"
  );
  const category = s("discovery.challenges.page.viewBy.category", "Category");
  const importance = s(
    "discovery.challenges.page.viewBy.importance",
    "Importance"
  );

  const link1Text = s(
    "discovery.challenges.page.subheader.link1",
    "Edit Challenges"
  );

  const link2Text = s("discovery.challenges.page.subheader.link2", "Summary");

  const [showSelectedOnly, setShowSelectedOnly] = useState(false);
  const [currentViewBy, setCurrentViewBy] = useState(viewBy);

  useEffect(() => {
    setShowSelectedOnly(showChallenges === ShowCardOptions.SELECTED_CARDS);
  }, [showChallenges]);

  useEffect(() => {
    setCurrentViewBy(viewBy);
  }, [viewBy]);

  const onToggleShowSelected = () => {
    setShowSelectedOnly(!showSelectedOnly);
    updateDiscoverySettings({
      discoveryId,
      changes: {
        showChallenges:
          showChallenges === ShowCardOptions.SELECTED_CARDS
            ? ShowCardOptions.ALL_CARDS
            : ShowCardOptions.SELECTED_CARDS
      }
    });
  };

  const clickViewBy = ({ key }) => {
    setCurrentViewBy(key);
    updateDiscoverySettings({
      discoveryId,
      changes: {
        viewChallengesBy: key
      }
    });
  };

  const viewByMenu = (
    <Menu onClick={clickViewBy} data-cy={"view-by-menu"}>
      {ViewChallengesBy.ALL.map((item) => {
        return (
          <MenuItem key={item}>
            <span>
              {item === ViewChallengesBy.TYPICAL
                ? typicalFirst
                : item === ViewChallengesBy.CATEGORY
                ? category
                : importance}
            </span>
          </MenuItem>
        );
      })}
    </Menu>
  );

  const linkConfig = [
    {
      to: `/discoveries/${discoveryId}/select-challenges`,
      label: link1Text,
      visible: true
    },
    {
      to: `/discoveries/${discoveryId}/challenge-summary`,
      label: link2Text,
      visible: canSeeChallengeSummary
    }
  ];

  const filteredLinkConfig = linkConfig.filter((link) => link.visible);

  return (
    <SelectChallengesProvider>
      {filteredLinkConfig.length > 1 ? (
        <DiscoverySubHeader
          rocketShipEditable={true}
          phase={Phases.CHALLENGES_SOLVED}
          linkConfig={filteredLinkConfig}
        />
      ) : null}
      <ChallengeLibraryMargin
        isMobile={isMobile}
        challengeLibraryOpen={challengeLibraryOpen}
      >
        <DiscoveryContentWrapper
          isMobile={isMobile}
          kpiLibraryVisible={canSeeChallengeLibrary}
          kpiLibraryOpen={challengeLibraryOpen}
        >
          <Header className={isMobile ? "mobile" : undefined}>
            <DiscoveryTitleBar>
              <Heading
                level={isMobile ? "h3" : "h2"}
                data-cy={"select-challenges-header"}
              >
                {s("discovery.challenges.page.header", "How can we help?")}
              </Heading>
            </DiscoveryTitleBar>
            <SubHeader>
              {s(
                "discovery.challenges.page.subheader",
                "Please select & prioritise your most important business challenges"
              )}
            </SubHeader>
            <GroupByTitle>
              {!canSeeLeadGeneration && (
                <GroupByContainer>
                  {!isMobile && (
                    <Heading level={"h5"} color={"gray4"}>
                      {s("discovery.challenges.page.sort.text", "View by")}
                    </Heading>
                  )}
                  <Dropdown overlay={viewByMenu} trigger="click">
                    <ButtonTextShiftLeft>
                      <NewButton
                        type={"text"}
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                        data-cy={"view-by-button"}
                      >
                        {currentViewBy === ViewChallengesBy.TYPICAL
                          ? typicalFirst
                          : currentViewBy === ViewChallengesBy.CATEGORY
                          ? category
                          : importance}
                        <Icon name={"angleDown"} />
                      </NewButton>
                    </ButtonTextShiftLeft>
                  </Dropdown>
                </GroupByContainer>
              )}
              {!canSeeLeadGeneration && (
                <Space>
                  <Toggle
                    data-cy={"show-selected-challenges-toggle"}
                    checked={showSelectedOnly}
                    onChange={onToggleShowSelected}
                  />{" "}
                  <TextBody>{showSelectedOnlyText}</TextBody>
                </Space>
              )}
            </GroupByTitle>
          </Header>
          {currentViewBy === ViewChallengesBy.TYPICAL ? (
            <ViewTypicalChallenges />
          ) : currentViewBy === ViewChallengesBy.CATEGORY ? (
            <ViewChallengesByGroups />
          ) : (
            <ViewChallengesByImportance />
          )}
          <DiscoveryFooter>
            <GoToSelectKPIsButton />
            <GoToChallengeSummaryButton />
          </DiscoveryFooter>
        </DiscoveryContentWrapper>
      </ChallengeLibraryMargin>
      {canSeeChallengeLibrary && <ChallengeLibrary />}
      <DeleteChallengeModal />
    </SelectChallengesProvider>
  );
};

const ChallengeLibraryMargin = styled.div`
  margin-right: ${({ isMobile, challengeLibraryOpen }) => {
    if (!isMobile && challengeLibraryOpen) {
      return challengeLibraryWidth + "px";
    }

    return 0;
  }};
`;

const ButtonTextShiftLeft = styled.div`
  margin-left: -8px;
`;

const GroupByContainer = styled.div`
  display: flex;
  align-items: baseline;

  h5 {
    margin-right: 14px;
  }
`;

const GroupByTitle = styled.div`
  margin-top: 22px;
  display: flex;
  align-items: baseline;
  margin-bottom: 8px;
  justify-content: space-between;

  .anticon {
    margin-left: 2px;
    height: 20px;
    width: 20px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;

  &.mobile {
    text-align: center;
  }

  &.mobile h3 {
    text-align: center;
    margin-bottom: 8px;
  }
`;

const SubHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const mapStateToProps = (state) => {
  const showChallenges = selectDiscoverySetting(
    state,
    DiscoverySettings.SHOW_CHALLENGES,
    ShowCardOptions.ALL_CARDS
  );
  const viewBy = selectDiscoverySetting(
    state,
    "viewChallengesBy",
    ViewChallengesBy.TYPICAL
  );
  const discoveryChallenges = selectDiscoveryChallenges(state);
  const categoryMap = selectMasterDataMap(state, "categories");

  return {
    groupIds: selectDiscoveryChallengeGroups(
      discoveryChallenges,
      viewBy,
      showChallenges,
      categoryMap
    ),
    discoveryChallenges,
    categoryMap,
    discoveryId: selectDiscoveryId(state),
    showChallenges,
    viewBy,
    canSeeChallengeSummary: hasPermission(
      state,
      Permissions.SEE_CHALLENGE_SUMMARY
    ),
    canSeeChallengeLibrary: hasPermission(
      state,
      Permissions.SEE_CHALLENGE_LIBRARY
    ),
    challengeLibraryOpen: selectChallengeLibraryOpen(state),
    canSeeLeadGeneration: hasPermission(state, Permissions.SEE_LEAD_GENERATION)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDiscoverySettings
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SelectChallenges
);
