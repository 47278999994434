import httpService from "./http.service";

const getAllUsers = ({ start, count, search, sort, order, userType }) =>
  httpService
    .get(`/users/`, null, { start, count, search, sort, order, userType })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getUsersDiscoveryOwners = () =>
  httpService
    .get(`/users/discovery-owners`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getMyUserInformation = () =>
  httpService
    .get(`/users/me`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const loginAsLeadGenerationUser = () =>
  httpService
    .post(`/users/loginAsLeadGenerationUser`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const updateUser = ({
  userId,
  roleIds,
  newEmail,
  firstName,
  lastName,
  jobTitle,
  country
}) =>
  httpService
    .patch(`/users/${userId}`, {
      roleIds,
      newEmail,
      firstName,
      lastName,
      jobTitle,
      country
    })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const createUser = ({
  email,
  roleIds,
  firstName,
  lastName,
  jobTitle,
  country
}) =>
  httpService
    .post("/users/", { email, roleIds, firstName, lastName, jobTitle, country })
    .then(({ data, response }) => {
      if (response) {
        switch (response.status) {
          case 409:
            throw new Error("DuplicateEmail");
          default:
            return data;
        }
      }
      return data;
    })
    .catch((err) => Promise.reject(err.response));

const deactivateUser = ({ userId }) =>
  httpService
    .post(`/users/${userId}/deactivate`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const reactivateUser = ({ userId, roleIds }) =>
  httpService
    .post(`/users/${userId}/reactivate`, { roleIds })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const registerUser = ({ email, password }) =>
  httpService
    .post(`/users/register`, { email, password })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const confirmSignUp = ({ email, confirmationCode, password }) =>
  httpService
    .post(`/users/confirmSignUp`, { email, confirmationCode, password })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const resetPassword = ({ email, confirmationCode, password }) =>
  httpService
    .post(`/users/resetPassword`, { email, confirmationCode, password })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const forgotPassword = ({ email, verifyAccount }) =>
  httpService
    .post(`/users/forgotPassword`, { email, verifyAccount })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  getAllUsers,
  getUsersDiscoveryOwners,
  getMyUserInformation,
  loginAsLeadGenerationUser,
  updateUser,
  createUser,
  deactivateUser,
  reactivateUser,
  registerUser,
  confirmSignUp,
  resetPassword,
  forgotPassword
};
export default methods;
