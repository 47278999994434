import Menu, { MenuItem } from "../../../../Menu";
import Icon from "../../../../Icon";
import Dropdown from "../../../../Dropdown";
import NewButton from "../../../../NewButton";
import PropTypes from "prop-types";
import { CheckOutlined } from "@ant-design/icons";
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection
} from "lexical";
import { $createHeadingNode, $createQuoteNode } from "@lexical/rich-text";
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND
} from "@lexical/list";
import { $createCodeNode } from "@lexical/code";
import { $wrapNodes } from "@lexical/selection";
import { useString as s } from "../../../../StringProvider";
import { Space } from "antd";
import { ReactComponent as UnorderedListIcon } from "../../../../../assets/editor/list-ul.svg";
import { ReactComponent as OrderedListIcon } from "../../../../../assets/editor/list-ol.svg";
import { ReactComponent as CodeIcon } from "../../../../../assets/editor/code.svg";
import { ReactComponent as H1Icon } from "../../../../../assets/editor/type-h1.svg";
import { ReactComponent as H2Icon } from "../../../../../assets/editor/type-h2.svg";
import { ReactComponent as QuoteIcon } from "../../../../../assets/editor/chat-square-quote.svg";
import { ReactComponent as ParagraphIcon } from "../../../../../assets/editor/text-paragraph.svg";
import styled from "styled-components";

const Icons = {
  paragraph: <ParagraphIcon />,
  h1: <H1Icon />,
  h2: <H2Icon />,
  ol: <OrderedListIcon />,
  ul: <UnorderedListIcon />,
  code: <CodeIcon />,
  quote: <QuoteIcon />
};

const FormatDropdown = ({ options, blockType, editor }) => {
  const OptionLabels = {
    paragraph: s("editor.buttons.paragraph.label", "Normal"),
    h1: s("editor.dropdown.h1.label", "Large Heading"),
    h2: s("editor.dropdown.h2.label", "Small Heading"),
    ol: s("editor.dropdown.ol.label", "Numbered List"),
    ul: s("editor.dropdown.ul.label", "Bulleted List"),
    code: s("editor.dropdown.code.label", "Code"),
    quote: s("editor.dropdown.ul.label", "Quote")
  };

  const formatParagraph = () => {
    if (blockType !== "paragraph") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
  };

  const formatLargeHeading = () => {
    if (blockType !== "h1") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h1"));
        }
      });
    }
  };

  const formatSmallHeading = () => {
    if (blockType !== "h2") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h2"));
        }
      });
    }
  };

  const formatBulletList = () => {
    if (blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== "ol") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
  };

  const formatQuote = () => {
    if (blockType !== "quote") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createQuoteNode());
        }
      });
    }
  };

  const formatCode = () => {
    if (blockType !== "code") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createCodeNode());
        }
      });
    }
  };

  const onClick = (e) => {
    switch (e.key) {
      case "paragraph":
        formatParagraph();
        break;
      case "h1":
        formatLargeHeading();
        break;
      case "h2":
        formatSmallHeading();
        break;
      case "ol":
        formatNumberedList();
        break;
      case "ul":
        formatBulletList();
        break;
      case "quote":
        formatQuote();
        break;
      case "code":
        formatCode();
        break;
    }
  };

  const renderMenu = () => (
    <Menu onClick={onClick}>
      {options.map((option) => (
        <MenuItem key={option} icon={Icons[option]}>
          <OptionContainer>
            <span> {OptionLabels[option]}</span>
            {blockType === option && <CheckOutlined />}
          </OptionContainer>
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <Dropdown
      overlay={renderMenu()}
      placement={"bottomRight"}
      trigger={["click"]}
    >
      <FixedWidthContainer>
        <NewButton type={"iconPrimary"} data-cy={"format-dropdown"}>
          <Space size={8}>
            {OptionLabels[blockType]}
            <Icon name={"angleDown"} />
          </Space>
        </NewButton>
      </FixedWidthContainer>
    </Dropdown>
  );
};

const FixedWidthContainer = styled.div`
  min-width: 140px;
  display: inline-block;
  vertical-align: baseline;
`;

const OptionContainer = styled.div`
  display: flex;

  & > *:first-child {
    flex-grow: 1;
    display: block;
  }

  & > *:last-child {
    display: block;
  }

  width: 100%;
`;

FormatDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(Icons))).isRequired,
  blockType: PropTypes.string.isRequired,
  editor: PropTypes.object.isRequired
};

export default FormatDropdown;
