import styled from "styled-components";
import React, { useEffect, useState } from "react";
import PoweredByCuvama from "../PoweredByCuvama";
import { themeProp } from "../../theme";
import { useString as s } from "../StringProvider";
import Heading from "../Heading";
import { Space } from "antd";
import Text from "../Text";
import Hyperlink from "../Hyperlink";
import { compose } from "redux";
import { useLocation, withRouter } from "react-router-dom";
import Icon from "../Icon";
import Link from "../Link";
import NavigationMenu from "../NavigationMenu";
import { useFeature } from "../FeaturesProvider";
import { hasPermission, selectIsCuvamaUser } from "../../store/reducers";
import Permissions from "../../utils/permissions";
import { connect } from "react-redux";

const Pages = {
  GENERAL: "general",
  MANAGE_CONFIGURATION: "manage-configuration",
  ACCESS_MANAGEMENT: "access-management",
  UPLOAD_CRM_DATA: "upload-crm-data",
  UPDATE_BRANDING: "update-branding",
  AUTOCONFIGURATION: "autoconfigurations",
  CONFIGURATION_FILES: "configuration-files"
};

Pages.ALL = Object.values(Pages);

const getSelectedPage = (pathname) => {
  for (const page of Pages.ALL) {
    if (pathname.indexOf(page) !== -1) {
      return page;
    }
  }

  return Pages.MANAGE_CONFIGURATION;
};

const OrganizationPageLayout = ({
  children,
  history,
  canSeeAccessManagement,
  canSeeUpdateConfiguration,
  canSeeAIEmailTemplates,
  isCuvamaUser,
  user,
  ...props
}) => {
  const header = s("organization.page.header", "Organization");
  const haveQuestions = s("organization.page.haveQuestions", "Have questions?");
  const contact = s("organization.page.contact", "Contact Cuvama");
  const backButton = s("organization.page.back", "Go Back");
  const general = s("organization.page.menu.general", "General");
  const manageConfiguration = s(
    "organization.page.menu.manageConfiguration",
    "Manage Configuration"
  );
  const accessManagement = s(
    "organization.page.menu.accessManagement",
    "Access Management"
  );
  const uploadCRMData = s(
    "organization.page.menu.uploadCRMData",
    "Upload CRM Data"
  );
  const updateBranding = s(
    "organization.page.menu.updateBranding",
    "Update Branding"
  );
  const autoconfiguration = s(
    "organization.page.menu.autoconfiguration",
    "Autoconfiguration"
  );
  const configurationFiles = s(
    "organization.page.menu.configurationFiles",
    "Configuration Files"
  );

  const getMenuItems = () => {
    const menuItems = [];

    if (salesforceIntegration || hubspotIntegration || isCuvamaUser) {
      menuItems.push({
        key: Pages.GENERAL,
        label: general
      });
    }

    menuItems.push({
      key: Pages.MANAGE_CONFIGURATION,
      label: manageConfiguration
    });

    if (canSeeAccessManagement) {
      menuItems.push({
        key: Pages.ACCESS_MANAGEMENT,
        label: accessManagement
      });
    }

    if (crmAccountsIntegration || salesforceIntegration) {
      menuItems.push({
        key: Pages.UPLOAD_CRM_DATA,
        label: uploadCRMData
      });
    }

    if (isCuvamaUser && canSeeUpdateConfiguration) {
      menuItems.push({
        key: Pages.UPDATE_BRANDING,
        label: updateBranding
      });
      menuItems.push({
        key: Pages.AUTOCONFIGURATION,
        label: autoconfiguration
      });
    }

    if (isCuvamaUser && canSeeAIEmailTemplates) {
      menuItems.push({
        key: Pages.CONFIGURATION_FILES,
        label: configurationFiles
      });
    }

    return menuItems;
  };

  const location = useLocation();
  const [selectedPage, setSelectedPage] = useState(Pages.MANAGE_CONFIGURATION);
  const salesforceIntegration = useFeature("salesforceIntegration", false);
  const crmAccountsIntegration = useFeature("crmAccountsIntegration", false);
  const hubspotIntegration = useFeature("hubspotIntegration", false);

  useEffect(() => {
    setSelectedPage(getSelectedPage(location.pathname));
  }, [location.pathname]);

  const onChangePage = (e) => {
    history.push(`/organizations/${e.key}`);
  };

  return (
    <Container {...props}>
      <NavigationContainer>
        <Link to={`/MyDiscoveries`} data-cy={"back-link"}>
          <Icon name={"arrowLeft"} /> {backButton}
        </Link>
        <Heading level={"h3"} style={{ marginTop: "33px" }}>
          {header}
        </Heading>
        <NavigationContainerMenu>
          <NavigationMenu
            selectedKeys={[selectedPage]}
            onClick={onChangePage}
            items={getMenuItems()}
          />
        </NavigationContainerMenu>
        <Space direction={"vertical"} size={4}>
          <Text variant={"small"} color={"gray4"}>
            {haveQuestions}
          </Text>
          <Text variant={"small"}>
            <Hyperlink to={"mailto:support@cuvama.com"}>{contact}</Hyperlink>
          </Text>
        </Space>
      </NavigationContainer>
      <ContentContainer>
        <Main>{children}</Main>
        <PoweredByCuvama />
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: stretch;

  a {
    font-weight: ${themeProp("typography.bMedium.fontWeight")};
    font-family: ${themeProp("typography.label.fontFamily")};
  }
`;

const NavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 250px;
  background-color: ${themeProp("palette.surface")};
  padding: 16px;
`;

const NavigationContainerMenu = styled.div`
  margin-top: 16px;
  flex: 1;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
`;

const Main = styled.div`
  flex-direction: column;
  flex: 1;
`;

const mapStateToProps = (state) => ({
  canSeeAccessManagement: hasPermission(
    state,
    Permissions.SEE_ACCESS_MANAGEMENT
  ),
  canSeeUpdateConfiguration: hasPermission(
    state,
    Permissions.UPDATE_CONFIGURATION
  ),
  canSeeAIEmailTemplates: hasPermission(
    state,
    Permissions.SEE_AI_EMAIL_TEMPLATES
  ),
  isCuvamaUser: selectIsCuvamaUser(state)
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(OrganizationPageLayout);
