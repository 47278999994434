import styled from "styled-components";
import { useString as s } from "../../../../components/StringProvider";
import Heading from "../../../../components/Heading";
import Currency from "../../../../components/Currency";
import PropTypes from "prop-types";
import Tooltip from "../../../../components/Tooltip";

const TotalColumn = ({
  numberOfCurrencyKPIs,
  numberOfNonCurrencyKPIs,
  totalAmount,
  currency
}) => {
  const totalText = s("discovery.opportunity.page.cosqTotal", "Total");
  const tbdText = s("discovery.opportunity.page.tbd", "Additional Benefits");

  return (
    <>
      <TitleContainer
        numberOfCurrencyKPIs={numberOfCurrencyKPIs}
        numberOfNonCurrencyKPIs={numberOfNonCurrencyKPIs}
        className={"title"}
      >
        <Heading level={"h5"} color={"gray3"}>
          {totalText}
        </Heading>
      </TitleContainer>
      <CurrencyContainer
        numberOfCurrencyKPIs={numberOfCurrencyKPIs}
        numberOfNonCurrencyKPIs={numberOfNonCurrencyKPIs}
        className={"currency amount"}
      >
        <Heading level={"h4"}>
          <Currency format="short" currency={currency}>
            {totalAmount}
          </Currency>
        </Heading>
      </CurrencyContainer>
      {!!numberOfNonCurrencyKPIs && (
        <AdditionalBenefitsContainer
          numberOfCurrencyKPIs={numberOfCurrencyKPIs}
          numberOfNonCurrencyKPIs={numberOfNonCurrencyKPIs}
          className={"tbd"}
          data-cy={"cosq-additional-benefits-section"}
        >
          {numberOfNonCurrencyKPIs === 1 && (
            <Tooltip title={tbdText} placement={"top"}>
              <Heading level={"h5"} useEllipsis={true}>
                {tbdText}
              </Heading>
            </Tooltip>
          )}
          {numberOfNonCurrencyKPIs > 1 && (
            <Heading level={"h5"}>{tbdText}</Heading>
          )}
        </AdditionalBenefitsContainer>
      )}
    </>
  );
};

const TitleContainer = styled.div`
  margin-left: 24px;
  grid-row-start: 1;
  grid-column-start: ${({ numberOfCurrencyKPIs, numberOfNonCurrencyKPIs }) =>
    numberOfCurrencyKPIs + numberOfNonCurrencyKPIs + 1};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & h5 {
    text-align: center;
  }
`;

const CurrencyContainer = styled.div`
  margin-left: 24px;
  grid-row-start: 2;
  grid-row-end: ${({ numberOfCurrencyKPIs }) => numberOfCurrencyKPIs} span;
  grid-column-start: ${({ numberOfCurrencyKPIs, numberOfNonCurrencyKPIs }) =>
    numberOfCurrencyKPIs + numberOfNonCurrencyKPIs + 1};
  border-radius: 4px;
  border: 1px dotted transparent;
  background: #e33d1f;

  display: flex;
  flex-direction: column;
  justify-content: center;

  & h4 {
    color: white;
    text-align: center;
  }
`;

const AdditionalBenefitsContainer = styled.div`
  margin-left: 24px;
  grid-row-start: ${({ numberOfCurrencyKPIs }) => numberOfCurrencyKPIs + 2};
  grid-row-end: ${({ numberOfNonCurrencyKPIs }) => numberOfNonCurrencyKPIs} span;
  grid-column-start: ${({ numberOfCurrencyKPIs, numberOfNonCurrencyKPIs }) =>
    numberOfCurrencyKPIs + numberOfNonCurrencyKPIs + 1};
  background-color: #ff9e8d;
  border-radius: 4px;
  border: 1px dashed #e33d1f;
  text-align: center;
  vertical-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & h5 {
    color: white;
    text-align: center;
  }
`;

TotalColumn.propTypes = {
  currency: PropTypes.string.isRequired,
  totalAmount: PropTypes.number.isRequired,
  numberOfCurrencyKPIs: PropTypes.number.isRequired,
  numberOfNonCurrencyKPIs: PropTypes.number.isRequired
};

export default TotalColumn;
