import React, { useEffect, useState } from "react";
import { useTheme } from "../../components/ThemeProvider";
import { SplitPageLayout } from "../../components/Layout";
import Page from "../../components/Page";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useString as s } from "../../components/StringProvider";
import Form from "../../components/Form";
import PropTypes from "prop-types";
import { forgotPassword } from "../../store/actions/authentication";
import {
  isAuthenticated,
  selectForgotPasswordError,
  selectForgotPasswordLoadingState
} from "../../store/reducers";
import ForgotPasswordForm from "./ForgotPasswordForm";
import useLoadingState from "../../utils/use-loading-state";

const ForgotPassword = ({
  invitation,
  history,
  forgotPasswordLoadingState,
  forgotPasswordError,
  forgotPassword,
  isAuthenticated
}) => {
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const invitationCover = useTheme("assets.share_login_cover");
  const nonInvitationCover = useTheme("assets.reset_cover");
  const invitationEmailError = s(
    "forgotPassword.page.error.invitation",
    "This email address is not authorised to access this discovery record."
  );
  const standardEmailError = s(
    "forgotPassword.page.error.standard",
    "User not found."
  );

  useEffect(() => {
    if (isAuthenticated) {
      if (invitation) {
        history.push({
          pathname: `/discoveries/${invitation.discoveryId}/select-challenges`
        });
      } else {
        history.push({
          pathname: `/`
        });
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (invitation?.emails?.length === 1) {
      form.setFieldsValue({ email: invitation.emails[0] });
    }
  }, [invitation]);

  useLoadingState(
    forgotPasswordLoadingState,
    () => {
      if (invitation) {
        history.push({
          pathname: `/invitations/${invitation.code}/reset-password`
        });
      } else {
        history.push({
          pathname: `/reset-password`
        });
      }
    },
    () => {
      if (forgotPasswordError) {
        if (typeof forgotPasswordError === "object") {
          setError(invitation ? invitationEmailError : standardEmailError);
        } else {
          setError(`${forgotPasswordError}`);
        }
      } else {
        setError("");
      }
    }
  );

  const onFinish = ({ email }) => {
    forgotPassword({ email });
  };

  return (
    <Page>
      <SplitPageLayout
        image={
          invitation
            ? invitationCover || nonInvitationCover
            : nonInvitationCover
        }
      >
        <ForgotPasswordForm
          form={form}
          error={error}
          onFinish={onFinish}
          invitation={invitation}
        />
      </SplitPageLayout>
    </Page>
  );
};

ForgotPassword.propTypes = {
  invitation: PropTypes.object
};

function mapStateToProps(state) {
  return {
    forgotPasswordLoadingState: selectForgotPasswordLoadingState(state),
    forgotPasswordError: selectForgotPasswordError(state),
    isAuthenticated: isAuthenticated(state)
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      forgotPassword
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ForgotPassword);
