import OrganizationHeader from "../OrganizationHeader";
import { OrganizationPageLayout } from "../../../components/Layout";
import Heading from "../../../components/Heading";
import { withRouter } from "react-router-dom";
import Page from "../../../components/Page";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { selectRequestData, selectRequestState } from "../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { useString as s } from "../../../components/StringProvider";
import { message, Space } from "antd";
import TextBody from "../../../components/TextBody";
import {
  FileDragger,
  FileUpload,
  ValidationErrors
} from "../ManageConfiguration/components";
import ValidationWorkflowTypes from "../../../utils/validation-workflow-types";
import {
  createValidation,
  deployValidation
} from "../../../store/actions/validations";
import actionTypes from "../../../store/actionTypes";
import ValidationStatuses from "../../../utils/validation-statuses";
import useLoadingState from "../../../utils/use-loading-state";
import NewButton from "../../../components/NewButton";
import { getLatestCRMAccountsLog } from "../../../store/actions/config";

const States = {
  READY: "ready",
  UPLOAD_COMPLETE: "upload_complete",
  VALIDATING: "validating",
  VALIDATION_FAILED: "validation_failed",
  VALIDATION_COMPLETE: "validation_complete",
  VALIDATION_COMPLETE_WITH_ERRORS: "validation_complete_with_errors"
};

const ConfigurationFiles = ({
  createFileData,
  createValidationLoadingState,
  createValidation,
  validation,
  deployValidation,
  deployValidationLoadingState,
  deploymentJob,
  getLatestCRMAccountsLog,
  history
}) => {
  const [fileList, setFileList] = useState([]);
  const [state, setState] = useState(States.READY);
  const header = s(
    "organization.page.configurationFiles.header",
    "Upload AI Email Template"
  );
  const subheader = s(
    "organization.page.configurationFiles.subheader",
    "Upload AI Email Template"
  );
  const deploy = s("organization.page.configurationFiles.deploy", "Deploy");
  const deploymentError = s(
    "organization.page.uploadCRMData.messages.deploymentError",
    "Error while deploying CRM data"
  );

  const onStartUpload = (file) => {
    setFileList((list) => [...list, file]);
    return false;
  };

  const onDelete = (file) => {
    setFileList((list) => list.filter((f) => f !== file));
    setState(States.READY);
  };

  const onUploadComplete = () => {
    createValidation({
      fileId: createFileData.file._id,
      workflowType: ValidationWorkflowTypes.AI_EMAIL_TEMPLATES
    });
    setState(States.UPLOAD_COMPLETE);
  };

  const onDeploy = () => {
    deployValidation({ validationId: validation._id });
  };

  useEffect(() => {
    if (state === States.READY) {
      getLatestCRMAccountsLog();
    }
  }, [state]);

  useLoadingState(
    createValidationLoadingState,
    () => {
      setState(
        validation.status === ValidationStatuses.VALIDATED
          ? States.VALIDATION_COMPLETE
          : States.VALIDATION_COMPLETE_WITH_ERRORS
      );
    },
    () => {
      setState(States.VALIDATION_FAILED);
    }
  );

  useLoadingState(
    deployValidationLoadingState,
    () => {
      history.push(`/organizations/deployments/${deploymentJob._id}`);
    },
    () => message.error(deploymentError)
  );

  return (
    <Page header={<OrganizationHeader />}>
      <OrganizationPageLayout>
        <Space direction={"vertical"} size={24}>
          <Space direction={"vertical"} size={6}>
            <Heading level={"h3"}>{header}</Heading>
            <TextBody>{subheader}</TextBody>
          </Space>
          <Container>
            {fileList.length === 0 ? (
              <FileDragger
                acceptTypes={".yaml,.yml"}
                onStartUpload={onStartUpload}
              />
            ) : (
              fileList.map((file, index) => (
                <FileUpload
                  key={index}
                  file={file}
                  onDelete={onDelete}
                  onUploadComplete={onUploadComplete}
                />
              ))
            )}
            {state === States.VALIDATION_COMPLETE_WITH_ERRORS ? (
              <ValidationErrors validation={validation} />
            ) : null}
            {state === States.VALIDATION_COMPLETE ? (
              <DeploymentContainer>
                <NewButton type={"primary"} onClick={onDeploy}>
                  {deploy}
                </NewButton>
              </DeploymentContainer>
            ) : null}
          </Container>
        </Space>
      </OrganizationPageLayout>
    </Page>
  );
};

const DeploymentContainer = styled.div`
  margin-top: 40px;
`;
const Container = styled.div`
  width: 100%;
`;

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createValidation,
      deployValidation,
      getLatestCRMAccountsLog
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  createFileData: selectRequestData(state, actionTypes.CREATE_FILE_REQUEST),
  createValidationLoadingState: selectRequestState(
    state,
    actionTypes.CREATE_VALIDATION_REQUEST
  ),
  validation: selectRequestData(state, actionTypes.CREATE_VALIDATION_REQUEST),
  deployValidationLoadingState: selectRequestState(
    state,
    actionTypes.DEPLOY_VALIDATION_REQUEST
  ),
  deploymentJob: selectRequestData(state, actionTypes.DEPLOY_VALIDATION_REQUEST)
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ConfigurationFiles);
