import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import TooltipIcon from "../TooltipIcon";
import Text from "../Text";
import { useMobileMediaQuery } from "../Responsive";

const InputGroup = ({ title, help, hidden, children, ...props }) => {
  const isMobile = useMobileMediaQuery();

  return (
    <InputGroupContainer hidden={hidden} {...props}>
      <InputGroupTitle help={help}>{title}</InputGroupTitle>
      <Row className={isMobile ? "mobile" : undefined}>{children}</Row>
    </InputGroupContainer>
  );
};
const InputGroupContainer = styled.div`
  padding-bottom: 20px;
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  &.mobile {
    flex-direction: column;
    align-items: stretch;
  }
`;
const InputGroupTitle = ({ help, children }) =>
  children ? (
    <Text>
      {children} <TooltipIcon title={help} />
    </Text>
  ) : null;

InputGroup.propTypes = {
  title: PropTypes.node,
  help: PropTypes.node,
  hidden: PropTypes.bool
};

export default InputGroup;
