import React, { useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import {
  useString as s,
  useStringTemplate
} from "../../../../components/StringProvider";
import {
  hasPermission,
  selectAllUsers,
  selectRequestState,
  selectUsersPagination
} from "../../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import Table from "../../../../components/Table";
import { useSetting } from "../../../../components/SettingsProvider";
import UserActionsDropdown from "./UserActionsDropdown";
import { getAllUsers } from "../../../../store/actions/users";
import UsersSearchBar from "./UsersSearchBar";
import * as userTypes from "../../../../utils/user-types";
import UserStatuses from "../../../../utils/user-statuses";
import styled, { css } from "styled-components";
import { IntlMessageFormat } from "intl-messageformat";
import actionTypes from "../../../../store/actionTypes";
import useLoadingState from "../../../../utils/use-loading-state";
import { message } from "antd";
import EditUserModal from "./EditUserModal";
import NewButton from "../../../../components/NewButton";
import Icon from "../../../../components/Icon";
import Permissions from "../../../../utils/permissions";
import AccessManagementActions from "../AccessManagementActions";
import { Text, Tooltip } from "../../../../components";
import { useFeature } from "../../../../components/FeaturesProvider";
import UserValue from "./UserValue";

TimeAgo.addDefaultLocale(en);

const Users = ({
  pagination,
  allUsers,
  getAllUsers,
  userType = userTypes.INTERNAL,
  loadingState,
  canEditAccessManagement
}) => {
  const salesforceUsersSync = useFeature("salesforceUsersSync", false);

  const [isUsersLoaded, setIsUsersLoaded] = useState(false);
  const [editUserId, setEditUserId] = useState("");
  const [start, setStart] = useState(0);
  const [showRecordsText, setShowRecordsText] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useState(undefined);
  const [order, setOrder] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState("");

  const showAdditionalField = userType === userTypes.INTERNAL;

  const locale = useSetting("locale", "en");

  const showRecordsTemplate = useStringTemplate(
    "accessManagement.page.users.showRecordTemplate",
    "Showing records {start} - {end} of {total}"
  );
  const active = s("accessManagement.page.users.status.active", "Active");
  const deactivated = s(
    "accessManagement.page.users.status.deactivated",
    "Deactivated"
  );
  const registered = s(
    "accessManagement.page.users.status.registered",
    "Registered"
  );
  const errorText = s(
    "accessManagement.page.users.messages.error",
    "Error loading users"
  );
  const editText = s("accessManagement.page.users.edit", "Edit User");
  const salesforceTooltip = s(
    "accessManagement.page.users.salesforce.tooltip",
    "User data is sourced from Salesforce and cannot be edited in Cuvama"
  );
  const action = s("accessManagement.page.users.action", "Action");
  const firstName = s("accessManagement.page.users.first.name", "First Name");
  const lastName = s("accessManagement.page.users.last.name", "Last Name");
  const jobTitle = s("accessManagement.page.users.job.title", "Job Title");

  const currentPagination = pagination
    ? {
        current: Math.floor(pagination.start / pageSize) + 1,
        total: pagination.total,
        pageSize
      }
    : { current: 0, total: 0, pageSize };

  const end = () => {
    if (currentPagination.total === 0) {
      return 0;
    }

    return currentPagination.total - start > pageSize
      ? start + pageSize
      : pagination.total;
  };

  const onClose = () => {
    setEditUserId(undefined);
  };

  const doQuery = () => {
    const query = {
      start,
      count: pageSize
    };

    if (searchTerm) {
      query.search = searchTerm;
    }

    if (sort) {
      query.sort = sort;
    }

    if (order) {
      query.order = order;
    }

    if (userType) {
      query.userType = userType;
    }

    getAllUsers(query);
  };

  useEffect(() => {
    doQuery();
  }, [start, searchTerm, userType, sort, order, pageSize]);

  useEffect(() => {
    const showRecordsText = new IntlMessageFormat(
      showRecordsTemplate,
      "en-US"
    ).format({
      start: currentPagination.total === 0 ? 0 : start + 1,
      end: end(),
      total: currentPagination.total
    });
    setShowRecordsText(showRecordsText);
  }, [pagination]);

  useLoadingState(
    loadingState,
    () => {
      if (allUsers?.length) setIsUsersLoaded(true);
    },
    () => {
      message.error(errorText);
    }
  );

  const data =
    (allUsers &&
      allUsers.map((user) => ({
        ...user,
        key: user.userId,
        userId: user.userId,
        action: "action"
      }))) ||
    [];

  let columns = [
    {
      title: s("accessManagement.page.users.email", "Email"),
      dataIndex: "email",
      key: "email",
      sorter: true,
      ellipsis: { showTitle: false },
      render: (email) => {
        return <UserValue searchTerm={searchTerm} value={email} />;
      }
    },
    {
      title: s("accessManagement.page.users.role", "Role"),
      dataIndex: "rolesText",
      key: "rolesText",
      sorter: true,
      ellipsis: { showTitle: false },
      render: (rolesText) => {
        return <UserValue searchTerm={searchTerm} value={rolesText} />;
      }
    },
    {
      title: s("accessManagement.page.users.lastActivity", "Last Activity"),
      dataIndex: "lastLoginAt",
      key: "lastLoginAt",
      sorter: true,
      render: (dateStr) => {
        return !dateStr ? (
          "-"
        ) : (
          <ReactTimeAgo date={new Date(dateStr)} locale={locale} />
        );
      }
    },
    {
      title: s("accessManagement.page.users.status", "Status"),
      dataIndex: "status",
      key: "status",
      sorter: false,
      render: (status) => {
        if (status === UserStatuses.ACTIVE) {
          return active;
        } else if (status === UserStatuses.DEACTIVATED) {
          return deactivated;
        } else if (status === UserStatuses.REGISTERED) {
          return registered;
        }
      }
    }
  ];

  if (showAdditionalField) {
    const additionalFields = [
      {
        title: firstName,
        dataIndex: "firstName",
        key: "firstName",
        sorter: true,
        ellipsis: { showTitle: false },
        render: (firstName) => {
          return <UserValue searchTerm={searchTerm} value={firstName} />;
        }
      },
      {
        title: lastName,
        dataIndex: "lastName",
        key: "lastName",
        sorter: true,
        ellipsis: { showTitle: false },
        render: (lastName) => {
          return <UserValue searchTerm={searchTerm} value={lastName} />;
        }
      },
      {
        title: jobTitle,
        dataIndex: "jobTitle",
        key: "jobTitle",
        sorter: true,
        ellipsis: { showTitle: false },
        render: (jobTitle) => {
          return <UserValue searchTerm={searchTerm} value={jobTitle} />;
        }
      }
    ];

    columns.splice(1, 0, ...additionalFields);
  }

  if (canEditAccessManagement) {
    columns.push({
      title: action,
      dataIndex: "action",
      key: "action",
      width: 95,
      fixed: true,
      render: (text, record) => {
        return (
          <Row>
            <NewButton
              type={"iconSecondary"}
              className="ant-dropdown-link"
              tooltip={editText}
              onClick={() => {
                setEditUserId(record.userId);
              }}
              data-cy={"edit-user"}
            >
              <Icon name={"pencil"} size={"medium"} colour={"gray4"} />
            </NewButton>
            <UserActionsDropdown
              userId={record.userId}
              status={record.status}
            />
          </Row>
        );
      }
    });
  }

  if (salesforceUsersSync && showAdditionalField) {
    columns.splice(0, 0, {
      title: "",
      fixed: true,
      dataIndex: "salesforce",
      key: "salesforce",
      sorter: false,
      width: "40px",
      render: (salesforce) => {
        return salesforce ? (
          <Row>
            <Tooltip title={salesforceTooltip} placement={"left"}>
              <Icon name={"replace"} colour={"gray4"} />
            </Tooltip>
          </Row>
        ) : null;
      }
    });
  }

  const onTableChange = (pagination, filters, sorter) => {
    const mapOrder = (order) => {
      switch (order) {
        case "ascend":
          return "asc";
        case "descend":
          return "desc";
        default:
          return;
      }
    };

    if (pagination.current) {
      setStart((pagination.current - 1) * pagination.pageSize);
    }

    setPageSize(pagination.pageSize);

    if (sorter.order) {
      setSort(sorter.columnKey);
      setOrder(mapOrder(sorter.order));
    } else {
      setSort(undefined);
      setOrder(undefined);
    }
  };

  const onSearch = (e) => {
    setSearchTerm(e);
    setStart(0);
  };

  const doUsersRefresh = () => {
    doQuery();
  };

  return (
    <>
      <UsersSearchBar
        onSearch={onSearch}
        userType={userType}
        doUsersRefresh={doUsersRefresh}
      />
      <Container data-cy={"users-table"}>
        <Table
          columns={columns}
          dataSource={data}
          onChange={onTableChange}
          pagination={currentPagination}
          variant={"smallBody"}
        />
      </Container>
      {isUsersLoaded && (
        <RecordsText data-cy={"records-text"} isDataEmpty={!!data?.length}>
          <Text color={"gray4"} variant={"bodyMobile"}>
            {showRecordsText}
          </Text>
        </RecordsText>
      )}
      <AccessManagementActions />
      {editUserId && (
        <EditUserModal
          userId={editUserId}
          onClose={onClose}
          visible={!!editUserId}
          userType={userType}
        />
      )}
    </>
  );
};

const RecordsText = styled.div`
  font-size: 12px;
  transform: translateY(-52px);
  width: fit-content;

  ${({ isDataEmpty }) =>
    !isDataEmpty &&
    css`
      transform: translateY(0px);
      margin-top: 16px;
    `}
`;

const mapStateToProps = (state) => {
  return {
    allUsers: selectAllUsers(state),
    pagination: selectUsersPagination(state),
    loadingState: selectRequestState(state, actionTypes.USERS_FETCH_REQUEST),
    canEditAccessManagement: hasPermission(
      state,
      Permissions.EDIT_ACCESS_MANAGEMENT
    )
  };
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllUsers
    },
    dispatch
  );

const Container = styled.div``;

export default compose(connect(mapStateToProps, mapDispatchToProps))(Users);
