import React, { createContext, useContext, useEffect, useState } from "react";
import _ from "lodash";
import featuresService from "../services/features.service";

export const FeaturesContext = createContext();
export const FeaturesProvider = ({ children }) => {
  const [features, setFeatures] = useState({});
  const getFeature = (key, defaultValue) => _.get(features, key, defaultValue);

  useEffect(() => {
    featuresService
      .getFeatures()
      .then((features) => {
        setFeatures({ ...features });
      })
      .catch((error) => {
        console.error("features retrieval failed", error);
      });
  }, []);
  return (
    <FeaturesContext.Provider
      value={{
        features,
        getFeature
      }}
    >
      {children}
    </FeaturesContext.Provider>
  );
};

export const useFeature = (key, activeByDefault) => {
  const { features } = useContext(FeaturesContext);
  const result = features[key];

  return result === true || result === false ? result : activeByDefault;
};

export default FeaturesProvider;
