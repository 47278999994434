import Toggle from "../../../components/Toggle";
import TextBody from "../../../components/TextBody";
import { Space } from "antd";
import React from "react";
import PropTypes from "prop-types";
import {
  isAuthenticated,
  selectDiscoveryId,
  selectDiscoveryScenario,
  selectDiscoveryShowAdditionalBenefits
} from "../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import {
  setDiscoveryShowAdditionalBenefits,
  updateDiscoverySettings
} from "../../../store/actions/discoveries";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useString as s } from "../../../components/StringProvider";
import { Tooltip } from "../../../components";
import Icon from "../../../components/Icon";
import actionTypes from "../../../store/actionTypes";

const ShowAdditionalBenefits = ({
  isAuthenticated,
  discoveryId,
  reportMode,
  printMode,
  updateDiscoverySettings,
  setDiscoveryShowAdditionalBenefits,
  showAdditionalBenefits,
  fixedWidth
}) => {
  const showAdditionalBenefitsText = s(
    "discovery.showAdditionalBenefits.text",
    "Show additional benefits"
  );
  const toolTipText = s(
    "discovery.showAdditionalBenefits.toolTip",
    "Display additional benefits that are quantified, but not financial."
  );

  const onChange = (value) => {
    if (reportMode && !isAuthenticated) {
      setDiscoveryShowAdditionalBenefits({ showAdditionalBenefits: value });
    } else {
      updateDiscoverySettings({
        discoveryId,
        changes: {
          showAdditionalBenefits: value
        }
      });
    }
  };

  if (printMode) {
    return null;
  }

  return (
    <div style={{ width: fixedWidth }}>
      <Space size={12}>
        <Toggle
          data-cy={"cosq-additional-benefits-toggle"}
          className="up-cosq-additionalbenefitstoggle"
          checked={showAdditionalBenefits}
          onChange={onChange}
        />
        <TextBody>{showAdditionalBenefitsText}</TextBody>
        <div style={{ marginTop: "4px" }}>
          <Tooltip title={toolTipText} placement={"top"}>
            <Icon name={"infoCircle"} colour={"gray4"} size={"medium"} />
          </Tooltip>
        </div>
      </Space>
    </div>
  );
};

ShowAdditionalBenefits.propTypes = {
  reportMode: PropTypes.bool.isRequired,
  printMode: PropTypes.bool.isRequired
};

const mapStateToProps = (state, props) => ({
  isAuthenticated: isAuthenticated(state),
  discoveryId: selectDiscoveryId(state),
  scenario: selectDiscoveryScenario(state),
  showAdditionalBenefits: selectDiscoveryShowAdditionalBenefits({
    state,
    reportMode: props.reportMode,
    actionType: actionTypes.DISCOVERY_SET_SHOW_ADDITIONAL_BENEFITS_REQUEST
  })
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDiscoverySettings,
      setDiscoveryShowAdditionalBenefits
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ShowAdditionalBenefits);
