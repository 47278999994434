import Form, { FormItem } from "../../../../components/Form";
import { useString as s } from "../../../../components/StringProvider";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Input from "../../../../components/Input";
import ButtonGroup from "../../../../components/ButtonGroup";
import NewButton from "../../../../components/NewButton";
import { selectIsCuvamaUser, selectRoles } from "../../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { getRoles } from "../../../../store/actions/roles";
import { connect } from "react-redux";
import CheckboxGroup from "../../../../components/CheckboxGroup";
import Checkbox from "../../../../components/Checkbox";
import getRoleFormConfig from "./role-form-config";
import styled from "styled-components";
import { themeProp } from "../../../../theme";
import {
  useMobileMediaQuery,
  useTabletMediaQuery
} from "../../../../components/Responsive";

const getDependenciesMap = ({ groups }) => {
  const map = {};

  for (const group of groups) {
    for (const permission of group.permissions) {
      if (permission.dependsOn) {
        map[permission.dependsOn] = permission.name;
      }
    }
  }

  return map;
};

const RoleForm = ({
  form,
  onFinish,
  submitButtonText,
  children,
  isBlessedRole = false,
  isCuvamaUser,
  initialPermissions
}) => {
  const isMobile = useMobileMediaQuery();
  const isTablet = useTabletMediaQuery();
  const roleNameText = s(
    "accessManagement.page.roleForm.rolename",
    "Role name"
  );
  const roleNamePlaceholder = s(
    "accessManagement.page.roleForm.rolename.placeholder",
    "The role name"
  );
  const descriptionText = s(
    "accessManagement.page.roleForm.description",
    "Description"
  );
  const descriptionPlaceholder = s(
    "accessManagement.page.roleForm.description.placeholder",
    "The role description"
  );
  const roleNameValidation = s(
    "accessManagement.page.roleForm.rolename.validation",
    "Role name is required"
  );

  const roleFormConfigGroups = getRoleFormConfig({ s, isCuvamaUser });
  const fullInputWidth = "673px";
  const dependenciesMap = getDependenciesMap({ groups: roleFormConfigGroups });
  const [currentPermissions, setCurrentPermissions] = useState("");

  useEffect(() => {
    if (initialPermissions) {
      const permissions = [...initialPermissions];

      permissions.sort();
      setCurrentPermissions(permissions.join(","));
    }
  }, [initialPermissions]);

  const onFieldsChange = (changedFields) => {
    const permissions = changedFields.find((field) =>
      field.name.includes("permissions")
    );

    if (permissions) {
      const newPermissions = [...permissions.value];

      newPermissions.sort();
      const removedPermission = currentPermissions
        .split(",")
        .find((p) => !newPermissions.includes(p));

      if (removedPermission && dependenciesMap[removedPermission]) {
        form.setFieldsValue({
          permissions: newPermissions.filter(
            (p) => p !== dependenciesMap[removedPermission]
          )
        });
      }

      setCurrentPermissions(newPermissions.join(","));
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      name={"roleForm"}
      onFieldsChange={onFieldsChange}
    >
      <FormItem
        name="name"
        label={roleNameText}
        style={{ maxWidth: "464px" }}
        rules={[{ required: true, message: roleNameValidation }]}
      >
        <Input placeholder={roleNamePlaceholder} disabled={isBlessedRole} />
      </FormItem>
      <FormItem name="description" label={descriptionText}>
        <Input
          placeholder={descriptionPlaceholder}
          style={{ maxWidth: { inputWidth: fullInputWidth } }}
          disabled={isBlessedRole}
        />
      </FormItem>
      <FormItem name="permissions">
        <CheckboxGroup>
          {roleFormConfigGroups.map((group, index) => (
            <PermissionGroupContainer
              isMobile={isMobile}
              isTablet={isTablet}
              inputWidth={fullInputWidth}
              key={index}
            >
              <PermissionGroupTitle
                isMobile={isMobile}
                isTablet={isTablet}
                inputWidth={fullInputWidth}
              >
                {group.groupName}
              </PermissionGroupTitle>
              {group.permissions.map((permission) => (
                <div key={permission.name} className={"permission"}>
                  <StyledCheckbox
                    isMobile={isMobile}
                    isTablet={isTablet}
                    inputWidth={fullInputWidth}
                    value={permission.name}
                    disabled={
                      permission.disabled ||
                      (permission.dependsOn &&
                        !currentPermissions
                          .split(",")
                          .includes(permission.dependsOn))
                    }
                  >
                    {permission.label || permission.name}
                  </StyledCheckbox>
                  {permission.description && (
                    <Description isMobile={isMobile} isTablet={isTablet}>
                      {permission.description}
                    </Description>
                  )}
                  <Divider />
                </div>
              ))}
            </PermissionGroupContainer>
          ))}
        </CheckboxGroup>
      </FormItem>
      <FormItem>
        <ButtonGroup mobile={isMobile}>
          <NewButton type={"submit"} data-cy={"role-form-save"}>
            {submitButtonText}
          </NewButton>
          {children}
        </ButtonGroup>
      </FormItem>
    </Form>
  );
};

const Divider = styled.hr`
  border-top: 0.5px solid ${themeProp("palette.gray2")};
`;

const PermissionGroupContainer = styled.div`
  margin-bottom: 30px;
  background-color: white;
  width: ${({ isMobile, isTablet, inputWidth }) =>
    isTablet ? "420px" : isMobile ? undefined : inputWidth};
  display: flex;
  flex-direction: column;
  align-items: stretch;

  & > .permission {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
    margin-bottom: 8px;
  }

  & > .permission:last-child {
    margin-bottom: 0;
  }
`;

const PermissionGroupTitle = styled.div`
  margin-bottom: 10px;
  background-color: ${themeProp("palette.gray2")};
  color: ${themeProp("palette.gray4")};
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
  font-weight: bold;
`;

const Description = styled.div`
  padding-left: 48px;
  font-size: 11px;
`;

const StyledCheckbox = styled(Checkbox)`
  padding-left: 16px;
`;

RoleForm.propTypes = {
  form: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  initialPermissions: PropTypes.array
};

const mapStateToProps = (state) => ({
  roles: selectRoles(state),
  isCuvamaUser: selectIsCuvamaUser(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getRoles
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(RoleForm);
