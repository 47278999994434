import React, { useState, useEffect } from "react";
import { validate } from "email-validator";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useString as s } from "../../../../components/StringProvider";
import Modal from "../../../../components/Modal";
import Icon from "../../../../components/Icon";
import Heading from "../../../../components/Heading";
import { Paragraph } from "../../../../components/Typography";
import { EmailInput } from "../../../../components/Input";
import NewButton from "../../../../components/NewButton";
import Hyperlink from "../../../../components/Hyperlink";
import Form, { FormItem } from "../../../../components/Form";
import usePreventScroll from "../../../../utils/hooks/use-prevent-scroll";
import {
  selectGuest,
  isAuthenticated,
  selectRequestState
} from "../../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { getGuest, registerGuest } from "../../../..//store/actions/guests";
import { themeProp } from "../../../../theme";
import useLoadingState from "../../../../utils/use-loading-state";
import actionTypes from "../../../../store/actionTypes";

const NewGuestReportModal = ({
  reportId,
  discovery,
  guest,
  getGuest,
  registerGuest,
  isAuthenticated,
  loadingState
}) => {
  const privacyPolicyUrl = "https://cuvama.com/privacy-policy/";
  const headerText = s(
    "new.guest.report.modal.header",
    "Discovery Report for {accountName}",
    { accountName: discovery?.name }
  );
  const subHeaderText = s(
    "new.guest.report.modal.subHeader",
    "Please introduce yourself to see the report."
  );
  const buttonText = s("new.guest.report.modal.button", "View report");
  const emailPlaceholderText = s(
    "new.guest.report.modal.email.placeholder",
    "Enter your email"
  );
  const emailValidation = s(
    "new.guest.report.modal.email.validation",
    "Please input a valid email"
  );
  const privacyPolicyText = s(
    "new.guest.report.modal.privacyPolicy",
    "Privacy Policy"
  );
  const privacyPolicyStatement = s(
    "new.guest.report.modal.privacyPolicy.statement",
    "By clicking View report, you consent to our"
  );
  const notificationStatement = s(
    "new.guest.report.modal.notification.info",
    "Your email will be used to notify the Discovery Owner."
  );

  const [isEmailValid, setIsEmailValid] = useState(false);
  const [guestEmail, setGuestEmail] = useState("");
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  const showModal = !isAuthenticated && !guest && isLoadingComplete;

  usePreventScroll(showModal);

  const [form] = Form.useForm();

  useEffect(() => {
    getGuest();
  }, []);

  useLoadingState(
    loadingState,
    () => {
      setLoadingComplete(true);
    },
    () => {
      setLoadingComplete(true);
    }
  );

  const validateEmail = () => ({
    validator(_, value) {
      if (!validate(value)) {
        setIsEmailValid(false);
        return Promise.reject(new Error(emailValidation));
      } else {
        setIsEmailValid(true);
        setGuestEmail(value);
        return Promise.resolve();
      }
    }
  });

  const onCreateReport = () => {
    if (isEmailValid && guestEmail && reportId) {
      registerGuest({ email: guestEmail, reportId });
    }
  };

  if (!showModal) {
    return null;
  }

  return (
    <StyledModal
      width={480}
      open={true}
      data-cy={"new-guest-report-modal"}
      showCloseIcon={false}
    >
      <ModalIcon>
        <StyledIcon name={"save"} colour={"primary"} />
      </ModalIcon>
      <ModalTitle>
        <Heading level={"h3"} align={"center"}>
          {headerText}
        </Heading>
        <Paragraph variant={"body"}>{subHeaderText}</Paragraph>
      </ModalTitle>
      <ModalEmailForm form={form} layout="vertical">
        <ModalEmailFormItem name="email" rules={[validateEmail()]}>
          <EmailInput
            placeholder={emailPlaceholderText}
            data-cy={"new-guest-report-modal-email-input"}
          />
        </ModalEmailFormItem>
        <Paragraph variant={"smallBody"} color="gray4">
          {privacyPolicyStatement}{" "}
          <span>
            <Hyperlink to={privacyPolicyUrl}>{privacyPolicyText}</Hyperlink>
          </span>
          {"."}
          <br />
          {notificationStatement}
        </Paragraph>
        <NewButton
          type={"primary"}
          disabled={!isEmailValid}
          onClick={onCreateReport}
          className={"new-guest-view-report-button"}
        >
          {buttonText}
        </NewButton>
      </ModalEmailForm>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  & .ant-modal-content {
    padding: 32px;
  }

  button {
    width: 100%;
    margin-top: 8px;
  }

  a {
    color: ${themeProp("components.link.primary.base")};
  }

  a:hover {
    color: ${themeProp("components.link.primary.hover")};
  }
`;

const ModalIcon = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ModalTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 24px 0px;

  h3 {
    max-width: 320px;
  }
`;

const ModalEmailForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ModalEmailFormItem = styled(FormItem)`
  margin: 0;
`;

const StyledIcon = styled(Icon)`
  svg {
    height: 33px;
    width: 42.46px;
  }
`;

NewGuestReportModal.propTypes = {
  discovery: PropTypes.object.isRequired,
  reportId: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state),
  guest: selectGuest(state),
  loadingState: selectRequestState(state, actionTypes.GET_GUEST_REQUEST)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getGuest, registerGuest }, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NewGuestReportModal
);
