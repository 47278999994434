import { useEffect } from "react";
import {
  selectRequestError,
  selectRequestState
} from "../../../../store/reducers";
import { retrieveIntegrationToken } from "../../../../store/actions/integrations";
import { useLocation, withRouter, useParams } from "react-router-dom";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import Loader from "../../../../components/Loader";
import actionTypes from "../../../../store/actionTypes";
import useLoadingState from "../../../../utils/use-loading-state";
import IntegrationNames from "../../../../utils/constants/integration-names";

const IntegrationCallback = ({
  history,
  retrieveIntegrationToken,
  loadingState,
  error
}) => {
  const location = useLocation();
  const { search } = location;
  const params = new URLSearchParams(search);

  const code = params.get("code");
  const pathParams = useParams();
  const name = pathParams?.name || IntegrationNames.SALESFORCE;
  const redirectURI = `${window.location.origin}${location.pathname}`;

  useEffect(() => {
    if (code && name) {
      retrieveIntegrationToken({ name, code, redirect_uri: redirectURI });
    }
  }, [code, name]);

  useLoadingState(
    loadingState,
    () => {
      history.push({
        pathname: `/organizations/general/integrations/${name}`
      });
    },
    () => {
      history.push({
        pathname: `/organizations/general/integrations/${name}`,
        search: `?integrationError=${
          error?.data?.message || "error.retrieveIntegrationToken.generalError"
        }`
      });
    }
  );

  return <Loader />;
};

const mapStateToProps = (state) => {
  return {
    loadingState: selectRequestState(
      state,
      actionTypes.RETRIEVE_INTEGRATION_TOKEN_REQUEST
    ),
    error: selectRequestError(
      state,
      actionTypes.RETRIEVE_INTEGRATION_TOKEN_REQUEST
    )
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ retrieveIntegrationToken }, dispatch);

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(IntegrationCallback);
