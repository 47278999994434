import { OrganizationPageLayout } from "../../../components/Layout";
import Page from "../../../components/Page";
import OrganizationHeader from "../OrganizationHeader";
import Heading from "../../../components/Heading";
import { useString as s } from "../../../components/StringProvider";
import NavigationMenu from "../../../components/NavigationMenu";
import React, { useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  withRouter
} from "react-router-dom";
import { NotFound } from "../../ErrorPage";
import Users from "./Users";
import Roles from "./Roles";
import { compose } from "redux";
import styled from "styled-components";
import * as userTypes from "../../../utils/user-types";
import CuvamaRoute from "../../../routes/CuvamaRoute";
import RoleEditor, { formActionTypes } from "./Roles/RoleEditor";
import actionTypes from "../../../store/actionTypes";

const Pages = {
  USERS: "users",
  EXTERNAL_USERS: "external-users",
  ROLES: "roles"
};
Pages.ALL = Object.values(Pages);

const getSelectedPage = (pathname) => {
  const matches = pathname.match(/^.*?([a-z\-]+)$/);

  if (matches) {
    for (const page of Pages.ALL) {
      if (page === matches[1]) {
        return page;
      }
    }
  }

  return Pages.USERS;
};

const AccessManagement = ({ history }) => {
  const header = s(
    "organization.page.accessManagement.header",
    "Access Management"
  );
  const users = s("organization.page.accessManagement.menu.users", "Users");
  const externalUsers = s(
    "organization.page.accessManagement.menu.externalUsers",
    "External Users"
  );
  const roles = s("organization.page.accessManagement.menu.roles", "Roles");
  const location = useLocation();
  const [selectedPage, setSelectedPage] = useState(Pages.ACCESS_MANAGEMENT);

  const navigationMenuItems = [
    {
      key: Pages.USERS,
      label: users
    },
    {
      key: Pages.EXTERNAL_USERS,
      label: externalUsers
    },
    {
      key: Pages.ROLES,
      label: roles
    }
  ];

  useEffect(() => {
    setSelectedPage(getSelectedPage(location.pathname));
  }, [location.pathname]);

  const onChangePage = (e) => {
    history.push(`/organizations/access-management/${e.key}`);
  };

  const createRolePath = "/organizations/access-management/roles/create";
  const editRolePath = "/organizations/access-management/roles/edit";
  const isRoleEdit =
    location.pathname === createRolePath ||
    location.pathname.includes(createRolePath) ||
    location.pathname.includes(editRolePath);

  return (
    <Page header={<OrganizationHeader />}>
      <OrganizationPageLayout>
        {!isRoleEdit && <Heading level={"h3"}>{header}</Heading>}
        {!isRoleEdit && (
          <NavigationMenu
            mode={"horizontal"}
            selectedKeys={[selectedPage]}
            onClick={onChangePage}
            items={navigationMenuItems}
          />
        )}
        <Container>
          <Switch>
            <CuvamaRoute
              path="/organizations/access-management/users"
              exact
              component={() => <Users userType={userTypes.INTERNAL} />}
            />
            <CuvamaRoute
              path="/organizations/access-management/external-users"
              exact
              component={() => <Users userType={userTypes.EXTERNAL} />}
            />
            <CuvamaRoute
              path={"/organizations/access-management/roles/edit/:roleId"}
              exact
              component={() => (
                <RoleEditor
                  formActionType={formActionTypes.EDIT}
                  actionType={actionTypes.UPDATE_ROLE_REQUEST}
                />
              )}
            />
            <CuvamaRoute
              path={"/organizations/access-management/roles/create/:roleId"}
              exact
              component={() => (
                <RoleEditor
                  formActionType={formActionTypes.COPY}
                  actionType={actionTypes.CREATE_ROLE_REQUEST}
                />
              )}
            />
            <CuvamaRoute
              path={"/organizations/access-management/roles/create"}
              exact
              component={() => (
                <RoleEditor
                  formActionType={formActionTypes.CREATE}
                  actionType={actionTypes.CREATE_ROLE_REQUEST}
                />
              )}
            />
            <CuvamaRoute
              path="/organizations/access-management/roles"
              exact
              component={Roles}
            />
            <Redirect
              from=""
              to="/organizations/access-management/users"
              exact
            />
            <Route component={NotFound} />
          </Switch>
        </Container>
      </OrganizationPageLayout>
    </Page>
  );
};

const Container = styled.div`
  width: 100%;
`;

export default compose(withRouter)(AccessManagement);
