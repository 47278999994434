import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { themeProp } from "../theme";
import { ReactComponent as PlayIcon } from "../assets/play.svg";
import { ReactComponent as PlusIcon } from "../assets/Plus.svg";
import { ReactComponent as PlusCircleIcon } from "../assets/PlusCircle.svg";
import { ReactComponent as MinusIcon } from "../assets/Minus.svg";
import { ReactComponent as AngleDownIcon } from "../assets/AngleDown.svg";
import { ReactComponent as AngleUpIcon } from "../assets/AngleUp.svg";
import { ReactComponent as ShareIcon } from "../assets/Share.svg";
import { ReactComponent as DownloadIcon } from "../assets/Down.svg";
import { ReactComponent as ArrowLeftIcon } from "../assets/ArrowBackStep.svg";
import { ReactComponent as ArrowLeft2Icon } from "../assets/ArrowLeft2.svg";
import { ReactComponent as ArrowRightIcon } from "../assets/ArrowRight.svg";
import { ReactComponent as LinkIcon } from "../assets/GenerateLinkIcon.svg";
import { ReactComponent as PenIcon } from "../assets/Pen.svg";
import { ReactComponent as AlertIcon } from "../assets/Alert.svg";
import { ReactComponent as HelpIcon } from "../assets/Help.svg";
import { ReactComponent as CloseIcon } from "../assets/Cross.svg";
import { ReactComponent as OpenInNewIcon } from "../assets/OpenInNew.svg";
import { ReactComponent as ArrowDownIcon } from "../assets/ArrowDown.svg";
import { ReactComponent as CheckIcon } from "../assets/Check.svg";
import { ReactComponent as CopyIcon } from "../assets/Copy.svg";
import { ReactComponent as DropdownIcon } from "../assets/Dropdown.svg";
import { ReactComponent as PencilIcon } from "../assets/Pencil.svg";
import { ReactComponent as ResetIcon } from "../assets/Reset.svg";
import { ReactComponent as PowerpointIcon } from "../assets/Powerpoint.svg";
import { ReactComponent as PDFIcon } from "../assets/PDF.svg";
import { ReactComponent as DeleteIcon } from "../assets/Delete.svg";
import { ReactComponent as AssignIcon } from "../assets/AssignTo.svg";
import { ReactComponent as CSVIcon } from "../assets/CSV.svg";
import { ReactComponent as SettingsIcon } from "../assets/Settings.svg";
import { ReactComponent as CommentIcon } from "../assets/Comment.svg";
import { ReactComponent as BellIcon } from "../assets/Bell.svg";
import { ReactComponent as RocketIcon } from "../assets/Rocket.svg";
import { ReactComponent as PaperPlane } from "../assets/PaperPlane.svg";
import { ReactComponent as Left } from "../assets/Left.svg";
import { ReactComponent as Right } from "../assets/Right.svg";
import { ReactComponent as Tachometer } from "../assets/Tachometer.svg";
import { ReactComponent as Drop } from "../assets/Drop.svg";
import { ReactComponent as XLS } from "../assets/XLS.svg";
import { ReactComponent as Organization } from "../assets/Organization.svg";
import { ReactComponent as Lock } from "../assets/Lock.svg";
import { ReactComponent as Eye } from "../assets/Eye.svg";
import { ReactComponent as Devices } from "../assets/Devices.svg";
import { ReactComponent as Account } from "../assets/AccountProfile.svg";
import { ReactComponent as HelpGrey } from "../assets/HelpGrey.svg";
import { ReactComponent as LogOut } from "../assets/Logout.svg";
import { ReactComponent as Feedback } from "../assets/Feedback.svg";
import { ReactComponent as DeactivateUser } from "../assets/DeactivateUser.svg";
import { ReactComponent as ReactivateUser } from "../assets/ReactivateUser.svg";
import { ReactComponent as NotesIcon } from "../assets/NotesEdit.svg";
import { ReactComponent as PaperEdit } from "../assets/PaperEdit.svg";
import { ReactComponent as InfoCircle } from "../assets/InfoCircle.svg";
import { ReactComponent as Error500 } from "../assets/500.svg";
import { ReactComponent as OpenValueMap } from "../assets/OpenValueMap.svg";
import { ReactComponent as CloseValueMap } from "../assets/CloseValueMap.svg";
import { ReactComponent as ValueMapOpenFullScreen } from "../assets/ValueMapOpenFullScreen.svg";
import { ReactComponent as Tick } from "../assets/Tick.svg";
import { ReactComponent as Canvas } from "../assets/Canvas.svg";
import { ReactComponent as NewDelete } from "../assets/NewDelete.svg";
import { ReactComponent as Image } from "../assets/Image.svg";
import { ReactComponent as InfoIcon } from "../assets/InfoCircleOutlined.svg";
import { ReactComponent as Email } from "../assets/Email.svg";
import { ReactComponent as BlankLink } from "../assets/blankLink.svg";
import { ReactComponent as Unselect } from "../assets/unselect.svg";
import { ReactComponent as Trash } from "../assets/trash.svg";
import { ReactComponent as Select } from "../assets/Select.svg";
import { ReactComponent as Flag } from "../assets/Flag.svg";
import { ReactComponent as FlagSolid } from "../assets/Flag_Solid.svg";
import { ReactComponent as ChevronRight } from "../assets/ChevronRight.svg";
import { ReactComponent as Company } from "../assets/Company.svg";
import { ReactComponent as Bag } from "../assets/Bag.svg";
import { ReactComponent as Paper } from "../assets/Paper.svg";
import { ReactComponent as Expand } from "../assets/Expand.svg";
import { ReactComponent as Collapse } from "../assets/Collapse.svg";
import { ReactComponent as Tune } from "../assets/Tune.svg";
import { ReactComponent as Save } from "../assets/Save.svg";
import { ReactComponent as Trending } from "../assets/Trending.svg";
import { ReactComponent as LaptopWithTick } from "../assets/LaptopWithTick.svg";
import { ReactComponent as Replace } from "../assets/Replace.svg";
import { ReactComponent as CloudUpload } from "../assets/CloudUpload.svg";

const icons = {
  account: <Account />,
  alert: <AlertIcon />,
  angleDown: <AngleDownIcon />,
  angleUp: <AngleUpIcon />,
  arrowDown: <ArrowDownIcon />,
  arrowLeft: <ArrowLeftIcon />,
  arrowLeft2: <ArrowLeft2Icon />,
  arrowRight: <ArrowRightIcon />,
  assign: <AssignIcon />,
  bag: <Bag />,
  bell: <BellIcon />,
  blankLink: <BlankLink />,
  canvas: <Canvas />,
  check: <CheckIcon />,
  chevronRight: <ChevronRight />,
  close: <CloseIcon />,
  closeValueMap: <CloseValueMap />,
  cloudUpload: <CloudUpload />,
  collapse: <Collapse />,
  comment: <CommentIcon />,
  company: <Company />,
  copy: <CopyIcon />,
  csv: <CSVIcon />,
  deactivateUser: <DeactivateUser />,
  delete: <DeleteIcon />,
  devices: <Devices />,
  download: <DownloadIcon />,
  dropdown: <DropdownIcon />,
  drop: <Drop />,
  edit: <PenIcon />,
  email: <Email />,
  error500: <Error500 />,
  eye: <Eye />,
  expand: <Expand />,
  feedback: <Feedback />,
  flag: <Flag />,
  flagSolid: <FlagSolid />,
  help: <HelpIcon />,
  helpGrey: <HelpGrey />,
  image: <Image />,
  info: <InfoIcon />,
  infoCircle: <InfoCircle />,
  laptopWithTick: <LaptopWithTick />,
  left: <Left />,
  link: <LinkIcon />,
  lock: <Lock />,
  logOut: <LogOut />,
  minus: <MinusIcon />,
  newDelete: <NewDelete />,
  notes: <NotesIcon />,
  open: <OpenInNewIcon />,
  openValueMap: <OpenValueMap />,
  organization: <Organization />,
  paper: <Paper />,
  paperEdit: <PaperEdit />,
  paperPlane: <PaperPlane />,
  pdf: <PDFIcon />,
  pencil: <PencilIcon />,
  play: <PlayIcon />,
  plus: <PlusIcon />,
  plusCircle: <PlusCircleIcon />,
  powerpoint: <PowerpointIcon />,
  reactivateUser: <ReactivateUser />,
  replace: <Replace />,
  reset: <ResetIcon />,
  right: <Right />,
  rocket: <RocketIcon />,
  save: <Save />,
  select: <Select />,
  settings: <SettingsIcon />,
  share: <ShareIcon />,
  tachometer: <Tachometer />,
  tick: <Tick />,
  trash: <Trash />,
  trending: <Trending />,
  tune: <Tune />,
  unselect: <Unselect />,
  valueMapOpenFullScreen: <ValueMapOpenFullScreen />,
  xls: <XLS />
};

const Icon = React.forwardRef(
  ({ name, size = "base", colour, ...props }, ref) => {
    const sizes = {
      base: "13px",
      small: "6px",
      sMedium: "10px",
      medium: "16px",
      large: "20px",
      larger: "24px",
      xlarge: "30px"
    };

    return (
      <IconContainer
        size={"string" === typeof size ? sizes[size] : `${size}px`}
        alt={name}
        colour={colour}
        name={name}
        ref={ref}
        {...props}
      >
        {icons[name]}
      </IconContainer>
    );
  }
);

Icon.propTypes = {
  name: PropTypes.oneOf(Object.keys(icons)).isRequired,
  size: PropTypes.oneOfType([
    PropTypes.oneOf([
      "base",
      "small",
      "sMedium",
      "medium",
      "large",
      "larger",
      "xlarge"
    ]),
    PropTypes.number
  ]),
  colour: PropTypes.oneOf([
    "inherit",
    "primary",
    "secondary",
    "text",
    "gray3",
    "gray4",
    "success",
    "surface",
    "error",
    "white"
  ])
};
Icon.defaultProps = {
  size: "base",
  colour: "inherit"
};

export default Icon;

const IconContainer = styled.span`
  line-height: inherit;
  vertical-align: middle;
  color: ${(props) =>
    props.colour === "inherit"
      ? "inherit"
      : themeProp(`palette.${props.colour}`)(props)};

  svg {
    height: ${(props) => props.size};
    width: ${(props) => props.size};
    path {
      fill: currentColor;
    }
    line {
      stroke: currentColor;
    }
  }
`;
