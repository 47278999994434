import types from "../actionTypes";
import accessService from "../../services/access.service";
import aiService from "../../services/ai.service";

export const companyResearch =
  ({
    email,
    companyName,
    companyWebsite,
    productName,
    marketSegment,
    top5Competitors
  }) =>
  (dispatch) => {
    dispatch({
      type: types.COMPANY_RESEARCH_REQUEST,
      parameters: {
        email,
        companyName,
        companyWebsite,
        productName,
        marketSegment,
        top5Competitors
      }
    });

    return aiService
      .researchCompany({
        email,
        companyName,
        companyWebsite,
        productName,
        marketSegment,
        top5Competitors
      })
      .then((payload) => {
        dispatch({
          type: types.COMPANY_RESEARCH_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.COMPANY_RESEARCH_FAILURE,
          error
        });
      });
  };

export const getAccess =
  ({ accessId }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_ACCESS_REQUEST
    });

    return accessService
      .getAccess({
        accessId
      })
      .then((payload) => {
        dispatch({
          type: types.GET_ACCESS_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_ACCESS_FAILURE,
          error
        });
      });
  };

export const grantAccess =
  ({ accessId }) =>
  (dispatch) => {
    dispatch({
      type: types.GRANT_ACCESS_REQUEST
    });

    return accessService
      .grantAccess({
        accessId
      })
      .then((payload) => {
        dispatch({
          type: types.GRANT_ACCESS_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GRANT_ACCESS_FAILURE,
          error
        });
      });
  };
