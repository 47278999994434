import Menu, { MenuItem } from "../../components/Menu";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useString as s } from "../../components/StringProvider";
import Icon from "../../components/Icon";
import Dropdown from "../../components/Dropdown";
import {
  hasPermission,
  selectDiscovery,
  selectDiscoveryId,
  selectDiscoveryReportId,
  isDiscoveryRegisterInterestComplete
} from "../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { Operations } from "../DiscoveryOperations/DiscoveryOperations";
import { startDiscoveryOperation } from "../../store/actions/discovery-operations";
import { Phases } from "../../utils/phases";
import PropTypes from "prop-types";
import NewButton from "../NewButton";
import Permissions from "../../utils/permissions";
import { useCustomMediaQuery, useMobileMediaQuery } from "../Responsive";
import { exportDiscovery } from "../../store/actions/discoveries";
import { useFeature } from "../FeaturesProvider";
import { Userpilot } from "userpilot";
import { RESOURCE_CENTER } from "../../utils/user-pilot-util";

const DiscoveryDropdown = ({
  discoveryId,
  discovery,
  reportId,
  canExportPowerpoint,
  canExportDiscoveryExcel,
  canSeeDiscoveryDropdownMenu,
  canSeeShareButton,
  canSeeCloneButton,
  canSeeSolutions,
  canSeeEmailButton,
  startDiscoveryOperation,
  template,
  exportDiscovery,
  isCanvasMode = false,
  getPopupContainer,
  canSeeLeadGeneration,
  canSeeMyDiscoveries,
  canSeeDiscoveryHelp,
  canExportToPDF,
  isRegistered
}) => {
  const isMobile = useMobileMediaQuery();
  const { isBreakPoint: showHiddenMenuItem } = useCustomMediaQuery({
    type: "between",
    startBreakPoint: 966,
    endBreakPoint: 1280
  });

  const isMandatoryQuestionsCompleted = discovery.mandatoryQuestionsCompleted;

  const rocketShipEnabled = useFeature("rocketShip", true);
  const cloneText = s("discovery.operation.clone.name", "Clone");
  const shareText = s("discovery.operation.share.name", "Share");
  const emailText = s("discovery.operation.email.name", "Email");
  const solutionsText = s("discovery.operation.solutions.name", "Solutions");
  const exportExcelText = s(
    "discovery.operation.exportExcel.name",
    "Export data"
  );
  const exportPPTText = s("discovery.operation.exportPPT.name", "Export PPT");
  const printText = s("discovery.operation.print.name", "Export PDF report");
  const downloadPDFText = s("discovery.operation.download.pdf", "Download PDF");
  const helpText = s("discovery.operation.help", "Help");
  const closeDiscoveryText = s(
    "discovery.operation.close.discovery",
    "Close discovery"
  );
  const moreActionsText = s(
    "discovery.operation.moreActions.name",
    "More actions"
  );

  const history = useHistory();
  const [saveAndCloseLink, setSaveAndCloseLink] = useState("/");

  useEffect(() => {
    if (!canSeeMyDiscoveries && discovery && discovery.invitationCode) {
      setSaveAndCloseLink(`/invitations/${discovery.invitationCode}`);
    } else {
      setSaveAndCloseLink("/");
    }
  }, [discovery, canSeeMyDiscoveries]);

  const triggerUserpilotResourceCenter = () => {
    Userpilot.trigger(RESOURCE_CENTER);
  };

  const onClick = (e) => {
    const { key } = e;

    switch (key) {
      case Operations.EXPORT_DISCOVERY_EXCEL:
        exportDiscovery({ reportId, format: "data-xlsx" });
        break;

      case Operations.CLOSE:
        history.push(saveAndCloseLink);
        break;

      case Operations.HELP:
        triggerUserpilotResourceCenter();
        break;

      default:
        startDiscoveryOperation({
          operationId: key,
          operationDiscoveryId: discoveryId,
          parameters: { template }
        });
        break;
    }
  };

  const renderMenu = () => (
    <Menu onClick={onClick}>
      {canSeeEmailButton && (showHiddenMenuItem || isCanvasMode || isMobile) ? (
        <MenuItem
          key={Operations.EMAIL}
          icon={<Icon name={"email"} />}
          className={"discovery-dropdown-menu-email up-email-button"}
          disabled={!isMandatoryQuestionsCompleted}
        >
          {emailText}
        </MenuItem>
      ) : null}
      {canSeeShareButton && (showHiddenMenuItem || isCanvasMode || isMobile) ? (
        <MenuItem
          key={Operations.SHARE}
          icon={<Icon name={"share"} />}
          className={"discovery-dropdown-menu-share up-share-button"}
        >
          {shareText}
        </MenuItem>
      ) : null}
      {canExportPowerpoint ? (
        <MenuItem
          key={Operations.EXPORT}
          icon={<Icon name={"powerpoint"} />}
          className={"discovery-dropdown-menu-powerpoint"}
          disabled={!isMandatoryQuestionsCompleted}
        >
          {exportPPTText}
        </MenuItem>
      ) : null}
      {canExportDiscoveryExcel ? (
        <MenuItem
          key={Operations.EXPORT_DISCOVERY_EXCEL}
          icon={<Icon name={"xls"} />}
          className={"discovery-dropdown-menu-excel"}
          disabled={!isMandatoryQuestionsCompleted}
        >
          {exportExcelText}
        </MenuItem>
      ) : null}
      {canExportToPDF ? (
        <MenuItem
          key={Operations.PRINT}
          icon={<Icon name={"pdf"} />}
          className={"discovery-dropdown-menu-pdf"}
          disabled={
            canSeeLeadGeneration
              ? !isRegistered
              : !isMandatoryQuestionsCompleted
          }
        >
          {canSeeLeadGeneration ? downloadPDFText : printText}
        </MenuItem>
      ) : null}
      {canSeeSolutions && rocketShipEnabled ? (
        <MenuItem
          key={Operations.SOLUTIONS}
          icon={<Icon name={"rocket"} />}
          className={"discovery-dropdown-menu-solutions"}
          disabled={!isMandatoryQuestionsCompleted}
        >
          {solutionsText}
        </MenuItem>
      ) : null}
      {canSeeCloneButton ? (
        <MenuItem
          key={Operations.CLONE}
          icon={<Icon name={"copy"} />}
          className={"discovery-dropdown-menu-clone"}
        >
          {cloneText}
        </MenuItem>
      ) : null}
      {canSeeDiscoveryHelp && (
        <MenuItem
          key={Operations.HELP}
          icon={<Icon name={"help"} />}
          className={"discovery-dropdown-menu-help"}
        >
          {helpText}
        </MenuItem>
      )}

      {!canSeeLeadGeneration && (
        <MenuItem
          key={Operations.CLOSE}
          icon={<Icon name={"close"} />}
          className={"discovery-dropdown-menu-close"}
        >
          {closeDiscoveryText}
        </MenuItem>
      )}
    </Menu>
  );

  return canSeeDiscoveryDropdownMenu ? (
    <Dropdown
      overlay={renderMenu()}
      placement={"bottomLeft"}
      trigger={["click"]}
      getPopupContainer={getPopupContainer}
    >
      <NewButton
        type={"iconDarkPrimary"}
        tooltip={moreActionsText}
        data-cy={"discovery-dropdown"}
        className={"up-more-actions"}
      >
        <Icon name={"dropdown"} size={"medium"} />
      </NewButton>
    </Dropdown>
  ) : null;
};

DiscoveryDropdown.propTypes = {
  template: PropTypes.oneOf(Phases.ALL).isRequired
};

const mapStateToProps = (state) => ({
  discoveryId: selectDiscoveryId(state),
  discovery: selectDiscovery(state),
  reportId: selectDiscoveryReportId(state),
  canExportPowerpoint: hasPermission(state, Permissions.EXPORT_POWERPOINT),
  canExportDiscoveryExcel: hasPermission(
    state,
    Permissions.EXPORT_DISCOVERY_EXCEL
  ),
  canSeeDiscoveryDropdownMenu: hasPermission(
    state,
    Permissions.SEE_DISCOVERY_DROPDOWN_MENU
  ),
  canSeeShareButton: hasPermission(state, Permissions.SEE_SHARE_BUTTON),
  canSeeCloneButton: hasPermission(
    state,
    Permissions.SEE_CLONE_DISCOVERY_BUTTON
  ),
  canSeeSolutions: hasPermission(state, Permissions.SEE_SOLUTIONS),
  canSeeEmailButton: hasPermission(state, Permissions.SHARE_EDIT),
  canSeeLeadGeneration: hasPermission(state, Permissions.SEE_LEAD_GENERATION),
  canSeeMyDiscoveries: hasPermission(state, Permissions.SEE_MY_DISCOVERIES),
  canSeeDiscoveryHelp: hasPermission(state, Permissions.SEE_DISCOVERY_HELP),
  canExportToPDF: hasPermission(state, Permissions.EXPORT_TO_PDF),
  isRegistered: isDiscoveryRegisterInterestComplete(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startDiscoveryOperation,
      exportDiscovery
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DiscoveryDropdown
);
