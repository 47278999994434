import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useString as s } from "../../../components/StringProvider";
import { themeProp } from "../../../theme";
import Heading from "../../../components/Heading";
import Card from "../../../components/Card";
import Metric from "../../../components/Metric";
import Currency from "../../../components/Currency";
import {
  selectDiscoveryCashflow,
  selectDiscoveryCurrency,
  selectDiscoveryROI
} from "../../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";

const PrintROIStatistics = ({ cashflow, roi, currency }) => {
  const {
    totalInvestment,
    totalBenefit,
    totalNetBenefit,
    annualNetBenefit,
    paybackPeriod,
    isMaxPaybackPeriod
  } = cashflow;
  const moreThanText = s(
    "discovery.roi.summary.statistics.paybackPeriod.moreThan",
    "More than {paybackPeriod, select, 1 {{paybackPeriod} Year} other {{paybackPeriod} Years}}",
    { paybackPeriod }
  );
  const lessThanText = s(
    "discovery.roi.summary.statistics.paybackPeriod.lessThan",
    "Less than {paybackPeriod, select, 1 {{paybackPeriod} Year} other {{paybackPeriod} Years}}",
    { paybackPeriod }
  );
  const { evaluationPeriod } = roi;

  return (
    <Container>
      <Header level="h4" bold>
        {evaluationPeriod}{" "}
        {s(
          "discovery.roi.summary.statistics.header",
          "Year Return On Investment"
        )}
      </Header>
      <Body>
        <Metric
          label={s(
            "discovery.roi.summary.statistics.metric1.label",
            "Investment"
          )}
          large
          printMode
        >
          <Currency format="short" currency={currency}>
            {totalInvestment}
          </Currency>
        </Metric>
        <Metric
          label={s("discovery.roi.summary.statistics.metric2.label", "Benefit")}
          large
          printMode
        >
          <Currency format="short" currency={currency}>
            {totalBenefit}
          </Currency>
        </Metric>
        <Metric
          label={s(
            "discovery.roi.summary.statistics.metric3.label",
            "Net Benefit"
          )}
          large
          printMode
        >
          <Currency format="short" currency={currency}>
            {totalNetBenefit}
          </Currency>
        </Metric>

        <Metric
          label={s("discovery.roi.summary.statistics.metric4.label", "ROI")}
          help={s(
            "discovery.roi.summary.statistics.metric4.tooltip",
            "ROI = (Total Benefit - Total Investment) / Total Investment"
          )}
          tooltipColor={"surface"}
          large
          printMode
        >
          {cashflow.returnOnInvestmentPercentage}%
        </Metric>
        <Metric
          label={s("discovery.roi.summary.statistics.metric5.label", "Payback")}
          help={s(
            "discovery.roi.summary.statistics.metric5.tooltip",
            "Simple estimate of the payback period to the nearest month"
          )}
          tooltipColor={"surface"}
          large
          printMode
        >
          {isMaxPaybackPeriod ? moreThanText : lessThanText}
        </Metric>
        <Metric
          label={s(
            "discovery.roi.summary.statistics.metric6.label",
            "Annual Net Benefit"
          )}
          help={s(
            "discovery.roi.summary.statistics.metric6.tooltip",
            "Annual net benefit once the solution is in steady state"
          )}
          tooltipColor={"surface"}
          large
          printMode
        >
          <Currency format="short" currency={currency}>
            {annualNetBenefit}
          </Currency>
        </Metric>
      </Body>
    </Container>
  );
};

PrintROIStatistics.propTypes = {
  cashflow: PropTypes.shape({
    investment: PropTypes.array,
    benefit: PropTypes.array,
    netBenefit: PropTypes.array,
    cumulativeBenefit: PropTypes.array
  }),
  evaluationPeriod: PropTypes.number,
  currency: PropTypes.string.isRequired
};

const Container = styled(Card)`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${themeProp("palette.secondary")};
  padding: 24px;
  color: white;
`;

const Header = styled(Heading)`
  color: white;
  opacity: 0.75;
  margin-bottom: 16px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const mapStateToProps = (state) => ({
  currency: selectDiscoveryCurrency(state),
  roi: selectDiscoveryROI(state),
  cashflow: selectDiscoveryCashflow(state)
});

export default compose(connect(mapStateToProps))(PrintROIStatistics);
