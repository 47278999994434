import React, { useEffect, useState } from "react";
import TurndownService from "turndown";
import PropTypes from "prop-types";
import { Paragraph } from "../../../components/Typography";
import { useString as s } from "../../StringProvider";
import styled, { css } from "styled-components";
import { RichTextEditor } from "../../Input";
import NewButton from "../../NewButton";
import { themeProp } from "../../../theme";
import Spinner from "../../Spinner";
import useCopyClipboard from "../../../utils/use-copy-clipboard";
import isCopySupported from "../../../utils/is-copy-supported";
import { marked } from "marked";
import { message } from "antd";

const EmailModalEditor = ({
  isContentGenerated,
  isContentLoading,
  emailContent
}) => {
  const copyButtonLabel = s(
    "email.popup.copy.email.button.label",
    "Copy to clipboard"
  );
  const editorDisabledMessage = s(
    "email.popup.editor.disabled.message",
    "Select & generate your content to get started"
  );
  const copySuccess = s(
    "email.popup.messages.copySuccess",
    "Email copied to clipboard"
  );
  const copyError = s("email.popup.messages.copyError", "Failed to copy");

  const turndownService = new TurndownService();

  const { onCopy } = useCopyClipboard({
    showNotification: true,
    copySuccessMessage: copySuccess,
    copyFailedMessage: copyError
  });

  const [value, setValue] = useState("");
  const [onChangeValue, setOnChangeValue] = useState("");

  useEffect(() => {
    if (emailContent?.body) {
      setValue(turndownService.turndown(emailContent.body));
      setOnChangeValue(turndownService.turndown(emailContent.body));
    }
  }, [emailContent]);

  const handleCopyToClipboard = (content) => {
    const { ClipboardItem } = window;

    if (isCopySupported) {
      const blob = new Blob([marked(content)], {
        type: "text/html"
      });
      const data = [new ClipboardItem({ "text/html": blob })];

      navigator.clipboard
        .write(data)
        .then(() => {
          message.success(copySuccess);
        })
        .catch(() => {
          message.error(copyError);
        });
    } else {
      onCopy(content);
    }
  };

  return (
    <Container isContentGenerated={!isContentGenerated}>
      <div>
        <RichTextEditor
          value={value}
          autoFocus={false}
          style={{
            minHeight: !isContentGenerated ? 0 : 545
          }}
          removeBorder={true}
          useMarkdown={true}
          canUseUpdateValuePlugin={true}
          setOnChangeValue={setOnChangeValue}
        />

        {!isContentGenerated && (
          <Paragraph
            align="center"
            style={{
              margin: "20px 0px"
            }}
            color={"gray4"}
            variant={"body"}
          >
            {editorDisabledMessage}
          </Paragraph>
        )}
      </div>
      <StyledNewButton
        disabled={!isContentGenerated}
        onClick={() => handleCopyToClipboard(onChangeValue)}
        data-cy={"copy-to-clipboard-button"}
      >
        {copyButtonLabel}
      </StyledNewButton>
      {isContentLoading && (
        <LoaderContainer>
          <Spinner fontSize={42} data-cy={"generate-email-spinner"} />
        </LoaderContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 16px;
  position: relative;

  ${({ isContentGenerated }) =>
    isContentGenerated &&
    css`
      cursor: not-allowed;
      position: relative;

      &::before {
        content: "";
        background-color: ${themeProp("palette.surface")};
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 100;
        border-radius: 4px;
        opacity: 0.3;
        cursor: not-allowed;
      }
    `}
`;

const StyledNewButton = styled(NewButton)`
  align-self: flex-end;
  margin-right: 16px;
`;

const LoaderContainer = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
`;

EmailModalEditor.propTypes = {
  isContentGenerated: PropTypes.bool.isRequired,
  isContentLoading: PropTypes.bool.isRequired,
  emailContent: PropTypes.shape({
    body: PropTypes.string.isRequired
  })
};

export default EmailModalEditor;
