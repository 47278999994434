import { selectRequestData } from "../../../../store/reducers";
import actionTypes from "../../../../store/actionTypes";
import { bindActionCreators, compose } from "redux";
import { generateAutoconfiguration } from "../../../../store/actions/autoconfigurations";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AutoconfigurationLayout from "./AutoconfigurationLayout";
import { useEffect, useState } from "react";
import Table from "../../../../components/Table";
import { useString as s } from "../../../../components/StringProvider";

const AutoconfigurationKPIs = ({
  autoconfiguration,
  generateAutoconfiguration
}) => {
  const [ready, setReady] = useState(false);
  const [data, setData] = useState([]);
  const columns = [];

  useEffect(() => {
    if (autoconfiguration) {
      setData(
        (autoconfiguration?.kpis || []).map((o) => ({
          ...o,
          key: o.code
        }))
      );
    }
  }, [autoconfiguration]);

  columns.push({
    title: s(
      "organization.page.autoconfiguration.autoconfigurationWizard.kpis.code",
      "Code"
    ),
    dataIndex: "code",
    key: "code"
  });

  columns.push({
    title: s(
      "organization.page.autoconfiguration.autoconfigurationWizard.kpis.benefitValueDriverDescription",
      "Name"
    ),
    dataIndex: "name",
    key: "name"
  });

  columns.push({
    title: s(
      "organization.page.autoconfiguration.autoconfigurationWizard.kpis.benefitValueDriverDescription",
      "Benefit Value Driver Description"
    ),
    dataIndex: "benefitValueDriverDescription",
    key: "benefitValueDriverDescription"
  });

  columns.push({
    title: s(
      "organization.page.autoconfiguration.autoconfigurationWizard.kpis.detailText",
      "Detail Text"
    ),
    dataIndex: "detailText",
    key: "detailText"
  });

  columns.push({
    title: s(
      "organization.page.autoconfiguration.autoconfigurationWizard.kpis.category",
      "Category"
    ),
    dataIndex: "categoryCode",
    key: "categoryCode",
    render: (categoryCode) =>
      autoconfiguration.categories.find((c) => c.code === categoryCode)?.name
  });

  columns.push({
    title: s(
      "organization.page.autoconfiguration.autoconfigurationWizard.kpis.rangeMin",
      "Min"
    ),
    dataIndex: "rangeMin",
    key: "rangeMin"
  });

  columns.push({
    title: s(
      "organization.page.autoconfiguration.autoconfigurationWizard.kpis.rangeLowMed",
      "Low - Med"
    ),
    dataIndex: "rangeLowMed",
    key: "rangeLowMed"
  });

  columns.push({
    title: s(
      "organization.page.autoconfiguration.autoconfigurationWizard.kpis.rangeMedHigh",
      "Med - High"
    ),
    dataIndex: "rangeMedHigh",
    key: "rangeMedHigh"
  });

  columns.push({
    title: s(
      "organization.page.autoconfiguration.autoconfigurationWizard.kpis.rangeMax",
      "Max"
    ),
    dataIndex: "rangeMax",
    key: "rangeMax"
  });

  useEffect(() => {
    if (!autoconfiguration?.kpis?.length) {
      generateAutoconfiguration({
        autoconfigurationId: autoconfiguration._id,
        section: "kpis"
      });
    } else if (autoconfiguration) {
      setReady(true);
    }
  }, [autoconfiguration]);

  return (
    <AutoconfigurationLayout>
      {ready && (
        <Table dataSource={data} columns={columns} pagination={false} />
      )}
    </AutoconfigurationLayout>
  );
};

const mapStateToProps = (state) => ({
  autoconfiguration: selectRequestData(
    state,
    actionTypes.GET_AUTOCONFIGURATION_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      generateAutoconfiguration
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AutoconfigurationKPIs);
