import styled from "styled-components";
import ButtonGroup from "../ButtonGroup";
import { useMobileMediaQuery } from "../Responsive";

const DiscoveryFooter = ({ children }) => {
  const isMobile = useMobileMediaQuery();
  return (
    <Container isMobile={isMobile}>
      <ButtonGroup>{children}</ButtonGroup>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: ${({ isMobile }) => (isMobile ? "16px" : "40px")};
  padding-bottom: ${({ isMobile }) => (isMobile ? "50px" : "40px")};
`;

export default DiscoveryFooter;
