import TextBody from "../../components/TextBody";
import NewButton from "../../components/NewButton";
import { useString as s } from "../../components/StringProvider";
import ButtonGroup from "../../components/ButtonGroup";
import {
  selectDeleteChallengeCode,
  selectDiscoveryId,
  selectDiscoveryBusinessCriticalChallengeCode
} from "../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import { stopDeletingChallenge } from "../../store/actions/delete-challenges";
import { deleteDiscoveryChallenge } from "../../store/actions/create-challenges";
import { Modal } from "../../components";

const DeleteChallengeModal = ({
  discoveryId,
  challengeCode,
  deleteDiscoveryChallenge,
  stopDeletingChallenge,
  businessCriticalChallengeCode
}) => {
  const title = s(
    "discovery.challenge.card.delete.modal.title",
    "Delete Challenge"
  );
  const cbiTitle = s(
    "discovery.challenge.card.delete.modal.title",
    "Delete Critical Business Issue"
  );
  const text = s(
    "discovery.challenge.card.delete.modal.text",
    "By continuing, your custom challenge will be removed from the discovery and deleted"
  );
  const ok = s("discovery.challenge.card.delete.modal.ok", "Delete");
  const cancel = s("discovery.challenge.card.delete.modal.cancel", "Cancel");

  if (!challengeCode) {
    return null;
  }

  const isCBI = businessCriticalChallengeCode === challengeCode;

  const onClose = () => {
    stopDeletingChallenge();
  };

  const onConfirm = () => {
    deleteDiscoveryChallenge({
      discoveryId,
      challengeCode,
      isCBI
    });
  };
  return (
    <Modal
      title={isCBI ? cbiTitle : title}
      open={true}
      onCancel={onClose}
      data-cy={"delete-challenge-modal"}
    >
      <TextBody>{text}</TextBody>
      <div style={{ paddingTop: 24 }}>
        <ButtonGroup>
          <NewButton
            type={"primary"}
            onClick={onConfirm}
            data-cy={"delete-challenge-button"}
          >
            {ok}
          </NewButton>
          <NewButton type={"secondary"} onClick={onClose}>
            {cancel}
          </NewButton>
        </ButtonGroup>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  discoveryId: selectDiscoveryId(state),
  challengeCode: selectDeleteChallengeCode(state),
  businessCriticalChallengeCode:
    selectDiscoveryBusinessCriticalChallengeCode(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteDiscoveryChallenge,
      stopDeletingChallenge
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DeleteChallengeModal
);
