import { Checkbox as ANTCheckbox } from "antd";
import styled from "styled-components";
import { themeProp } from "../theme";

const Checkbox = styled(ANTCheckbox)`
  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${themeProp("palette.secondary")};
    border-color: ${themeProp("palette.secondary")};
  }

  & .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: ${themeProp("palette.gray2")};
    border-color: ${themeProp("palette.gray2")};

    &::after {
      border-color: ${themeProp("palette.surface")};
    }
  }

  &.ant-checkbox {
    font-family: ${themeProp("typography.body.fontFamily")};
    font-size: ${themeProp("typography.body.fontSize")};
    line-height: ${themeProp("typography.body.lineHeight")};
    font-weight: ${themeProp("typography.body.fontWeight")};
  }

  &-checked {
    .ant-checkbox-inner {
      background-color: ${themeProp("palette.secondary")};
      border-color: ${themeProp("palette.secondary")};

      &::after {
        border-width: 3px;
        margin-top: 1px;
      }
    }

    &::after {
      border-color: ${themeProp("palette.secondary")};
    }
  }

  &:hover {
    .ant-checkbox-inner {
      border-color: ${themeProp("palette.secondary")};
    }
  }

  .ant-checkbox-indeterminate {
    .ant-checkbox-inner {
      &::after {
        background-color: ${themeProp("palette.secondary")};
        top: 50%;
        left: 50%;
        width: 16px;
        height: 16px;
      }
    }
  }

  .ant-checkbox-inner {
    width: 24px;
    height: 24px;
  }

  .ant-checkbox-inner::after {
    top: 42%;
    left: 20%;
    width: 8px;
    height: 16px;
  }
`;

export default Checkbox;
