import styled from "styled-components";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { DiscoveryFooter } from "../../components/Discovery";
import ROIForm from "./components/ROIForm";
import ROISummary from "./components/ROISummary";
import { isDiscoveryROIComplete, selectDiscovery } from "../../store/reducers";
import DiscoveryContentWrapper from "../Discovery/DiscoveryContentWrapper";
import { FinishDiscoveryButton } from "../../components/DiscoveryButtons";
import { Mobile, useMobileMediaQuery } from "../../components/Responsive";
import ROIFormHeader from "./components/ROIFormHeader";
import Heading from "../../components/Heading";
import { useString as s } from "../../components/StringProvider";
import { Text } from "../../components";
import { useEffect, useState } from "react";
import DiscoverySubHeader from "../Discovery/DiscoverySubHeader";
import { Phases } from "../../utils/phases";

const ROI = ({ history, discovery, location, isROIComplete }) => {
  const isMobile = useMobileMediaQuery();
  const header = s(
    "discovery.roi.page.summary.header",
    "Return on Investment for {discoveryName}",
    { discoveryName: discovery.name }
  );
  const subheader = s(
    "discovery.roi.page.summary.subheader",
    "Choose your scenario and the benefit categories that should be included to adjust the evaluation."
  );

  const link1Text = s("discovery.roi.page.subheader.link1", "Edit Assumptions");

  const link2Text = s(
    "discovery.roi.page.subheader.link2",
    "Return on Investment"
  );

  const [editAssumptionsValue, setEditAssumptionsValue] = useState(undefined);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (!searchParams.has("edit-assumptions")) {
      history.replace({
        pathname: location.pathname,
        search: isROIComplete
          ? "edit-assumptions=false"
          : "edit-assumptions=true"
      });
    } else {
      setEditAssumptionsValue(searchParams.get("edit-assumptions") === "true");
    }
  }, [location, isROIComplete]);

  const renderSummary = () => (
    <>
      <Mobile>
        <ROIFormHeader className={isMobile ? "mobile" : undefined}>
          <Heading level={isMobile ? "h3" : "h2"}>{header}</Heading>
          <Text variant={isMobile ? "bodyMobile" : "body"}>{subheader}</Text>
        </ROIFormHeader>
      </Mobile>
      <ROISummary discovery={discovery} reportMode={false} />
      <DiscoveryFooter>
        <FinishDiscoveryButton variant={"primary"} block={isMobile} />
      </DiscoveryFooter>
    </>
  );

  const handleFormSubmit = () => {
    history.push({
      pathname: location.pathname,
      search: "edit-assumptions=false"
    });
  };

  const linkConfig = [
    {
      to: `/discoveries/${discovery._id}/roi?edit-assumptions=true`,
      label: link1Text
    },
    {
      to: `/discoveries/${discovery._id}/roi?edit-assumptions=false`,
      label: link2Text,
      conditionToEnableLink: isROIComplete
    }
  ];

  return (
    editAssumptionsValue !== undefined && (
      <DiscoveryContentWrapper format={"wide"} isMobile={isMobile}>
        <DiscoverySubHeader phase={Phases.ROI} linkConfig={linkConfig} />
        <ROIContainer>
          {editAssumptionsValue && (
            <ROIForm discovery={discovery} onSubmit={handleFormSubmit} />
          )}
          {!editAssumptionsValue && renderSummary()}
        </ROIContainer>
      </DiscoveryContentWrapper>
    )
  );
};

const ROIContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
`;

const mapStateToProps = (state) => ({
  discovery: selectDiscovery(state),
  isROIComplete: isDiscoveryROIComplete(state)
});

export default compose(withRouter, connect(mapStateToProps))(ROI);
