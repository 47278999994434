import Heading from "../Heading";
import { Divider } from "antd";
import Icon from "../Icon";
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  selectGroup,
  selectDiscoverySetting,
  selectDiscoveryId
} from "../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import Text from "../Text";
import NewButton from "../NewButton";
import { useString as s } from "../StringProvider";
import GroupVisualizations from "../../utils/group-visualizations";
import { useMobileMediaQuery } from "../Responsive";
import { updateDiscoverySettingsGroup } from "../../store/actions/discoveries";
import ExpandedOrCollapsed from "../../utils/ExpandedOrCollapsed";
import { isDiscoveryGroupCollapsed } from "../../store/selectors/discovery";

const Group = ({
  discoveryId,
  groupCode,
  group,
  collapsed,
  collapsible,
  children,
  updateDiscoverySettingsGroup,
  showGroupDescription
}) => {
  const isMobile = useMobileMediaQuery();
  const collapseSectionText = s("group.button.collapse", "Collapse section");
  const expandSectionText = s("group.button.expand", "Expand section");

  const renderContents = () => {
    return (
      <>
        {group.description && showGroupDescription ? (
          <GroupDescription>
            {group.visualizationOption ===
            GroupVisualizations.SUBTOTAL_GROUP ? (
              <Text variant={isMobile ? "bodyMobile" : "small"}>
                {group.description}
              </Text>
            ) : (
              <Text color={"gray4"}>{group.description}</Text>
            )}
          </GroupDescription>
        ) : null}
        <List>{children}</List>
        {collapsible ? <Divider /> : null}
      </>
    );
  };

  const toggleState = () => {
    updateDiscoverySettingsGroup({
      discoveryId,
      groupId: groupCode,
      status: !collapsed
        ? ExpandedOrCollapsed.COLLAPSED
        : ExpandedOrCollapsed.EXPANDED
    });
  };

  return (
    <Container order={group.order} data-cy={"group"}>
      <GroupHeader>
        <Heading level={isMobile ? "h4" : "h3"} data-cy={"group-title"}>
          {group.name}
        </Heading>
        {collapsible ? (
          <NewButton
            type={"iconSecondary"}
            onClick={toggleState}
            tooltip={collapsed ? expandSectionText : collapseSectionText}
          >
            {collapsed ? <Icon name="plus" /> : <Icon name="minus" />}
          </NewButton>
        ) : null}
      </GroupHeader>
      {!collapsed || !collapsible ? renderContents() : ""}
    </Container>
  );
};
Group.propTypes = {
  groupCode: PropTypes.string.isRequired,
  group: PropTypes.shape({
    description: PropTypes.string,
    order: PropTypes.number,
    name: PropTypes.string
  }).isRequired,
  collapsed: PropTypes.bool,
  showGroupDescription: PropTypes.bool,
  children: PropTypes.node,
  initiallyCollapsed: PropTypes.oneOf([undefined, true, false]),
  collapsible: PropTypes.bool
};
Group.defaultProps = {
  collapsed: false,
  initiallyCollapsed: undefined,
  showGroupDescription: true,
  collapsible: true
};

const Container = styled.div`
  order: ${(props) => props.order};
`;

const GroupDescription = styled.div`
  margin-bottom: 20px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const GroupHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const mapStateToProps = (state, props) => {
  const isGroupCollapsed = isDiscoveryGroupCollapsed(
    selectDiscoverySetting(state, "groups"),
    props.groupCode
  );

  const initiallyCollapsed = props.hasOwnProperty("initiallyCollapsed")
    ? props.initiallyCollapsed
    : true;

  const collapsed =
    isGroupCollapsed !== undefined ? isGroupCollapsed : initiallyCollapsed;

  return {
    discoveryId: selectDiscoveryId(state),
    group: selectGroup(state, props.groupCode),
    collapsed
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDiscoverySettingsGroup
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(Group);
