import React from "react";
import styled from "styled-components";
import { PageSection } from "../../../../components/Page";
import { useString as s } from "../../../../components/StringProvider";
import Heading from "../../../../components/Heading";
import {
  selectDiscovery,
  selectDiscoveryMetricGroupCodeString,
  selectDiscoveryUngroupedMetricCodeString
} from "../../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { isPhaseLessThanOrEqualTo, Phases } from "../../../../utils/phases";
import ReportMetric from "./ReportMetric";
import ReportMetricGroup from "./ReportMetricGroup";
import YourSituationColumns from "./YourSituationColumns";
import { useTheme } from "../../../../components/ThemeProvider";
import PropTypes from "prop-types";
import QuestionAndAnswer from "./QuestionAndAnswer";
import MobileReportSection from "../MobileReportSection";
import { Space } from "antd";
import MobileReportTitle from "../MobileReportTitle";
import { useMobileMediaQuery } from "../../../../components/Responsive";

const ReportYourSituation = ({
  template,
  reportId,
  discovery,
  metricCodeString,
  groupCodeString
}) => {
  const isMobile = useMobileMediaQuery();
  const coverImage = useTheme("assets.report_your_situation_cover");
  const header = s("report.your_situation.header", "Your Situation", {
    discoveryName: discovery.name
  });
  const subheader = s(
    "report.your_situation.subheader",
    "Segmentation Questions & Answers"
  );
  const subheader2 = s(
    "report.your_situation.subheader2",
    "Current Inputs Used to Calculate Value"
  );

  const renderContents = () => (
    <>
      <QuestionsAndAnswersColumns>
        {discovery.questions
          .filter((question) => question.active)
          .map((discoveryQuestion, index) => (
            <QuestionAndAnswer
              discoveryQuestion={discoveryQuestion}
              discoveryId={discovery._id}
              reportId={reportId}
              key={index}
              questionNumber={index}
            />
          ))}
      </QuestionsAndAnswersColumns>
      {isPhaseLessThanOrEqualTo(Phases.VALUE_HYPOTHESIS, template) &&
      (metricCodeString || groupCodeString) ? (
        <>
          <Heading
            level={isMobile ? "h4" : "h3"}
            data-cy={"report-current-business-metrics"}
          >
            {subheader2}
          </Heading>
          {metricCodeString ? (
            <ReportMetricsListWithMargin>
              {metricCodeString.split(",").map((metricCode, index) => (
                <ReportMetric metricCode={metricCode} key={index} />
              ))}
            </ReportMetricsListWithMargin>
          ) : null}
          {groupCodeString
            ? groupCodeString
                .split(",")
                .map((groupCode, index) => (
                  <ReportMetricGroup key={index} groupCode={groupCode} />
                ))
            : null}
        </>
      ) : null}
    </>
  );

  return isMobile ? (
    <MobileReportSection id={"report-your-situation"}>
      <Space direction={"vertical"} size={16}>
        <MobileReportTitle header={header} />
        <Heading level={"h4"}>{subheader}</Heading>
        {renderContents()}
      </Space>
    </MobileReportSection>
  ) : (
    <ReportROIContainer id={"report-your-situation"}>
      <Left image={coverImage} />
      <Right>
        <Heading level={"h1"}>{header}</Heading>
        <Heading level={"h3"}>{subheader}</Heading>
        {renderContents()}
      </Right>
    </ReportROIContainer>
  );
};

const QuestionsAndAnswersColumns = styled(YourSituationColumns)`
  margin-bottom: 40px;
`;

const ReportMetricsListWithMargin = styled(YourSituationColumns)`
  margin-bottom: 24px;
`;

const Left = styled.div`
  min-width: 420px;
  margin-left: 70px;
  margin-right: 100px;

  background-image: url(${(props) => props.image});
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
`;

const Right = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-contents: flex-start;
  padding-right: 100px;
`;

const ReportROIContainer = styled(PageSection)`
  padding: 120px 24px;
  display: flex;
  flex-direction: row;

  @media print {
    padding: 0px;
  }
`;

ReportYourSituation.propTypes = {
  template: PropTypes.oneOf(Phases.ALL).isRequired,
  reportId: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  discovery: selectDiscovery(state),
  metricCodeString: selectDiscoveryUngroupedMetricCodeString(state),
  groupCodeString: selectDiscoveryMetricGroupCodeString(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ReportYourSituation
);
