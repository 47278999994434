import cognitoStorageService from "./cognito-storage.service";
import { v4 as uuidv4 } from "uuid";

const saveState = (stateData) => {
  const storageService = cognitoStorageService.getStorage();
  const value = JSON.stringify(stateData);
  const state = uuidv4();

  storageService.setItem(state, value);
  return state;
};

const getState = ({ state }) => {
  const storageService = cognitoStorageService.getStorage();
  const stateData = storageService.getItem(state);

  storageService.removeItem(state);
  return stateData ? JSON.parse(stateData) : null;
};

export default {
  saveState,
  getState
};
