import React, { useEffect, useState } from "react";
import Input from "./Input";

const EmailInput = React.forwardRef(
  ({ value, onChange, ...props }, inputRef) => {
    const [currentValue, setCurrentValue] = useState(value);

    useEffect(() => {
      setCurrentValue(value);
    }, [value]);

    const handleOnChange = (event) => {
      const caret = event.target.selectionStart;
      const element = event.target;
      window.requestAnimationFrame(() => {
        element.selectionStart = caret;
        element.selectionEnd = caret;
      });

      setCurrentValue(event.target.value.toLowerCase());
      event.target.value = event.target.value.toLowerCase();

      if (onChange) {
        onChange(event);
      }
    };

    return (
      <Input
        value={currentValue}
        onChange={handleOnChange}
        {...props}
        ref={inputRef}
      />
    );
  }
);

export default EmailInput;
