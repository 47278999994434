import React, { useEffect, useState } from "react";
import { getDiscoveryHeaderMenuItems } from "./components/menu-helper";
import { useLocation, withRouter } from "react-router-dom";
import {
  hasPermission,
  isDiscoveryRegisterInterestComplete,
  selectDiscovery,
  selectDiscoveryPhase,
  selectDiscoveryId
} from "../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import Header, {
  AccountBadge,
  BrandBadge,
  HeaderMenuItem,
  HeaderMiddleSection
} from "../../components/Header";
import Link from "../../components/Link";
import PropType from "prop-types";
import Permissions from "../../utils/permissions";
import PhaseName from "../../components/PhaseName";
import { useString as s } from "../../components/StringProvider";
import Progress from "../../components/Progress";
import styled from "styled-components";
import { themeProp } from "../../theme";
import CanvasButton from "./CanvasButton";
import { Tooltip, Icon } from "../../components";
import { ShareButton, EmailButton } from "../../components/DiscoveryOperations";
import DiscoveryDropdown from "../../components/Discovery/DiscoveryDropdown";
import {
  useCustomMediaQuery,
  useMobileMediaQuery
} from "../../components/Responsive";
import DiscoveryHeaderMobileMenu from "./DiscoveryHeaderMobileMenu";
import ExportToPDFButton from "../../components/DiscoveryOperations/ExportToPDFButton";

const DiscoveryHeader = ({
  discovery,
  showMenu,
  showCloseButton,
  showControlPanel,
  showAccountBadge,
  canSeeMyDiscoveries,
  canSeeSelectChallenges,
  canSeeChallengeSummary,
  canSeeSelectKPIs,
  canSeeKPISummary,
  canSeeValueHypothesis,
  canSeeROIPage,
  isRegistered,
  canSeeLeadGeneration,
  canUpdateROIValues,
  discoveryId,
  phase,
  canSeeCanvas,
  isLeadGeneration,
  canExportToPDF,
  canSeeDiscoveryDropdownMenu
}) => {
  const shareText = s("share.popup.title", "Share");
  const emailText = s("email.popup.title", "Email");
  const tooltipText = s(
    "discovery.page.header.tooltip",
    "Complete questions first"
  );
  const saveAndCloseTooltip = s(
    "discovery.page.header.save.tooltip",
    "Close Discovery"
  );

  const isMobile = useMobileMediaQuery();
  const { isBreakPoint: showDropdownMenu } = useCustomMediaQuery({
    type: "max",
    singleBreakPoint: 966
  });

  const location = useLocation();
  const [menuItems, setMenuItems] = useState([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [saveAndCloseLink, setSaveAndCloseLink] = useState("/");

  const isCanvasSelected = location.pathname.includes("/valuemaps");

  const showExportToPDFButton =
    canSeeLeadGeneration &&
    canExportToPDF &&
    isRegistered &&
    !canSeeDiscoveryDropdownMenu;

  useEffect(() => {
    const menuItems = getDiscoveryHeaderMenuItems({
      discovery,
      pathname: location.pathname,
      canSeeSelectChallenges,
      canSeeChallengeSummary,
      canSeeSelectKPIs,
      canSeeKPISummary,
      canSeeValueHypothesis,
      canSeeROIPage,
      isDiscoveryRegisterInterestComplete: isRegistered,
      canSeeLeadGeneration,
      canUpdateROIValues
    });
    setMenuItems(menuItems);

    if (!canSeeMyDiscoveries && discovery) {
      setSaveAndCloseLink(`/invitations/${discovery.invitationCode}`);
    }
  }, [discovery, canSeeMyDiscoveries]);

  useEffect(() => {
    const selectedItem = menuItems?.find((item) => item.isSelected);

    if (selectedItem?.phase) {
      setSelectedMenuItem(selectedItem.phase);
    } else {
      setSelectedMenuItem(null);
    }
  }, [menuItems]);

  return (
    <Header data-cy={"discovery-header"}>
      <HeaderLeftSection isLeadGeneration={isLeadGeneration}>
        <BrandBadge
          linkTo={canSeeMyDiscoveries ? "/MyDiscoveries" : undefined}
        />
        {!canSeeLeadGeneration && discovery && showAccountBadge && (
          <AccountBadge canClick={canSeeMyDiscoveries} discovery={discovery} />
        )}
      </HeaderLeftSection>
      {showMenu ? (
        <HeaderMiddleSection data-cy={"discovery-navbar"}>
          {showDropdownMenu && menuItems ? (
            <MobileMenu>
              <DiscoveryHeaderMobileMenu
                menuItems={menuItems}
                selectedMenuItem={selectedMenuItem}
                discovery={discovery}
              />
              {Boolean(selectedMenuItem) && (
                <Progress percent={100} showInfo={false} color={"secondary"} />
              )}
            </MobileMenu>
          ) : (
            <>
              {menuItems?.map((item, index) =>
                item.visible ? (
                  <HeaderMenuItem key={index}>
                    <div className={item.status}>
                      {item.link ? (
                        <Link
                          to={`/discoveries/${discovery._id}${item.link}`}
                          color={
                            item.isSelected
                              ? "headerSelected"
                              : "headerUnselected"
                          }
                          disabled={item.disabled}
                          data-cy={"discovery-header-menu-item"}
                        >
                          <PhaseName phase={item.phase} />
                        </Link>
                      ) : (
                        <Tooltip title={tooltipText} placement={"bottom"}>
                          <DisabledLink data-cy={"discovery-header-menu-item"}>
                            <PhaseName phase={item.phase} />
                          </DisabledLink>
                        </Tooltip>
                      )}
                    </div>
                    {item.isSelected && (
                      <Progress
                        percent={100}
                        showInfo={false}
                        color={"secondary"}
                      />
                    )}
                  </HeaderMenuItem>
                ) : null
              )}
            </>
          )}
          {!isMobile && canSeeCanvas && (
            <HeaderMenuItem>
              <CanvasMenuItem>
                <CanvasButton isCanvasSelected={isCanvasSelected} />
                {isCanvasSelected && (
                  <Progress
                    percent={100}
                    showInfo={false}
                    color={"secondary"}
                  />
                )}
              </CanvasMenuItem>
            </HeaderMenuItem>
          )}
        </HeaderMiddleSection>
      ) : null}
      <HeaderRightSection isLeadGeneration={isLeadGeneration}>
        {showControlPanel && (
          <ControlPanel>
            {!isMobile && (
              <ControlPanelButtons>
                <EmailButton
                  discoveryId={discoveryId}
                  tooltip={emailText}
                  isMandatoryQuestionsCompleted={
                    discovery.mandatoryQuestionsCompleted
                  }
                  type={"iconDarkPrimary"}
                />
                <ShareButton
                  template={phase}
                  discoveryId={discoveryId}
                  tooltip={shareText}
                  type={"iconDarkPrimary"}
                />
              </ControlPanelButtons>
            )}
            <DiscoveryDropdown template={phase} />
          </ControlPanel>
        )}
        {showCloseButton && (
          <Tooltip title={saveAndCloseTooltip} placement={"left"}>
            <Link to={saveAndCloseLink} data-cy={"save-and-close-link"}>
              <Icon name="close" colour="gray4" size={"medium"} />
            </Link>
          </Tooltip>
        )}
        {showExportToPDFButton && <ExportToPDFButton />}
      </HeaderRightSection>
    </Header>
  );
};

export const HeaderLeftSection = styled.div`
  display: flex;
  align-items: center;
  width: ${(props) => (props.isLeadGeneration ? "146.48px" : "268.48px")};

  @media screen and (max-width: 1300px) {
    width: ${(props) => (props.isLeadGeneration ? "146.48px" : "auto")};
  }

  @media screen and (max-width: 577px) {
    width: 146.68px;
  }
`;

const HeaderRightSection = styled.div`
  display: flex;
  justify-content: flex-end;
  width: ${(props) => (props.isLeadGeneration ? "146.48px" : "268.48px")};

  @media screen and (max-width: 1300px) {
    width: ${(props) => (props.isLeadGeneration ? "146.48px" : "auto")};
  }

  @media screen and (max-width: 577px) {
    width: 146.68px;
  }
`;

export const MobileMenu = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  transform: translateX(10px);
`;

const CanvasMenuItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: fit-content;
  padding-left: 32px;
  position: relative;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    width: 2px;
    left: 0;
    height: 20px;
    background-color: ${themeProp("palette.gray2")};
  }

  @media screen and (max-width: 1028px) {
    padding-left: 24px;
  }
`;

export const ControlPanel = styled.div`
  display: flex;
  gap: 4px;
`;

export const ControlPanelButtons = styled.div`
  display: flex;
  gap: 4px;

  @media screen and (min-width: 966px) and (max-width: 1280px) {
    display: none;
  }
`;

export const DisabledLink = styled.div`
  cursor: not-allowed;
  color: ${themeProp("palette.gray3")};
`;

DiscoveryHeader.propTypes = {
  showMenu: PropType.bool,
  showCloseButton: PropType.bool,
  showControlPanel: PropType.bool,
  showAccountBadge: PropType.bool
};

DiscoveryHeader.defaultProps = {
  showMenu: true,
  showCloseButton: false,
  showControlPanel: true,
  showAccountBadge: true
};

const mapStateToProps = (state) => ({
  discovery: selectDiscovery(state),
  canSeeMyDiscoveries: hasPermission(state, Permissions.SEE_MY_DISCOVERIES),
  canSeeSelectChallenges: hasPermission(
    state,
    Permissions.SEE_SELECT_CHALLENGES
  ),
  canSeeChallengeSummary: hasPermission(
    state,
    Permissions.SEE_CHALLENGE_SUMMARY
  ),
  canSeeKPISummary: hasPermission(state, Permissions.SEE_KPI_SUMMARY),
  canSeeSelectKPIs: hasPermission(state, Permissions.SEE_SELECT_KPIS),
  canSeeValueHypothesis: hasPermission(state, Permissions.SEE_VALUE_HYPOTHESIS),
  canSeeROIPage: hasPermission(state, Permissions.SEE_ROI_PAGE),
  canUpdateROIValues: hasPermission(state, Permissions.UPDATE_ROI_VALUES),
  isRegistered: isDiscoveryRegisterInterestComplete(state),
  canSeeLeadGeneration: hasPermission(state, Permissions.SEE_LEAD_GENERATION),
  discoveryId: selectDiscoveryId(state),
  phase: selectDiscoveryPhase(state),
  canSeeCanvas: hasPermission(state, Permissions.SEE_CANVAS),
  isLeadGeneration: hasPermission(state, Permissions.SEE_LEAD_GENERATION),
  canExportToPDF: hasPermission(state, Permissions.EXPORT_TO_PDF),
  canSeeDiscoveryDropdownMenu: hasPermission(
    state,
    Permissions.SEE_DISCOVERY_DROPDOWN_MENU
  )
});

export default compose(withRouter, connect(mapStateToProps))(DiscoveryHeader);
