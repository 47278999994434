import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import discoveryData, * as fromDiscovery from "./discovery";
import discoveriesData, * as fromDiscoveries from "./discoveries";
import authentication, * as fromAuthentication from "./authentication";
import discoveryOperationsReducer, * as fromDiscoveryOperations from "./discovery-operations";
import masterDataReducer, * as fromMasterData from "./master-data";
import usersReducer, * as fromUsers from "./users";
import guestReducer, * as fromGuests from "./guests";
import commentsReducer, * as fromComments from "./comments";
import editKPIsReducer, * as fromEditKPIs from "./edit-kpis";
import createKPIsReducer, * as fromCreateKPIs from "./create-kpis";
import deleteKPIsReducer, * as fromDeleteKPIs from "./delete-kpis";
import editChallengesReducer, * as fromEditChallenges from "./edit-challenges";
import createChallengesReducer, * as fromCreateChallenges from "./create-challenges";
import deleteChallengesReducer, * as fromDeleteChallenges from "./delete-challenges";
import notificationsReducer, * as fromNotifications from "./notifications";
import previewSolutionsReducer, * as fromPreviewSolutions from "./preview-solutions";
import requestsReducer, * as fromRequests from "./requests";
import cookiesReducer, * as fromCookies from "./cookies";
import rolesReducer, * as fromRoles from "./roles";
import jobsReducer, * as fromJobs from "./jobs";
import kpiLibraryReducer, * as fromKPILibrary from "./kpi-library";
import challengeLibraryReducer, * as fromChallengeLibrary from "./challenge-library";
import fileListReducer, * as fromFileList from "./file-list";
import updateConfigurationModalsReducer, * as fromUpdateConfigurationModals from "./update-configuration-modals";
import fullscreenReducer, * as fromFullscreen from "./fullscreen";
import notificationMessageReducer, * as fromNotificationMessage from "./notification-message";
import Permissions from "../../utils/permissions";
import integrationsReducer, * as fromIntegrations from "./integrations";

const isNullOrUndefined = (v) => v === null || v === undefined;

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    authentication,
    discoveryData,
    discoveriesData,
    discoveryOperationsReducer,
    masterData: masterDataReducer,
    usersData: usersReducer,
    commentsData: commentsReducer,
    editKPIsData: editKPIsReducer,
    createKPIsData: createKPIsReducer,
    editChallengesData: editChallengesReducer,
    createChallengesData: createChallengesReducer,
    deleteChallengesData: deleteChallengesReducer,
    notificationsData: notificationsReducer,
    previewSolutionsData: previewSolutionsReducer,
    requestsData: requestsReducer,
    cookiesData: cookiesReducer,
    rolesData: rolesReducer,
    jobsData: jobsReducer,
    kpiLibraryData: kpiLibraryReducer,
    challengeLibraryData: challengeLibraryReducer,
    fileListData: fileListReducer,
    deleteKPIsData: deleteKPIsReducer,
    updateConfigurationModalsData: updateConfigurationModalsReducer,
    fullscreenData: fullscreenReducer,
    notificationMessageData: notificationMessageReducer,
    guestsData: guestReducer,
    integrationsData: integrationsReducer
  });

export default reducers;

export const isAuthenticated = (state) =>
  fromAuthentication.isAuthenticated(state.authentication);
export const isInitialLoginComplete = (state) =>
  fromAuthentication.isInitialLoginComplete(state.authentication);
export const selectLoginLoadingState = (state) =>
  fromAuthentication.selectLoginLoadingState(state.authentication);
export const selectInitializationError = (state) =>
  fromAuthentication.selectInitializationError(state.authentication);
export const selectRegistrationError = (state) =>
  fromAuthentication.selectRegistrationError(state.authentication);
export const selectUserId = (state) =>
  fromAuthentication.selectUserId(state.authentication);
export const selectUser = (state) =>
  fromAuthentication.selectUser(state.authentication);
export const selectIsCuvamaUser = (state) =>
  fromAuthentication.selectIsCuvamaUser(state.authentication);
export const selectAccessManagementUserById = (state, userId) =>
  fromUsers.selectUserFromUsers(state.usersData, userId);
export const selectLogoutLoadingState = (state) =>
  fromAuthentication.selectLogoutLoadingState(state.authentication);
export const selectAllPermissions = (state) =>
  fromAuthentication.selectAllPermissions(state.authentication);
export const hasPermission = (state, permission) =>
  fromAuthentication.hasPermission(state.authentication, permission);
export const selectUserInfoLoadingState = (state) =>
  fromAuthentication.selectUserInfoLoadingState(state.authentication);
export const selectUserInfoError = (state) =>
  fromAuthentication.selectUserInfoError(state.authentication);
export const selectForgotPasswordLoadingState = (state) =>
  fromAuthentication.selectForgotPasswordLoadingState(state.authentication);
export const selectForgotPasswordError = (state) =>
  fromAuthentication.selectForgotPasswordError(state.authentication);
export const selectForgotPasswordEmail = (state) =>
  fromAuthentication.selectForgotPasswordEmail(state.authentication);
export const isLoginWithUnconfirmedUser = (state) =>
  fromAuthentication.isLoginWithUnconfirmedUser(state.authentication);
export const isSignupWithExistingUser = (state) =>
  fromAuthentication.isSignupWithExistingUser(state.authentication);
export const selectConfirmationError = (state) =>
  fromAuthentication.selectConfirmationError(state.authentication);
export const selectRegisteredUsername = (state) =>
  fromAuthentication.selectRegisteredUsername(state.authentication);
export const selectRegistrationLoadingState = (state) =>
  fromAuthentication.selectRegistrationLoadingState(state.authentication);

export const selectDiscovery = (state) =>
  fromDiscovery.selectDiscovery(state.discoveryData);
export const selectDiscoveryCanvasLogoPath = (state) =>
  fromDiscovery.selectDiscoveryCanvasLogoPath(state.discoveryData);
export const selectDiscoveryCanvasTitle = (state) =>
  fromDiscovery.selectDiscoveryCanvasTitle(state.discoveryData);
export const selectDiscoveryId = (state) =>
  fromDiscovery.selectDiscoveryId(state.discoveryData);
export const selectDiscoveryUsername = (state) =>
  fromDiscovery.selectDiscoveryUsername(state.discoveryData);
export const selectDiscoveryEditors = (state) =>
  fromDiscovery.selectDiscoveryEditors(state.discoveryData);
export const selectDiscoveryName = (state) =>
  fromDiscovery.selectDiscoveryName(state.discoveryData);
export const selectDiscoveryPhase = (state) =>
  fromDiscovery.selectDiscoveryPhase(state.discoveryData);
export const selectDiscoveryMaximumPhase = (state) =>
  fromDiscovery.selectDiscoveryMaximumPhase(state.discoveryData);
export const selectDiscoveryCurrency = (state) =>
  fromDiscovery.selectDiscoveryCurrency(state.discoveryData);
export const selectDiscoveryMasterDataVersion = (state) =>
  fromDiscovery.selectDiscoveryMasterDataVersion(state.discoveryData);
export const selectDiscoveryTypeCode = (state) =>
  fromDiscovery.selectDiscoveryTypeCode(state.discoveryData);
export const selectDiscoveryQuestion = (state, questionNumber) =>
  fromDiscovery.selectDiscoveryQuestion(state.discoveryData, questionNumber);
export const selectDiscoveryQuestionAnswer = (state, questionNumber) =>
  fromDiscovery.selectDiscoveryQuestionAnswer(
    state.discoveryData,
    questionNumber
  );
export const selectDiscoveryQuestions = (state) =>
  fromDiscovery.selectDiscoveryQuestions(state.discoveryData);
export const selectDiscoveryChallenges = (state) =>
  fromDiscovery.selectDiscoveryChallenges(state.discoveryData);
export const selectDiscoveryBusinessCriticalChallengeCode = (state) =>
  fromDiscovery.selectDiscoveryBusinessCriticalChallengeCode(
    state.discoveryData
  );
export const selectDiscoverySelectedChallenges = (state) =>
  fromDiscovery.selectDiscoverySelectedChallenges(state.discoveryData);
export const selectDiscoveryChallenge = (state, challengeCode) =>
  fromDiscovery.selectDiscoveryChallenge(state.discoveryData, challengeCode);
export const selectDiscoveryUngroupedMetricCodeString = (
  state,
  showInvisible
) =>
  fromDiscovery.selectDiscoveryUngroupedMetricCodeString(
    state.discoveryData,
    showInvisible
  );
export const selectDiscoveryMetricsCount = (state, canSeeAllMetrics) =>
  fromDiscovery.selectDiscoveryMetricsCount(
    state.discoveryData,
    canSeeAllMetrics
  );
export const selectDiscoveryMetric = (state, metricCode) =>
  fromDiscovery.selectDiscoveryMetric(state.discoveryData, metricCode);
export const selectDiscoveryMetricCodeStringByGroup = (
  state,
  groupCode,
  showInvisible
) =>
  fromDiscovery.selectDiscoveryMetricCodeStringByGroup(
    state.discoveryData,
    groupCode,
    showInvisible
  );
export const selectDiscoveryMetricGroupCodeString = (state, showInvisible) =>
  fromDiscovery.selectDiscoveryMetricGroupCodeString(
    state.discoveryData,
    showInvisible
  );
export const selectDiscoveryUngroupedKPIs = (state, showKPIs) =>
  fromDiscovery.selectDiscoveryUngroupedKPIs(state.discoveryData, showKPIs);
export const selectDiscoveryKPI = (state, kpiCode) =>
  fromDiscovery.selectDiscoveryKPI(state.discoveryData, kpiCode);
export const selectDiscoveryKPIs = (state) =>
  fromDiscovery.selectDiscoveryKPIs(state.discoveryData);
export const selectDiscoveryAllKPIs = (state) =>
  fromDiscovery.selectDiscoveryAllKPIs(state.discoveryData);
export const selectDiscoverySelectedKPIs = (state) =>
  fromDiscovery.selectDiscoverySelectedKPIs(state.discoveryData);
export const selectDiscoveryKPIsByGroup = (state, groupCode, showKPIs) =>
  fromDiscovery.selectDiscoveryKPIsByGroup(
    state.discoveryData,
    groupCode,
    showKPIs
  );
export const selectDiscoveryKPIGroupCodeString = (state, showKPIs) =>
  fromDiscovery.selectDiscoveryKPIGroupCodeString(
    state.discoveryData,
    showKPIs
  );
export const selectDiscoveryResetMetricError = (state) =>
  fromDiscovery.selectDiscoveryResetMetricError(state.discoveryData);
export const selectDiscoveryROI = (state) =>
  fromDiscovery.selectDiscoveryROI(state.discoveryData);
export const selectDiscoveryScenario = (state) =>
  fromDiscovery.selectDiscoveryScenario(state.discoveryData);
export const selectDiscoveryDelayTimeline = ({
  state,
  reportMode,
  actionType
}) => {
  const authenticated = isAuthenticated(state);
  const delayTimelineDiscovery = fromDiscovery.selectDiscoveryDelayTimeline(
    state.discoveryData
  );

  if (reportMode && !authenticated) {
    const delayTimelineLocal = selectRequestData(state, actionType);

    if (isNullOrUndefined(delayTimelineLocal)) {
      return delayTimelineDiscovery;
    }

    return delayTimelineLocal;
  }

  return delayTimelineDiscovery;
};

export const selectDiscoveryShowAdditionalBenefits = ({
  state,
  reportMode,
  actionType
}) => {
  const authenticated = isAuthenticated(state);
  const showAdditionalBenefitsDiscovery = selectDiscoverySetting(
    state,
    "showAdditionalBenefits",
    false
  );

  if (reportMode && !authenticated) {
    const showAdditionalBenefitsLocal = selectRequestData(state, actionType);

    if (isNullOrUndefined(showAdditionalBenefitsLocal)) {
      return showAdditionalBenefitsDiscovery;
    }

    return showAdditionalBenefitsLocal;
  }

  return showAdditionalBenefitsDiscovery;
};
export const selectDiscoveryLoadingError = (state) =>
  fromDiscovery.selectDiscoveryLoadingError(state.discoveryData);
export const selectDiscoveryLoadingState = (state) =>
  fromDiscovery.selectDiscoveryLoadingState(state.discoveryData);
export const selectDiscoveryRefreshLoadingState = (state) =>
  fromDiscovery.selectDiscoveryRefreshLoadingState(state.discoveryData);
export const selectDiscoveryNewKPIOrder = (state, kpiCode, viewBy) =>
  fromDiscovery.selectDiscoveryNewKPIOrder(
    state.discoveryData,
    kpiCode,
    viewBy
  );
export const selectDiscoveryActiveSolutionCodes = (state, solutionCodes) =>
  fromDiscovery.selectDiscoveryActiveSolutionCodes(
    state.discoveryData,
    solutionCodes
  );
export const selectDiscoverySolutions = (state) =>
  fromDiscovery.selectDiscoverySolutions(state.discoveryData);
export const selectDiscoveryGroup = (state, groupCode) =>
  fromDiscovery.selectDiscoveryGroup(state.discoveryData, groupCode);
export const selectDiscoveryGroups = (state) =>
  fromDiscovery.selectDiscoveryGroups(state.discoveryData);
export const isDiscoveryROIComplete = (state) =>
  fromDiscovery.isDiscoveryROIComplete(state.discoveryData);
export const selectDiscoveryRequestROILoadingState = (state) =>
  fromDiscovery.selectDiscoveryRequestROILoadingState(state.discoveryData);

export const isDiscoveryRegisterInterestComplete = (state) =>
  fromDiscovery.isDiscoveryRegisterInterestComplete(state.discoveryData);

export const selectDiscoveryCheckpoint = (state) =>
  fromDiscovery.selectDiscoveryCheckpoint(state.discoveryData);
export const selectDiscoveryLastViewedPage = (state) =>
  fromDiscovery.selectDiscoveryLastViewedPage(state.discoveryData);

export const selectDiscoveries = (state) =>
  fromDiscoveries.selectDiscoveries(state.discoveriesData);
export const selectFetchDiscoveriesLoadingState = (state) =>
  fromDiscoveries.selectFetchDiscoveriesLoadingState(state.discoveriesData);
export const selectDiscoveriesPagination = (state) =>
  fromDiscoveries.selectDiscoveriesPagination(state.discoveriesData);
export const selectDiscoveriesCount = (state) =>
  fromDiscoveries.selectDiscoveriesCount(state.discoveriesData);
export const selectDiscoveriesOnlySamples = (state) =>
  fromDiscoveries.selectDiscoveriesOnlySamples(state.discoveriesData);
export const selectDiscoveriesFetchError = (state) =>
  fromDiscoveries.selectDiscoveriesFetchError(state.discoveriesData);
export const selectAddDiscoveryError = (state) =>
  fromDiscoveries.selectAddDiscoveryError(state.discoveriesData);
export const selectAddDiscoveryResult = (state) =>
  fromDiscoveries.selectAddDiscoveryResult(state.discoveriesData);
export const selectAddDiscoveryLoadingState = (state) =>
  fromDiscoveries.selectAddDiscoveryLoadingState(state.discoveriesData);
export const selectDiscoverySaveSolutionsLoadingState = (state) =>
  fromDiscovery.selectDiscoverySaveSolutionsLoadingState(state.discoveryData);
export const selectDiscoveryDifference = (state) =>
  fromDiscovery.selectDiscoveryDifference(state.discoveryData);
export const selectDiscoveryReportId = (state) =>
  fromDiscovery.selectDiscoveryReportId(state.discoveryData);
export const selectDiscoverySetting = (state, field, defaultValue) =>
  fromDiscovery.selectDiscoverySetting(
    state.discoveryData,
    field,
    defaultValue
  );
export const selectDiscoveryCashflow = (state) =>
  fromDiscovery.selectDiscoveryCashflow(state.discoveryData);

export const selectDiscoveryOperationId = (state) =>
  fromDiscoveryOperations.selectDiscoveryOperationId(
    state.discoveryOperationsReducer
  );
export const selectDiscoveryOperationDiscoveryId = (state) =>
  fromDiscoveryOperations.selectDiscoveryOperationDiscoveryId(
    state.discoveryOperationsReducer
  );
export const selectDiscoveryOperationReportId = (state) =>
  fromDiscoveryOperations.selectDiscoveryOperationReportId(
    state.discoveryOperationsReducer
  );
export const selectDiscoveryOperationConfirmation = (state) =>
  fromDiscoveryOperations.selectDiscoveryOperationConfirmation(
    state.discoveryOperationsReducer
  );
export const selectDiscoveryOperationAlert = (state) =>
  fromDiscoveryOperations.selectDiscoveryOperationAlert(
    state.discoveryOperationsReducer
  );
export const selectDiscoveryOperationParameters = (state) =>
  fromDiscoveryOperations.selectDiscoveryOperationParameters(
    state.discoveryOperationsReducer
  );
export const selectDiscoveryOperationResults = (state) =>
  fromDiscoveryOperations.selectDiscoveryOperationResults(
    state.discoveryOperationsReducer
  );

export const selectMasterDataLoadingState = (state) =>
  fromMasterData.selectMasterDataLoadingState(state.masterData);
export const selectMasterDataLoadingError = (state) =>
  fromMasterData.selectMasterDataLoadingError(state.masterData);
export const selectMasterDataVersion = (state) =>
  fromMasterData.selectMasterDataVersion(state.masterData);
export const selectCategory = (state, categoryCode) =>
  fromMasterData.selectCategory(state.masterData, categoryCode);
export const selectGroup = (state, groupCode) =>
  fromMasterData.selectGroup(state.masterData, groupCode);
export const selectQuestion = (state, questionCode) =>
  fromMasterData.selectQuestion(state.masterData, questionCode);
export const selectQuestions = (state) =>
  fromMasterData.selectQuestions(state.masterData);
export const selectMetric = (state, metricCode) =>
  fromMasterData.selectMetric(state.masterData, metricCode);
export const selectKPI = (state, kpiCode) =>
  fromMasterData.selectKPI(state.masterData, kpiCode);
export const selectSolution = (state, solutionCode) =>
  fromMasterData.selectSolution(state.masterData, solutionCode);

export const selectAllGroups = (state) =>
  fromMasterData.selectAllGroups(state.masterData);
export const selectAllCategories = (state) =>
  fromMasterData.selectAllCategories(state.masterData);
export const selectAllMetrics = (state) =>
  fromMasterData.selectAllMetrics(state.masterData);
export const selectAllDiscoveryTypes = (state) =>
  fromMasterData.selectAllDiscoveryTypes(state.masterData);
export const isSolutionsEnabled = (state) =>
  fromMasterData.isSolutionsEnabled(state.masterData);

export const selectMasterDataMap = (state, mapName) =>
  fromMasterData.selectMasterDataMap(state.masterData, mapName);

export const selectUsersLoadingState = (state) =>
  fromUsers.selectUsersLoadingState(state.usersData);
export const selectAllUsers = (state) =>
  fromUsers.selectAllUsers(state.usersData);
export const selectUsersOperationId = (state) =>
  fromUsers.selectUsersOperationId(state.usersData);
export const selectUsersOperationUserId = (state) =>
  fromUsers.selectUsersOperationUserId(state.usersData);
export const selectUsersOperationRoleId = (state) =>
  fromUsers.selectUsersOperationRoleId(state.usersData);

export const selectUsersPagination = (state) =>
  fromUsers.selectUsersPagination(state.usersData);
export const selectUsersDiscoveryOwners = (state) =>
  fromUsers.selectUsersDiscoveryOwners(state.usersData);
export const selectCommentCountLoadingState = (state) =>
  fromComments.selectCommentCountLoadingState(state.commentsData);
export const selectCommentCountError = (state) =>
  fromComments.selectCommentCountError(state.commentsData);
export const selectCommentCountForTopic = (state, topic) =>
  fromComments.selectCommentCountForTopic(state.commentsData, topic);
export const selectCommentsLoadingState = (state) =>
  fromComments.selectCommentsLoadingState(state.commentsData);
export const selectCommentIds = (state) =>
  fromComments.selectCommentIds(state.commentsData);
export const selectCommentTopic = (state) =>
  fromComments.selectCommentTopic(state.commentsData);
export const selectPostCommentLoadingState = (state) =>
  fromComments.selectPostCommentLoadingState(state.commentsData);
export const selectDraftCommentForTopic = (state, topic) =>
  fromComments.selectDraftCommentForTopic(state.commentsData, topic);
export const selectDeleteCommentLoadingState = (state) =>
  fromComments.selectDeleteCommentLoadingState(state.commentsData);
export const isEditingComment = (state, commentId) =>
  fromComments.isEditingCommentId(state.commentsData, commentId);
export const isEditingAnyComment = (state) =>
  fromComments.isEditingAnyComment(state.commentsData);
export const selectComment = (state, commentId) =>
  fromComments.selectComment(state.commentsData, commentId);
export const selectValueNote = (state) =>
  fromComments.selectValueNote(state.commentsData);
export const selectValueNoteLoadingState = (state) =>
  fromComments.selectValueNoteLoadingState(state.commentsData);
export const isDeletingComment = (state) =>
  fromComments.isDeletingComment(state.commentsData);
export const selectEditKPICode = (state) =>
  fromEditKPIs.selectEditKPICode(state.editKPIsData);
export const selectDeleteKPICode = (state) =>
  fromDeleteKPIs.selectDeleteKPICode(state.deleteKPIsData);
export const selectIsCreatingKPI = (state) =>
  fromCreateKPIs.selectIsCreatingKPI(state.createKPIsData);

export const selectEditChallengeCode = (state) =>
  fromEditChallenges.selectEditChallengeCode(state.editChallengesData);
export const selectIsCreatingChallenge = (state) =>
  fromCreateChallenges.selectIsCreatingChallenge(state.createChallengesData);
export const selectDeleteChallengeCode = (state) =>
  fromDeleteChallenges.selectDeleteChallengeCode(state.deleteChallengesData);

export const selectNotificationCount = (state) =>
  fromNotifications.selectNotificationCount(state.notificationsData);
export const selectNotificationCountLoadingState = (state) =>
  fromNotifications.selectNotificationCountLoadingState(
    state.notificationsData
  );
export const selectNotificationIds = (state) =>
  fromNotifications.selectNotificationIds(state.notificationsData);
export const selectNotification = (state, notificationId) =>
  fromNotifications.selectNotification(state.notificationsData, notificationId);
export const selectNotificationsLoadingState = (state) =>
  fromNotifications.selectNotificationsLoadingState(state.notificationsData);
export const selectNotificationsReadLoadingState = (state) =>
  fromNotifications.selectNotificationsReadLoadingState(
    state.notificationsData
  );

export const selectPreviewSolutionsMap = (state) =>
  fromPreviewSolutions.selectPreviewSolutionsMap(state.previewSolutionsData);
export const selectPreviewSolutionsLoadingState = (state) =>
  fromPreviewSolutions.selectPreviewSolutionsLoadingState(
    state.previewSolutionsData
  );

export const selectRequestState = (state, actionType) =>
  fromRequests.selectRequestState(state.requestsData, actionType);
export const selectRequestData = (state, actionType) =>
  fromRequests.selectRequestData(state.requestsData, actionType);
export const selectRequestParameters = (state, actionType) =>
  fromRequests.selectRequestParameters(state.requestsData, actionType);
export const selectRequestError = (state, actionType) =>
  fromRequests.selectRequestError(state.requestsData, actionType);

export const selectCookie = (state, name) =>
  fromCookies.selectCookie(state.cookiesData, name);

export const selectRoles = (state) => fromRoles.selectRoles(state.rolesData);
export const selectRole = (state) => fromRoles.selectRole(state.rolesData);
export const selectRolesPagination = (state) =>
  fromRoles.selectRolesPagination(state.rolesData);

export const selectJob = (state, jobId) =>
  fromJobs.selectJob(state.jobsData, jobId);
export const selectJobLoadingState = (state, jobId) =>
  fromJobs.selectJobLoadingState(state.jobsData, jobId);
export const selectKPILibraryOpen = (state) =>
  fromKPILibrary.selectKPILibraryOpen(state.kpiLibraryData);
export const selectScrollToKPICode = (state) =>
  fromKPILibrary.selectScrollToKPICode(state.kpiLibraryData);
export const selectChallengeLibraryOpen = (state) =>
  fromChallengeLibrary.selectChallengeLibraryOpen(state.challengeLibraryData);
export const selectScrollToChallengeCode = (state) =>
  fromChallengeLibrary.selectScrollToChallengeCode(state.challengeLibraryData);

export const selectAllFiles = (state) =>
  fromFileList.selectAllFiles(state.fileListData);
export const selectFileState = (state, fileUID) =>
  fromFileList.selectFileState(state.fileListData, fileUID);
export const selectFileFileId = (state, fileUID) =>
  fromFileList.selectFileFileId(state.fileListData, fileUID);
export const selectFileUploadPercentage = (state, fileUID) =>
  fromFileList.selectFileUploadPercentage(state.fileListData, fileUID);
export const selectFileValidationId = (state, fileUID) =>
  fromFileList.selectFileValidationId(state.fileListData, fileUID);

export const selectFileAdditionalData = (state, fileUID) =>
  fromFileList.selectFileAdditionalData(state.fileListData, fileUID);

export const isFileListErrorFree = (state) =>
  fromFileList.isFileListErrorFree(state.fileListData);
export const selectFileListValidationIds = (state) =>
  fromFileList.selectFileListValidationIds(state.fileListData);
export const selectFileValidationErrors = (state, fileUID) =>
  fromFileList.selectFileValidationErrors(state.fileListData, fileUID);

export const selectCurrentConfigurationModal = (state) =>
  fromUpdateConfigurationModals.selectCurrentConfigurationModal(
    state.updateConfigurationModalsData
  );

export const isFullscreen = (state) =>
  fromFullscreen.isFullscreen(state.fullscreenData);

export const selectOpenNotificationData = (state) =>
  fromNotificationMessage.selectOpenNotificationData(
    state.notificationMessageData
  );
export const selectCloseNotificationKey = (state) =>
  fromNotificationMessage.selectCloseNotificationKey(
    state.notificationMessageData
  );

export const selectHasManageDiscoveryAccessPermission = (state) => {
  const editorUserIds = selectDiscoveryEditors(state);

  return (
    selectDiscoveryUsername(state) === selectUser(state).email ||
    editorUserIds.includes(selectUserId(state)) ||
    hasPermission(state, Permissions.SEE_ALL_DISCOVERIES)
  );
};

export const selectGuest = (state) => fromGuests.selectGuest(state.guestsData);

export const selectIntegration = (state) =>
  fromIntegrations.selectIntegration(state.integrationsData);
