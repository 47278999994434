import httpService from "./http.service";

const getFeatures = () =>
  httpService
    .get(`/features`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  getFeatures
};
export default methods;
