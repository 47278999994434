import loginStateService from "../services/login-state.service";
import errorsService from "../services/errors.service";

const redirectToOAuthLogin = ({
  authConfiguration,
  identityProvider,
  stateData
}) => {
  const url = new URL(
    `https://${authConfiguration.oauth.domain}/oauth2/authorize`
  );

  const state = loginStateService.saveState(stateData);

  url.searchParams.append("client_id", authConfiguration.userPoolWebClientId);
  url.searchParams.append(
    "response_type",
    authConfiguration.oauth.responseType
  );
  url.searchParams.append("scope", "openid");
  url.searchParams.append(
    "redirect_uri",
    authConfiguration.oauth.redirectSignIn
  );
  url.searchParams.append("state", state);

  if (identityProvider) {
    url.searchParams.append("identity_provider", identityProvider);
  }

  errorsService
    .logInfo({
      category: "redirect-to-oauth-login",
      data: `redirecting to ${url.href}`
    })
    .then(() => {});

  window.location.href = url.href;
};

export default redirectToOAuthLogin;
