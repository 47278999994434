import Menu, { MenuItem } from "../../../../components/Menu";
import Icon from "../../../../components/Icon";
import Dropdown from "../../../../components/Dropdown";
import NewButton from "../../../../components/NewButton";
import { useString as s } from "../../../../components/StringProvider";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  selectRequestData,
  selectRequestState
} from "../../../../store/reducers";
import React from "react";
import openDownload from "../../../../utils/open-download";
import { downloadGuide } from "../../../../store/actions/configurations";
import actionTypes from "../../../../store/actionTypes";
import useLoadingState from "../../../../utils/use-loading-state";
import { message } from "antd";

const GuidesDropdown = ({
  downloadGuide,
  downloadGuideLoadingState,
  download
}) => {
  const guidesText = s(
    "organization.page.manageConfiguration.guides",
    "Guides"
  );
  const powerpointTemplateGuideText = s(
    "organization.page.manageConfiguration.guides.powerpointTemplateGuide",
    "PPT Tags List"
  );

  const downloadError = s(
    "organization.page.manageConfiguration.guides.downloadError",
    "Error downloading guide"
  );

  const onClick = ({ guideName }) => {
    if (guideName) {
      downloadGuide({ guideName });
    }
  };

  useLoadingState(
    downloadGuideLoadingState,
    () => {
      console.log("download", download);
      openDownload(download.url);
    },
    () => {
      message.error(downloadError);
    }
  );

  const renderMenu = () => (
    <StyledMenu onClick={onClick}>
      <MenuItem
        key={"powerpointTemplateGuide"}
        icon={<Icon name={"download"} />}
        onClick={() => onClick({ guideName: "powerpointTemplateGuide" })}
      >
        {powerpointTemplateGuideText}
      </MenuItem>
    </StyledMenu>
  );

  return (
    <Dropdown
      overlay={renderMenu()}
      placement={"bottomRight"}
      trigger={["click"]}
    >
      <NewButton type={"secondaryDropdown"} data-cy={"guides-dropdown"}>
        {guidesText}
        <Icon name={"angleDown"} />
      </NewButton>
    </Dropdown>
  );
};

const StyledMenu = styled(Menu)`
  min-width: 160px;
  max-width: 160px;
`;

const mapStateToProps = (state) => ({
  download: selectRequestData(state, actionTypes.DOWNLOAD_GUIDE_REQUEST),
  downloadGuideLoadingState: selectRequestState(
    state,
    actionTypes.DOWNLOAD_GUIDE_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      downloadGuide
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  GuidesDropdown
);
