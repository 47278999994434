import { selectJob } from "../store/reducers";
import { bindActionCreators, compose } from "redux";
import { getJob } from "../store/actions/jobs";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import httpService from "../services/http.service";
import { useEffect } from "react";
import pusherService from "../services/pusher.service";
import ChannelNames from "../utils/channel-names";
import JobStatuses from "../utils/job-statuses";
import useInterval from "../utils/use-interval";

const INTERVAL_MS = 15 * 1000;
const JobMonitor = ({ jobId, getJob, job, onChange, onFinish }) => {
  const tenantId = httpService.getTenantId();

  useEffect(() => {
    pusherService.subscribe({
      channelName: ChannelNames.JOBS({ jobId, tenantId }),
      eventName: "update",
      callback: onJobUpdate
    });

    getJob({ jobId });

    return () => {
      pusherService.unsubscribe({
        channelName: ChannelNames.JOBS({ jobId, tenantId }),
        eventName: "update",
        callback: onJobUpdate
      });
    };
  }, []);

  useInterval(
    () => {
      console.log("Manual fallback getJob", job?._id, job?.status);
      onJobUpdate();
    },
    job?.status !== JobStatuses.COMPLETED && job?.status !== JobStatuses.FAILED
      ? INTERVAL_MS
      : null
  );

  useEffect(() => {
    if (job) {
      switch (job.status) {
        case JobStatuses.COMPLETED:
          onFinish(true);
          onChange(job);
          break;

        case JobStatuses.FAILED:
          onFinish(false);
          onChange(job);
          break;

        default:
          onChange(job);
          break;
      }
    }
  }, [job]);

  const onJobUpdate = () => {
    getJob({ jobId });
  };

  return null;
};

JobMonitor.propTypes = {
  jobId: PropTypes.string.isRequired,
  onFinish: PropTypes.func,
  onChange: PropTypes.func
};

JobMonitor.defaultProps = {
  onFinish: () => {},
  onChange: () => {}
};

const mapStateToProps = (state, props) => ({
  job: selectJob(state, props.jobId)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getJob
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  JobMonitor
);
