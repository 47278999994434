import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Badge from "../../components/Discovery/Badge";
import Icon from "../../components/Icon";
import { themeProp } from "../../theme";
import { useString as s } from "../../components/StringProvider";
import Text from "../Text";

const ConnectionLayout = ({ header, isConnected, children }) => {
  const connected = s("connectionLayout.connected.label", "Connected");
  const notConnected = s(
    "connectionLayout.not.connected.label",
    "Not Connected"
  );
  const status = s("connectionLayout.status.label", "STATUS");

  const connectionStatus = (
    <StatusContainer>
      <div>
        <Text color={"gray4"} variant={"h5"}>
          {status}
        </Text>
        <StyledBadge
          color={"surface"}
          bg={
            isConnected
              ? themeProp(`palette.success`)
              : themeProp(`palette.gray4`)
          }
          variant={"bodyMobile"}
          data-cy={isConnected ? "connected-badge" : "disconnected-badge"}
        >
          <div>
            <Icon name={isConnected ? "check" : "alert"} size={11} />
          </div>
          {isConnected ? connected : notConnected}
        </StyledBadge>
      </div>
    </StatusContainer>
  );

  return (
    <Container>
      <TitleBar>
        {connectionStatus}
        {header}
      </TitleBar>
      {children}
    </Container>
  );
};

const Container = styled.div`
  max-width: 550px;
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

const TitleBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 32px;
  margin-bottom: 24px;
`;

const StatusContainer = styled.div`
  display: inline-flex;
  margin: 8px 0 24px 0;

  & > * {
    padding: 20px 24px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid ${themeProp("palette.gray2")};
    gap: 24px;
  }
`;

const StyledBadge = styled(Badge)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 2px 8px !important;

  svg {
    transform: translateY(-0.5px);
  }
`;

ConnectionLayout.propTypes = {
  header: PropTypes.string.isRequired,
  isConnected: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default ConnectionLayout;
