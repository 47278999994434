import {
  isDeletingComment,
  selectCommentCountForTopic
} from "../../../store/reducers";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropType from "prop-types";
import Icon from "../../Icon";
import { useRef, useState } from "react";
import Text from "../../Text";
import { useString as s } from "../../StringProvider";
import NewButton from "../../NewButton";
import Popover from "../../Popover";
import CommentsPanel from "./CommentsPanel";
import { useMobileMediaQuery } from "../../Responsive";

const CommentsButton = ({
  topic,
  commentCount,
  reportId,
  mobilePlacement,
  isDeletingComment,
  commentPanelMaxHeight,
  eventName
}) => {
  const isMobile = useMobileMediaQuery();
  const buttonRef = useRef();
  const tooltipText = s("comments.button", "Add comment");
  const [popoverVisible, setPopoverVisible] = useState(false);

  const classNames = ["open-comments-button"];

  if (popoverVisible) {
    classNames.push("open-comments-button-open");
  }

  if (commentCount) {
    classNames.push("open-comments-button-has-comments");
  }

  const onOpenChange = (visible) => {
    if (visible) {
      setPopoverVisible(true);
    } else {
      setPopoverVisible(!!isDeletingComment);
    }
  };

  return (
    <Popover
      placement={isMobile ? mobilePlacement : "bottomLeft"}
      trigger={"click"}
      content={
        <CommentsPanel
          topic={topic}
          reportId={reportId}
          onClose={() => setPopoverVisible(false)}
          commentPanelMaxHeight={commentPanelMaxHeight}
          eventName={eventName}
        />
      }
      destroyTooltipOnHide={true}
      open={popoverVisible}
      onOpenChange={onOpenChange}
      overlayInnerStyle={{
        padding: 0
      }}
    >
      <NewButton
        type={popoverVisible ? "iconPrimary" : "iconSecondary"}
        ref={buttonRef}
        className={classNames}
        tooltip={tooltipText}
      >
        <Icon name={"comment"} />
        {commentCount ? (
          <Text variant={"small"} color={"gray3"} data-cy={"comment-count"}>
            &nbsp;{commentCount}
          </Text>
        ) : null}
      </NewButton>
    </Popover>
  );
};

CommentsButton.propTypes = {
  topic: PropType.string.isRequired,
  reportId: PropType.string,
  mobilePlacement: PropType.oneOf(["bottom", "bottomRight"]),
  commentPanelMaxHeight: PropType.number,
  eventName: PropType.string.isRequired
};

CommentsButton.defaultProps = {
  mobilePlacement: "bottomRight",
  commentPanelMaxHeight: 400
};

const mapStateToProps = (state, props) => {
  return {
    commentCount: selectCommentCountForTopic(state, props.topic),
    isDeletingComment: isDeletingComment(state)
  };
};

export default compose(withRouter, connect(mapStateToProps))(CommentsButton);
