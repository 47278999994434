import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Text } from "../../components";
import { useString as s } from "../../components/StringProvider";
import Link from "../../components/Link";
import { EmailInput, PasswordInput } from "../../components/Input";
import Heading from "../../components/Heading";
import Form, { FormItem } from "../../components/Form";
import NewButton from "../../components/NewButton";
import ButtonGroup from "../../components/ButtonGroup";
import LoginFormContainer from "./LoginFormContainer";
import ContinueWithSSO from "./ContinueWithSSO";
import { validate } from "email-validator";
import SSOError from "./SSOError";

const LoginForm = ({ error, ssoError, onFinish, invitation }) => {
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);
  const [authError, setAuthError] = useState("");
  const [form] = Form.useForm();
  const emailValidation = s(
    "login.page.email.validation",
    "Please input your email"
  );
  const emailPlaceholder = s("login.page.email.placeholder", "Your email");
  const passwordValidation = s(
    "login.page.password.validation",
    "Please input your password"
  );
  const passwordPlaceholder = s("login.page.password.placeholder", "Password");

  useEffect(() => {
    setAuthError(error);

    if (error) {
      setSubmitDisabled(true);
    }
  }, [error]);

  const onFieldsChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setSubmitDisabled(!!hasErrors || !form.isFieldsTouched(true));
    setAuthError("");
  };

  return (
    <LoginFormContainer>
      <Heading level={"h2"}>
        {s("login.page.header", "Log in to continue")}
      </Heading>
      <ContinueWithSSO />
      <Form
        name="basic"
        form={form}
        onFinish={onFinish}
        className={"login"}
        layout={"vertical"}
        onFieldsChange={onFieldsChange}
      >
        <FormItem
          name="email"
          rules={[
            () => ({
              validator(_, value) {
                if (!validate(value)) {
                  return Promise.reject(new Error(emailValidation));
                }

                return Promise.resolve();
              }
            })
          ]}
        >
          <EmailInput placeholder={emailPlaceholder} />
        </FormItem>

        <FormItem
          name="password"
          rules={[{ required: true, message: passwordValidation }]}
          validateStatus={authError ? "error" : undefined}
          help={authError || undefined}
          className="password"
        >
          <PasswordInput placeholder={passwordPlaceholder} />
        </FormItem>
        <ForgotPasswordContainer>
          <Text>
            {s("login.page.textfield1", "Forgot your password?")}{" "}
            <Link
              to={
                invitation?.code
                  ? `/invitations/${invitation?.code}/forgot-password`
                  : "/forgot-password"
              }
              data-cy={"forgot-password-link"}
            >
              {s("login.page.textfield1.link", "Click to reset it")}
            </Link>
          </Text>
        </ForgotPasswordContainer>
        <ButtonGroup mobile={true}>
          <NewButton
            type={"submit"}
            data-cy={"login-button"}
            disabled={isSubmitDisabled}
          >
            {s("login.page.button", "Let's start")}
          </NewButton>
        </ButtonGroup>
      </Form>
      {!invitation && (
        <SignUpContainer>
          {s(
            "login.page.subheader",
            "New to the {companyName} Outcomes Discovery app?"
          )}
          <Link
            to="/sign-up"
            data-cy={"sign-up-link"}
            style={{ marginLeft: 8 }}
          >
            Sign up
          </Link>
        </SignUpContainer>
      )}
      {!!ssoError && <SSOError errorDescription={ssoError.errorDescription} />}
    </LoginFormContainer>
  );
};

const ForgotPasswordContainer = styled.div`
  margin-top: 16px;
`;

const SignUpContainer = styled.div``;

LoginForm.propTypes = {
  invitation: PropTypes.object
};

export default LoginForm;
