import "react-phone-number-input/style.css";
import OriginalPhoneInput from "react-phone-number-input";
import styled from "styled-components";
import { themeProp } from "../../theme";
import flags from "react-phone-number-input/flags";
import SelectCountryCallingCode from "../SelectCountryCallingCode";

const PhoneInput = styled(OriginalPhoneInput).attrs(() => ({
  flags,
  countrySelectComponent: SelectCountryCallingCode
}))`
  &.PhoneInput {
    border-bottom: 1px solid ${themeProp("palette.secondary")};

    &:hover {
      border-bottom: 1px solid ${themeProp("palette.secondary")};
    }

    &:focus {
      border-bottom: 1px solid ${themeProp("palette.secondary")};
      background-color: ${themeProp("palette.surface")};
    }
  }

  & .PhoneInputInput {
    width: ${(props) => (props.block ? "100%" : "initial")};
    background: transparent;
    color: ${themeProp("palette.text")};
    border: none;
    font-size: ${themeProp("typography.body.fontSize")};
    transition: border-color 0.2s ease-out;
    border-radius: 0;

    &.mobile {
      height: 40px;
    }
  }

  .ant-select-selector .ant-select-selection-item,
  .ant-select-selector .ant-select-selection-placeholder {
    line-height: normal;
  }

  .ant-select-selector .ant-select-selection-item .PhoneInputCountryIcon {
    margin: 5px 5px 0 0;
  }
`;

export default PhoneInput;
