import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { message } from "antd";
import { Modal, Text, Tooltip, NewButton, Form, FormItem } from "..";
import { useString as s } from "../StringProvider";
import { useSetting } from "../SettingsProvider";
import Input from "../Input";
import Select from "../Select";
import ButtonGroup from "../ButtonGroup";
import { updateUser } from "../../store/actions/users";
import {
  selectRequestError,
  selectRequestState,
  hasPermission,
  selectDiscoveryLoadingState,
  selectUser
} from "../../store/reducers";
import actionTypes from "../../store/actionTypes";
import useLoadingState from "../../utils/use-loading-state";
import Permissions from "../../utils/permissions";
import hasMissingProperties from "../../utils/has-missing-properties";
import { useLocation } from "react-router-dom";
import { useFeature } from "../FeaturesProvider";

const AboutUserModal = ({
  userInfo,
  updateUser,
  loadingState,
  error,
  isInternalUser,
  discoveryLoadingState
}) => {
  const countries = useSetting("users.countries");
  const salesforceUsersSync = useFeature("salesforceUsersSync", false);

  const title = s("aboutUserModal.title", "Tell us a bit about yourself");
  const subTitle = s(
    "aboutUserModal.subTitle",
    "This helps us personalize your experience"
  );
  const saveButton = s("aboutUserModal.save.button", "Get Started");
  const saveButtonTooltip = s(
    "aboutUserModal.save.button.tooltip",
    "Please fill out all fields to continue"
  );
  const firstNameText = s("aboutUserModal.first.name", "First Name");
  const firstNamePlaceholder = s(
    "aboutUserModal.first.name.placeholder",
    "Enter first name"
  );
  const firstNameValidation = s(
    "aboutUserModal.first.name.validation",
    "Please add a first name"
  );
  const lastNameText = s("aboutUserModal.last.name", "Last Name");
  const lastNamePlaceholder = s(
    "aboutUserModal.last.name.placeholder",
    "Enter last name"
  );
  const lastNameValidation = s(
    "aboutUserModal.first.name.validation",
    "Please add a last name"
  );
  const jobTitleText = s("aboutUserModal.userForm.job.title", "Job Title");
  const jobTitlePlaceholder = s(
    "aboutUserModal.userForm.job.title.placeholder",
    "Enter job title"
  );
  const jobTitleValidation = s(
    "aboutUserModal.job.title.validation",
    "Please add a job title"
  );
  const countryText = s("aboutUserModal.country", "Country / Region");
  const countryPlaceholder = s(
    "aboutUserModal.country.placeholder",
    "Choose country / region"
  );
  const countryValidation = s(
    "aboutUserModal.country.validation",
    "Please add a country / region"
  );
  const errorMessage = s(
    "aboutUserModal.update.error",
    "Profile details failed to update"
  );
  const successMessage = s(
    "aboutUserModal.update.success",
    "Profile details were updated"
  );

  const [showModal, setShowModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [discoveryError, setDiscoveryError] = useState(false);

  const [form] = Form.useForm();
  const location = useLocation();
  const isDiscoveriesRoute = location.pathname.includes("/discoveries/");

  const mandatoryFields = ["firstName", "lastName", "jobTitle"];

  if (!!countries?.length) {
    mandatoryFields.push("country");
  }

  const formItems = [
    {
      name: "firstName",
      label: firstNameText,
      placeholder: firstNamePlaceholder,
      validation: firstNameValidation
    },
    {
      name: "lastName",
      label: lastNameText,
      placeholder: lastNamePlaceholder,
      validation: lastNameValidation
    },
    {
      name: "jobTitle",
      label: jobTitleText,
      placeholder: jobTitlePlaceholder,
      validation: jobTitleValidation
    }
  ];

  const countriesOptions = countries?.map((country) => {
    return { key: country, value: country, label: country };
  });

  useLoadingState(
    loadingState,
    () => {
      if (showModal) {
        message.success(successMessage);
      }
    },
    () => {
      if (error && showModal) {
        message.error(errorMessage);
      }
    }
  );

  const handleDiscoveryLoadingStateChange = (isError) => {
    if (isDiscoveriesRoute) {
      setDiscoveryError(isError);
    }
  };

  useLoadingState(
    discoveryLoadingState,
    () => handleDiscoveryLoadingStateChange(false),
    () => handleDiscoveryLoadingStateChange(true),
    () => handleDiscoveryLoadingStateChange(true)
  );

  useEffect(() => {
    if (
      !salesforceUsersSync &&
      !discoveryError &&
      isInternalUser &&
      !userInfo?.isAboutUserFormFilled &&
      hasMissingProperties(userInfo, mandatoryFields)
    ) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [userInfo, discoveryError]);

  useEffect(() => {
    if (userInfo) {
      const { firstName, lastName, jobTitle, country } = userInfo;

      form.setFieldsValue({
        firstName,
        lastName,
        jobTitle,
        country: !country ? undefined : country
      });
    }
  }, [userInfo]);

  const onValuesChange = () => {
    const someErrors = form
      .getFieldsError()
      .some(({ errors }) => errors.length);

    const isFieldsFilled = mandatoryFields.every((field) =>
      form.getFieldValue(field)
    );

    setDisabled(!isFieldsFilled || someErrors);
  };

  const onFinish = async (values) => {
    const userId = userInfo?.userId;
    const { firstName, lastName, jobTitle, country } = values;

    if (userId) {
      updateUser({
        userId,
        firstName,
        lastName,
        jobTitle,
        country
      });
    }
  };

  return showModal ? (
    <Modal
      open={true}
      title={title}
      showCloseIcon={false}
      data-cy={"about-user-modal"}
    >
      <Text variant={"body"}>{subTitle}</Text>
      <StyledForm
        form={form}
        layout={"vertical"}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        name={"aboutUser"}
      >
        {formItems.map((item) => (
          <FormItem
            key={item.name}
            label={item.label}
            name={item.name}
            rules={[
              {
                required: true,
                message: item.validation,
                whitespace: true
              }
            ]}
          >
            <Input placeholder={item.placeholder} />
          </FormItem>
        ))}
        {!!countries?.length && (
          <FormItem
            label={countryText}
            name={"country"}
            rules={[
              {
                required: true,
                message: countryValidation,
                whitespace: true
              }
            ]}
          >
            <Select
              options={countriesOptions}
              placeholder={countryPlaceholder}
            />
          </FormItem>
        )}
        <FormItem>
          <ButtonGroup>
            <Tooltip title={disabled ? saveButtonTooltip : ""}>
              <NewButton
                type={"submit"}
                data-cy={"save-button"}
                disabled={disabled}
              >
                {saveButton}
              </NewButton>
            </Tooltip>
          </ButtonGroup>
        </FormItem>
      </StyledForm>
    </Modal>
  ) : null;
};

const StyledForm = styled(Form)`
  margin-top: 24px;
`;

const mapStateToProps = (state) => ({
  loadingState: selectRequestState(state, actionTypes.UPDATE_USER_REQUEST),
  error: selectRequestError(state, actionTypes.UPDATE_USER_REQUEST),
  isInternalUser: hasPermission(state, Permissions.SEE_MY_DISCOVERIES),
  userInfo: selectUser(state),
  discoveryLoadingState: selectDiscoveryLoadingState(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUser
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AboutUserModal
);
