import React from "react";
import styled from "styled-components";
import Heading from "../../../components/Heading";
import { useString as s } from "../../../components/StringProvider";
import { themeProp } from "../../../theme";
import { selectDiscoveryName } from "../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { useTheme } from "../../../components/ThemeProvider";
import MobileReportSection from "./MobileReportSection";
import MobileReportTitle from "./MobileReportTitle";
import { useMobileMediaQuery } from "../../../components/Responsive";

const ReportIntroPartial = ({ discoveryName }) => {
  const isMobile = useMobileMediaQuery();
  const header = s("report.page.header", "Opportunity for {discoveryName}", {
    discoveryName
  });
  const subheader = s("report.page.subheader2");

  return isMobile ? (
    <MobileReportSection
      id={"report-intro"}
      data-cy={"report-header-partial"}
      className={"fullpage"}
    >
      <ReportHeaderImage
        src={useTheme("assets.report_header_cover")}
        className={"mobile"}
      />
      <MobileReportTitle
        header={header}
        subheader={subheader}
        style={{ marginTop: "30px", paddingLeft: "20px", paddingRight: "20px" }}
      />
    </MobileReportSection>
  ) : (
    <ReportHeaderContainer data-cy={"report-header-partial"}>
      <ReportHeaderContent>
        <ReportHeaderContentWrapper>
          <Heading level="h1">{header}</Heading>
          <Heading level={"h4"}>{subheader}</Heading>
        </ReportHeaderContentWrapper>
      </ReportHeaderContent>
      <ReportHeaderImage src={useTheme("assets.report_header_cover")} />
    </ReportHeaderContainer>
  );
};

const ReportHeaderContainer = styled.div`
  background-color: ${themeProp("palette.background")};
  display: flex;
`;
const ReportHeaderImage = styled.div`
  height: 480px;
  min-width: 450px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.mobile {
    min-width: unset;
    height: unset;
    flex: 1;
    background-size: contain;
  }
`;
const ReportHeaderContent = styled.div`
  padding: 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;
const ReportHeaderContentWrapper = styled.div`
  width: 480px;
`;

const mapStateToProps = (state) => ({
  discoveryName: selectDiscoveryName(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ReportIntroPartial
);
