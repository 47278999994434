import CloneDiscoveryModal from "./CloneDiscoveryModal";
import EditDiscoveryModal from "./EditDiscoveryModal";
import ExportPowerpointModal from "./ExportPowerpointModal";
import React, { useEffect } from "react";
import ShareDiscoveryModal from "./ShareDiscoveryModal";
import {
  selectDiscovery,
  selectDiscoveryOperationDiscoveryId,
  selectDiscoveryOperationId,
  selectDiscoveryOperationParameters,
  selectDiscoveryOperationReportId,
  selectDiscoveryROI,
  selectMasterDataVersion,
  selectRequestData
} from "../../store/reducers";
import { bindActionCreators, compose } from "redux";
import {
  fetchDiscovery,
  getDiscoverySummary
} from "../../store/actions/discoveries";
import { connect } from "react-redux";
import { stopDiscoveryOperation } from "../../store/actions/discovery-operations";
import { getMasterData } from "../../store/actions/master-data";
import PrintReportModal from "./PrintReportModal";
import DeleteDiscoveryModal from "./DeleteDiscoveryModal";
import AssignDiscoveryModal from "./AssignDiscoveryModal";
import EmailDiscoveryModal from "./EmailDiscoveryModal/EmailDiscoveryModal";
import actionTypes from "../../store/actionTypes";
import { getReportSummary } from "../../store/actions/reports";
import SolutionsModal from "../Discovery/SolutionsModal";
import { useLocation } from "react-router-dom";
import restrictROITemplate from "../../utils/restrict-roi-template";
import DiscoveryEngagementModal from "./DiscoveryEngagementModal/DiscoveryEngagementModal";

export const Operations = {
  CLONE: "clone",
  EDIT: "edit",
  EXPORT: "export",
  SHARE: "share",
  EMAIL: "email",
  PRINT: "print",
  DELETE: "delete",
  ASSIGN: "assign",
  SOLUTIONS: "solutions",
  EXPORT_DISCOVERY_EXCEL: "exportDiscoveryExcel",
  HELP: "help",
  CLOSE: "close",
  ENGAGEMENT: "engagement"
};

const DiscoveryOperations = ({
  operationId,
  discoverySummary,
  operationDiscoveryId,
  operationReportId,
  getReportSummary,
  masterDataVersion,
  discovery,
  fetchDiscovery,
  parameters,
  roi,
  getDiscoverySummary,
  getMasterData,
  stopDiscoveryOperation
}) => {
  const location = useLocation();

  const isCanvasMode = location.pathname.includes("/valuemaps/");

  const operationRequiresFullDiscovery =
    operationId === Operations.SHARE || operationId === Operations.EMAIL;

  useEffect(() => {
    if (operationId && operationDiscoveryId) {
      getDiscoverySummary({ discoveryId: operationDiscoveryId });
    }
  }, [operationId, operationDiscoveryId]);

  useEffect(() => {
    if (
      operationId &&
      operationReportId &&
      (!discoverySummary || discoverySummary.reportId !== operationReportId)
    ) {
      getReportSummary({ reportId: operationReportId });
    }
  }, [operationId, operationReportId, discoverySummary]);

  useEffect(() => {
    if (
      operationId &&
      operationDiscoveryId &&
      discoverySummary &&
      discoverySummary._id === operationDiscoveryId &&
      discoverySummary.masterDataVersion !== masterDataVersion
    ) {
      getMasterData({ version: discoverySummary.masterDataVersion });
    }
  }, [operationId, operationDiscoveryId, discoverySummary, masterDataVersion]);

  useEffect(() => {
    const shouldFetchDiscovery =
      operationRequiresFullDiscovery &&
      discoverySummary &&
      discoverySummary._id === operationDiscoveryId &&
      (!discovery || discovery._id !== operationDiscoveryId);

    if (shouldFetchDiscovery) {
      fetchDiscovery(operationDiscoveryId);
    }
  }, [operationId, operationDiscoveryId, discoverySummary, discovery]);

  if (
    operationDiscoveryId &&
    (!discoverySummary ||
      discoverySummary._id !== operationDiscoveryId ||
      discoverySummary.masterDataVersion !== masterDataVersion)
  ) {
    return null;
  }

  if (
    operationReportId &&
    (!discoverySummary || discoverySummary.reportId !== operationReportId)
  ) {
    return null;
  }

  if (
    operationRequiresFullDiscovery &&
    (!discovery || discovery._id !== operationDiscoveryId)
  ) {
    return null;
  }

  switch (operationId) {
    case Operations.CLONE:
      return (
        <CloneDiscoveryModal
          discoveryId={discoverySummary._id}
          accountId={discoverySummary.accountId}
          accountName={discoverySummary.accountName}
          accountSource={discoverySummary.accountSource}
          opportunityId={discoverySummary.opportunityId}
          opportunityName={discoverySummary.opportunityName}
          opportunitySource={discoverySummary.opportunitySource}
          currency={discoverySummary.currency}
          discoveryTypeCode={discoverySummary.discoveryTypeCode}
          onClose={stopDiscoveryOperation}
        />
      );

    case Operations.EDIT:
      return (
        <EditDiscoveryModal
          discoveryId={discoverySummary._id}
          accountId={discoverySummary.accountId}
          accountName={discoverySummary.accountName}
          accountSource={discoverySummary.accountSource}
          opportunityId={discoverySummary.opportunityId}
          opportunityName={discoverySummary.opportunityName}
          opportunitySource={discoverySummary.opportunitySource}
          version={discoverySummary.version}
          currency={discoverySummary.currency}
          discoveryTypeCode={discoverySummary.discoveryTypeCode}
          onClose={stopDiscoveryOperation}
        />
      );

    case Operations.EXPORT:
      return (
        <ExportPowerpointModal
          discoveryId={discoverySummary._id}
          reportId={discoverySummary.reportId}
          onClose={stopDiscoveryOperation}
        />
      );

    case Operations.SHARE:
      return (
        <ShareDiscoveryModal
          template={restrictROITemplate({
            templateToRestrict: parameters.template || discoverySummary.phase,
            roi
          })}
          onClose={stopDiscoveryOperation}
        />
      );

    case Operations.PRINT:
      return (
        <PrintReportModal
          reportId={discoverySummary.reportId}
          template={restrictROITemplate({
            templateToRestrict: parameters.template || discoverySummary.phase,
            roi
          })}
          phase={restrictROITemplate({
            templateToRestrict: discoverySummary.phase,
            roi
          })}
          onClose={stopDiscoveryOperation}
        />
      );

    case Operations.ASSIGN:
      return (
        <AssignDiscoveryModal
          discoveryId={discoverySummary._id}
          currentUserId={discoverySummary.cognitoUserGuid}
          onClose={stopDiscoveryOperation}
        />
      );

    case Operations.DELETE:
      return (
        <DeleteDiscoveryModal
          discoveryId={discoverySummary._id}
          onClose={stopDiscoveryOperation}
        />
      );

    case Operations.SOLUTIONS:
      return (
        <SolutionsModal
          editable={!isCanvasMode}
          onClose={stopDiscoveryOperation}
        />
      );

    case Operations.EMAIL:
      return <EmailDiscoveryModal onClose={stopDiscoveryOperation} />;

    case Operations.ENGAGEMENT:
      return (
        <DiscoveryEngagementModal
          onClose={stopDiscoveryOperation}
          discoveryId={discoverySummary._id}
          discoveryName={discoverySummary.name}
          externalEngagementCount={discoverySummary.externalEngagementCount}
          externalReportViewCount={discoverySummary.externalReportViewCount}
        />
      );

    default:
      return null;
  }
};

const mapStateToProps = (state) => ({
  discovery: selectDiscovery(state),
  operationId: selectDiscoveryOperationId(state),
  operationDiscoveryId: selectDiscoveryOperationDiscoveryId(state),
  operationReportId: selectDiscoveryOperationReportId(state),
  parameters: selectDiscoveryOperationParameters(state),
  roi: selectDiscoveryROI(state),
  masterDataVersion: selectMasterDataVersion(state),
  discoverySummary: selectRequestData(
    state,
    actionTypes.DISCOVERY_SUMMARY_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDiscovery,
      getDiscoverySummary,
      getMasterData,
      stopDiscoveryOperation,
      getReportSummary
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DiscoveryOperations
);
