import { Auth, Hub } from "aws-amplify";
import types from "./actionTypes";
import errorsService from "../services/errors.service";

export default class AmplifyBridge {
  constructor(store) {
    this.store = store;
    const callback = (event) => {
      this.onAuthEvent(event);
    };
    Hub.listen("auth", callback.bind(this), "AmplifyBridge");
    this.checkUser();
  }

  checkUser() {
    Auth.currentAuthenticatedUser()
      .then(() => {
        Auth.currentSession().then((data) => {
          this.store.dispatch({
            type: types.INITIAL_LOGIN_COMPLETE,
            payload: {
              token: data.getAccessToken().getJwtToken()
            }
          });
        });
      })
      .catch(() => {
        this.store.dispatch({
          type: types.INITIAL_LOGIN_COMPLETE
        });
      });
  }

  onAuthEvent(event) {
    console.log("AmplifyBridge", event.payload.event);
    errorsService.logInfo({
      category: "amplify-bridge",
      data: event.payload.event
    });
  }
}
