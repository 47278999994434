import React from "react";
import styled from "styled-components";
import { isEmpty } from "lodash";
import {
  hasPermission,
  selectDiscovery,
  selectDiscoveryId,
  selectQuestions,
  selectRequestData
} from "../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import { Space } from "antd";
import Icon from "../../components/Icon";
import { useHistory, useParams, withRouter } from "react-router-dom";
import { useString as s } from "../../components/StringProvider";
import Text from "../../components/Text";
import actionType from "../../store/actionTypes";
import { NewButton } from "../../components";
import Dropdown, { DropdownMenu } from "../../components/Dropdown";
import Permissions from "../../utils/permissions";
import { isMissingAnswerForCompulsorySelection } from "./components/all-mandatory-questions-answered-local";
import { themeProp } from "../../theme";
import { getQuestionNavDropdownMenuItems } from "./components/questions-nav-dropdown-util";
import QuestionButton from "./QuestionButton";
import Color from "color";

const QuestionsNavigator = ({
  discoveryId,
  discovery,
  isNextDisabled,
  questionsMD,
  canUpdateQuestions,
  isLeadGeneration
}) => {
  const history = useHistory();
  const { questionNumber } = useParams();
  const currentQuestionNumber = parseInt(questionNumber);
  const currentAnswer = discovery?.questions?.[currentQuestionNumber]?.answer;

  const previousQuestionTooltip = s(
    "discovery.questions.previous.tooltip",
    "Previous question"
  );

  const answerQuestionTooltip = s(
    "discovery.questions.nextNotAnswered.tooltip",
    "Answer the question to continue"
  );
  const nextQuestionTooltip = s(
    "discovery.questions.nextAnswered.tooltip",
    "Next question"
  );

  const getQuestions = () => {
    let questions = [];
    let numberDisplayCounter = 1;

    for (let i = 0; i < discovery.questions.length; i++) {
      let question = discovery.questions[i];
      const questionMD = questionsMD.find(
        (q) => q.code === question.questionCode
      );

      if (question.active) {
        question.numberDisplay = numberDisplayCounter;
        numberDisplayCounter++;
      }

      question.arrayPosition = i;
      question.question = questionMD.question;
      question.compulsorySelection =
        questionMD?.visualizationOptions?.compulsorySelection || false;
      question.missingAnswerForCompulsorySelection =
        isMissingAnswerForCompulsorySelection({
          discoveryQuestion: question,
          questionMD
        });

      questions.push(question);
    }

    return questions;
  };

  const questions = getQuestions();
  const numberDisplay = questions[questionNumber].numberDisplay;
  let activeQuestions = questions.filter((question) => question.active);

  const questionText = s(
    "discovery.questions.navigator.text",
    "Question {current} of {total}",
    {
      current: numberDisplay,
      total: activeQuestions.length
    }
  );

  const questionTextStart = s(
    "discovery.questions.navigator.text.start",
    "Question"
  );

  const questionTextEnd = s(
    "discovery.questions.navigator.text.end",
    " of {total}",
    {
      total: activeQuestions.length
    }
  );

  const getPrevious = () => {
    for (let i = currentQuestionNumber - 1; i >= 0; i--) {
      if (questions[i].active) {
        return questions[i];
      }
    }

    return undefined;
  };

  const currentNotAnswered = isEmpty(currentAnswer);
  const previous = getPrevious();

  const onNextClick = () => {
    history.push(`/discoveries/${discoveryId}/questions/next`);
  };

  const onPreviousClick = () => {
    history.push(
      `/discoveries/${discoveryId}/questions/${previous?.arrayPosition}`
    );
  };

  const PreviousArrow = () => (
    <QuestionButton
      data-cy={"previous-question-link"}
      disabled={previous?.arrayPosition === undefined}
      onClick={onPreviousClick}
      tooltip={previousQuestionTooltip}
    >
      <Icon name={"arrowLeft2"} size={"large"} />
    </QuestionButton>
  );

  const nextTooltip = isNextDisabled
    ? currentNotAnswered
      ? answerQuestionTooltip
      : nextQuestionTooltip
    : undefined;

  const NextArrow = () => (
    <QuestionButton
      data-cy={"next-question-link"}
      disabled={isNextDisabled}
      onClick={onNextClick}
      tooltip={nextTooltip}
    >
      <Icon name={"arrowRight"} size={"large"} />
    </QuestionButton>
  );

  const menuItems = getQuestionNavDropdownMenuItems({
    questions,
    isNextDisabled,
    currentQuestionNumber
  });

  const onDropdownClick = ({ key }) => {
    history.push(`/discoveries/${discoveryId}/questions/${key}`);
  };

  const questionDropdown = () => (
    <Space direction={"horizontal"} size={2}>
      <Text variant={"body"} color={"gray4"}>
        {questionTextStart}
      </Text>
      <Dropdown
        menu={{ items: menuItems, onClick: onDropdownClick }}
        dropdownRender={(menuItem) => {
          return (
            <StyledDropdownMenu useFontWeightForHover={false}>
              {menuItem}
            </StyledDropdownMenu>
          );
        }}
        placement={"bottomCenter"}
      >
        <NumberDisplayButton
          type={"iconSecondary"}
          data-cy={"questions-nav-dropdown"}
          className={"up-question-nav-dropdown"}
        >
          {numberDisplay}
        </NumberDisplayButton>
      </Dropdown>
      <Text variant={"body"} color={"gray4"}>
        {questionTextEnd}
      </Text>
    </Space>
  );

  const showDropdown = canUpdateQuestions && !isLeadGeneration;

  return (
    <Space direction={"horizontal"} size={24} data-cy={"questions-nav"}>
      <PreviousArrow />
      {!showDropdown && (
        <Text variant={"body"} color={"gray4"}>
          {questionText}
        </Text>
      )}
      {showDropdown && questionDropdown()}
      <NextArrow />
    </Space>
  );
};

const NumberDisplayButton = styled(NewButton)`
  color: ${themeProp(`palette.secondary`)} !important;
  background-color: ${(props) =>
    themeProp(`components.newButton.${props.type}.backgroundHover`)} !important;
  font-size: ${themeProp("typography.body.fontSize")} !important;
  margin-right: 8px;
  margin-left: 8px;
`;

const StyledDropdownMenu = styled(DropdownMenu)`
  .ant-dropdown-menu {
    padding: 8px;
  }

  .ant-dropdown-menu-item {
    padding: 8px !important;
  }

  .ant-dropdown-menu-item:hover {
    background-color: transparent !important;

    .label-number {
      background-color: ${themeProp("palette.gray1")};
      color: ${themeProp("palette.secondary")};
    }
  }

  .ant-dropdown-menu-item-disabled:hover {
    .label-number {
      color: ${themeProp("palette.gray3")};
      background-color: ${(props) => {
        return Color(themeProp("palette.gray1")(props)).alpha(0.5);
      }};
    }
  }

  .highlighted-question .label-number {
    color: ${themeProp("palette.secondary")};
  }

  .ant-dropdown-menu-item-disabled .label-number {
    color: ${themeProp("palette.gray3")};
  }
`;

const mapStateToProps = (state) => {
  return {
    discoveryId: selectDiscoveryId(state),
    discovery: selectDiscovery(state),
    isNextDisabled: selectRequestData(
      state,
      actionType.DISCOVERY_QUESTION_SUBMIT_DISABLED_SUCCESS
    ),
    questionsMD: selectQuestions(state),
    canUpdateQuestions: hasPermission(state, Permissions.UPDATE_QUESTIONS),
    isLeadGeneration: hasPermission(state, Permissions.SEE_LEAD_GENERATION)
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps)
)(QuestionsNavigator);
