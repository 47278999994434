import PropType from "prop-types";
import JobTypes from "../../../utils/job-types";
import { useString as s } from "../../../components/StringProvider";
import { InternalServerError } from "../../ErrorPage";

const DeploymentFailure = ({ type }) => {
  const messages = {};

  messages[JobTypes.MD_CONFIGURATION_DEPLOYMENT] = s(
    `deploymentJob.page.deploymentFailure.${JobTypes.MD_CONFIGURATION_DEPLOYMENT}`,
    "An error occurred while updating master data configuration."
  );

  messages[JobTypes.CRM_CONFIGURATION_DEPLOYMENT] = s(
    `deploymentJob.page.deploymentFailure.${JobTypes.CRM_CONFIGURATION_DEPLOYMENT}`,
    "An error occurred while updating CRM data configuration."
  );

  return <InternalServerError text={messages[type]} />;
};

DeploymentFailure.propTypes = {
  type: PropType.oneOf(JobTypes.ALL)
};

export default DeploymentFailure;
