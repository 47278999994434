import { Redirect, withRouter } from "react-router-dom";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import {
  isAuthenticated,
  selectLogoutLoadingState
} from "../../store/reducers";
import { useEffect } from "react";
import { logout } from "../../store/actions/authentication";
import LoadingState from "../../utils/loading-state";

const Logout = ({ isAuthenticated, loadingState, logout }) => {
  useEffect(() => {
    if (isAuthenticated) {
      logout();
    }
  }, [isAuthenticated]);

  return isAuthenticated && loadingState !== LoadingState.FAILED ? null : (
    <Redirect to={"/"} />
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    loadingState: selectLogoutLoadingState(state)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Logout);
