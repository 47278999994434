import DefinitionTypes from "../definition-types";
import { sortDiscoveryKPIsByImportance } from "../sorting";

export const impactValueKPIsFilter = (kpis = []) =>
  kpis.filter(
    (kpi) =>
      kpi.selected &&
      kpi.relevant &&
      kpi.hasImpactValue &&
      !kpi.definition.oneTimeBenefit
  );

export const impactPercentageKPIsFilter = (kpis = []) =>
  kpis.filter(
    (kpi) =>
      kpi.selected &&
      kpi.relevant &&
      !kpi.hasImpactValue &&
      kpi.hasImpactPercentage
  );

export const additionalBenefitsKPIFilter = (kpis) =>
  kpis.filter((kpi) => kpi.selected && kpi.relevant && !kpi.hasImpactValue);

export const strategicKPIsFilter = (kpis = []) =>
  kpis.filter(
    (kpi) => kpi.selected && !kpi.hasImpactValue && !kpi.hasImpactPercentage
  );

export const selectedKPIsFilter = (kpis = []) =>
  kpis.filter((kpi) => isSelectedDiscoveryKPI(kpi));

export const isSelectedDiscoveryKPI = (kpi) =>
  kpi.selected && kpi.relevant && kpi.inScope;

export const sortKPIsByImportance = ({ kpis }) => {
  kpis.sort(sortDiscoveryKPIsByImportance);
};

export const isCustomKPI = ({ discoveryKPI }) => {
  return discoveryKPI?.definition?.status === DefinitionTypes.NEW;
};

export const getValueMapKPIs = ({ discoveryKPIs }) => {
  return discoveryKPIs.filter((kpi) => kpi.relevant && kpi.inScope);
};
