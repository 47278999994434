import React, { useState } from "react";
import PropTypes from "prop-types";
import { useString as s } from "../../components/StringProvider";
import Dropdown from "../../components/Dropdown";
import NewButton from "../../components/NewButton";
import Icon from "../../components/Icon";
import Menu, { MenuItem } from "../../components/Menu";
import styled from "styled-components";
import { themeProp } from "../../theme";
import ItemTypes from "../../components/ItemTypes";

const ValueMapCardDropdown = ({
  onEdit,
  onDelete,
  onSelect,
  isSelected,
  item,
  isCBI,
  isCBIPresent,
  type,
  setCBI,
  unSetCBI
}) => {
  const editMenuText = s(`valuemap.card.dropdown.edit`, "Edit");
  const editInfoText = s(
    `valuemap.card.dropdown.edit.info`,
    "Double-click on card"
  );
  const setCBIText = s(
    `valuemap.card.dropdown.set.cbi`,
    "Set as Critical Business Issue"
  );
  const unsetCBIText = s(
    `valuemap.card.dropdown.unset.cbi`,
    "Unset Critical Business Issue"
  );
  const replaceCBIText = s(
    `valuemap.card.dropdown.replace.cbi`,
    "Replace Critical Business Issue"
  );
  const unselectText = s(`valuemap.card.dropdown.unselect`, "Unselect");
  const selectText = s(`valuemap.card.dropdown.select`, "Select");
  const deleteCustomText = s(
    `valuemap.card.dropdown.delete.custom`,
    "Delete custom card"
  );
  const removeFromListText = s(
    `valuemap.card.dropdown.remove`,
    "Remove from list"
  );
  const toolTipText = s("valuemap.card.dropdown.tooltip", "More actions");

  const isChallenge = type === ItemTypes.VALUE_MAP_CHALLENGE;

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const menuOptions = [
    {
      key: editMenuText,
      icon: "edit",
      title: editMenuText,
      description: editInfoText,
      isVisible: true,
      isDisabled: !onEdit,
      dataCy: "valuemap-card-dropdown-edit"
    },
    {
      key: setCBIText,
      icon: "flag",
      title: setCBIText,
      description: "",
      isVisible: isChallenge && !isCBI && !isCBIPresent,
      isDisabled: !onEdit,
      dataCy: "valuemap-card-dropdown-set-cbi"
    },
    {
      key: unsetCBIText,
      icon: "flag",
      title: unsetCBIText,
      description: "",
      isVisible: isChallenge && isCBI,
      isDisabled: !onEdit,
      dataCy: "valuemap-card-dropdown-unset-cbi"
    },
    {
      key: replaceCBIText,
      icon: "flag",
      title: replaceCBIText,
      description: "",
      isVisible: isChallenge && !isCBI && isCBIPresent,
      isDisabled: !onEdit,
      dataCy: "valuemap-card-dropdown-replace-cbi"
    },
    {
      key: unselectText,
      icon: "unselect",
      title: unselectText,
      description: "",
      isVisible: isSelected,
      isDisabled: !onSelect,
      dataCy: "valuemap-card-dropdown-unselect"
    },
    {
      key: selectText,
      icon: "select",
      title: selectText,
      description: "",
      isVisible: !isSelected,
      isDisabled: !onSelect,
      dataCy: "valuemap-card-dropdown-select"
    },
    {
      key: item.custom ? deleteCustomText : removeFromListText,
      icon: "trash",
      title: item.custom ? deleteCustomText : removeFromListText,
      description: "",
      isVisible: true,
      isDisabled: !onDelete,
      dataCy: "valuemap-card-dropdown-delete"
    }
  ];

  const onClick = (e) => {
    switch (e.key) {
      case editMenuText:
        onEdit();
        break;
      case unselectText:
      case selectText:
        onSelect();
        break;
      case deleteCustomText:
      case removeFromListText:
        onDelete();
        break;
      case setCBIText:
      case replaceCBIText:
        setCBI({ item });
        break;
      case unsetCBIText:
        unSetCBI(item);
        break;
      default:
        break;
    }
  };

  const onOpenChange = (visible) => {
    setDropdownVisible(visible);
  };

  const renderMenu = () => (
    <Menu onClick={onClick}>
      {menuOptions.map(
        (option) =>
          option.isVisible && (
            <MenuItem
              key={option.key}
              disabled={option.isDisabled}
              data-cy={option.dataCy}
            >
              <Icon name={option.icon} />
              <MenuItemInner>
                <div>{option.title}</div>
                <div>{option.description}</div>
              </MenuItemInner>
            </MenuItem>
          )
      )}
    </Menu>
  );

  return (
    <Dropdown
      overlay={renderMenu()}
      placement={"bottomLeft"}
      trigger={["click"]}
      onOpenChange={onOpenChange}
    >
      <NewButton
        type={dropdownVisible ? "iconPrimary" : "iconSecondary"}
        data-cy={`valuemap-card-dropdown`}
        tooltip={toolTipText}
      >
        <Icon name={"dropdown"} />
      </NewButton>
    </Dropdown>
  );
};

const MenuItemInner = styled.div`
  width: 257px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;

  div:last-of-type {
    font-weight: ${themeProp("typography.body.fontWeight")};
    font-size: ${themeProp("typography.small.fontSize")};
    opacity: 0.5;
  }
`;

ValueMapCardDropdown.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  isCBI: PropTypes.bool.isRequired,
  type: PropTypes.oneOf([
    ItemTypes.VALUE_MAP_CHALLENGE,
    ItemTypes.VALUE_MAP_KPI
  ]),
  setCBI: PropTypes.func,
  unSetCBI: PropTypes.func
};

export default ValueMapCardDropdown;
