import styled from "styled-components";
import FadeLoader from "react-spinners/FadeLoader";
import React from "react";
import { useTheme } from "./ThemeProvider";

const Loader = () => {
  const loaderColor = useTheme("palette.primary");

  return (
    <LoaderContainer>
      <LoaderDiv>
        <FadeLoader color={loaderColor} size="50" />
      </LoaderDiv>
    </LoaderContainer>
  );
};

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
  width: 100%;
  height: 100vh;
`;
const LoaderDiv = styled.div`
  position: relative;
  height: 50px;
  width: 50px;
`;

export default Loader;
