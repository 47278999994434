import React from "react";
import styled, { css } from "styled-components";
import { themeProp } from "../theme";
import { Link } from "react-router-dom";
import { Space } from "antd";
import { TruncatedParagraph, Paragraph } from "./Typography";
import Icon from "./Icon";
import Tooltip from "./Tooltip";
import { useTheme } from "./ThemeProvider";

export const BrandBadge = ({ linkTo, onClick }) => {
  const logo = useTheme("assets.logo");

  const BrandBadgeComponent = (
    <BrandBadgeContainer showHover={linkTo}>
      <BrandBadgeInner>
        <BrandBadgeImage>
          <img src={logo} alt="brand logo" />
        </BrandBadgeImage>
      </BrandBadgeInner>
    </BrandBadgeContainer>
  );

  return (
    <>
      {linkTo ? (
        <Link to={linkTo} onClick={onClick}>
          {BrandBadgeComponent}
        </Link>
      ) : (
        <>{BrandBadgeComponent}</>
      )}
    </>
  );
};

export const AccountBadge = ({ canClick, discovery }) => {
  const accountInformation = [
    {
      id: 1,
      icon: "company",
      title: discovery.name,
      dataCy: "account-detail-name"
    },
    {
      id: 2,
      icon: "bag",
      title: discovery.opportunity,
      dataCy: "account-detail-discovery"
    },
    {
      id: 3,
      icon: "paper",
      title: discovery.version,
      dataCy: "account-detail-description"
    }
  ];

  const accountDetails = (
    <AccountDetailContainer>
      {accountInformation.map((account) => (
        <li key={account.id} data-cy={account.dataCy}>
          <Icon name={account.icon} colour={"gray3"} size={"medium"} />
          <StyledParagraph color={"gray1"}>{account.title}</StyledParagraph>
        </li>
      ))}
    </AccountDetailContainer>
  );

  const accountBadgeComponent = (
    <AccountBadgeContainer
      data-cy={"discovery-header-account-badge"}
      canClick={canClick}
    >
      <StyledIcon name="chevronRight" colour={"gray3"} />
      <TruncatedParagraph rows={1} variant={"body"} colour={"gray3"}>
        {discovery.accountName}
      </TruncatedParagraph>
    </AccountBadgeContainer>
  );

  return (
    <>
      {canClick ? (
        <Tooltip title={accountDetails} trigger={"click"}>
          {accountBadgeComponent}
        </Tooltip>
      ) : (
        <>{accountBadgeComponent}</>
      )}
    </>
  );
};

const BrandBadgeContainer = styled.div`
  height: fit-content;
  border-radius: 6px;

  ${({ showHover }) =>
    showHover &&
    css`
      padding: 0.5px 0px;

      &:hover {
        background-color: ${themeProp("palette.gray1")};
      }
    `}
`;

const BrandBadgeInner = styled.div`
  margin: 8px;
`;

const BrandBadgeImage = styled.div`
  text-decoration: none;
  display: flex;
  align-items: center;

  img {
    display: block;
    max-height: 36.5px;
    max-width: 160px;
    width: auto;
  }
`;

const AccountBadgeContainer = styled.div`
  display: flex;
  align-items: center;
  width: 122px;
  font-weight: ${themeProp("typography.small.fontWeight")};
  cursor: ${(props) => (props.canClick ? "pointer" : "not-allowed")};
  transform: translateY(-1px);

  span {
    color: ${themeProp("palette.gray3")};
  }

  .ant-typography-ellipsis {
    color: ${themeProp("palette.gray3")};
  }

  @media screen and (max-width: 1280px) {
    display: none;
  }
`;

const AccountDetailContainer = styled.ul`
  transform: translateY(5px);
  gap: 5px;
  display: flex;
  flex-direction: column;

  li {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 8px;
    text-align: left;
  }
`;

const StyledParagraph = styled(Paragraph)`
  letter-spacing: none;
  font-family: ${themeProp("typography.h5Small.fontFamily")};
  font-weight: ${themeProp("typography.h5Small.fontWeight")};
`;

const StyledIcon = styled(Icon)`
  height: 20px;
  width: 20px;
  display: grid;
  place-items: center;
  margin-right: 2px;

  svg {
    height: 10px;
  }
`;

export const HeaderMiddleSection = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 32px;
  padding: 0 32px;

  @media screen and (max-width: 1028px) {
    column-gap: 24px;
    padding: 0px;
  }
`;

export const HeaderRightSection = styled(Space)`
  display: flex;
  justify-content: flex-end;
  height: 100%;
  padding-top: 15px;
  align-self: flex-end;
  align-items: baseline;
`;

const Header = styled.div`
  height: 80px;
  top: 0;
  position: fixed;
  z-index: 10;
  padding: 0px 32px 0px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${themeProp("palette.surface")};
  box-shadow: 0 0 4px rgba(5, 37, 67, 0.15);
  width: 100%;

  body.scrollbar-gap & {
    padding-right: ${(props) =>
      themeProp("constants.scrollBarWidth")(props) + 40}px;
  }

  &.mobile {
    height: 64px;
  }

  @media print {
    position: absolute;
  }

  @media screen and (max-width: 1280px) {
    padding: 0px 20px 0px 14px;
  }
`;

export const HeaderMenuItem = styled.div`
  color: ${themeProp("palette.gray3")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-top: 30px;
  font-size: ${themeProp("typography.body.fontSize", "16px")};
  font-family: ${themeProp("typography.label.fontFamily", "sans-serif")};
  font-weight: bold;
  line-height: 22px;
  white-space: nowrap;

  .active {
    color: ${themeProp("palette.secondary")};
  }
  .completed {
    color: ${themeProp("palette.secondary")};

    .icon {
      color: ${themeProp("palette.secondary")};
    }
  }
  .ondeck {
    color: ${themeProp("palette.secondary")};
  }

  @media print {
    color: ${themeProp("palette.secondary")};
  }
`;

export default Header;
