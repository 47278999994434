import { useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import PropTypes from "prop-types";
import ValueMapTextForm from "./ValueMapTextForm";
import ItemTypes from "../../components/ItemTypes";
import {
  DropContainer,
  ValueMapCardContainer,
  TickContainer
} from "./ValueMapCardUI";
import classNames from "classnames";
import ValueMapCardText from "./ValueMapCardText";
import { Icon } from "../../components";
import styled from "styled-components";
import ValueMapCardTickButton from "./ValueMapCardTickButton";
import CustomDragLayer from "./CustomDragLayer";
import useComponentDimensions from "../../utils/use-component-dimensions";
import ValueMapCardPreview from "./ValueMapCardPreview";
import {
  sendUserPilotEvent,
  UserPilotEventNames
} from "../../utils/user-pilot-util";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  selectDiscoveryId,
  selectDiscoveryBusinessCriticalChallengeCode
} from "../../store/reducers";
import ValueMapCardDropdown from "./ValueMapCardDropdown";
import { CommentsButton } from "../../components/Discovery/Comments";

export const DropDirection = {
  TOP: "top",
  BOTTOM: "bottom",
  KEY_OBJECTIVE: "keyObjective",
  REMOVE_KEY_OBJECTIVE: "removeKeyObjective"
};

const ValueMapCard = ({
  type,
  item,
  onToggleSelect,
  onSave,
  onReorder,
  setIsGrabbed,
  discoveryId,
  onDelete,
  businessCriticalChallengeCode,
  onChangeKeyObjective
}) => {
  const [editMode, setEditMode] = useState(false);
  const [text, setText] = useState("");
  const [selected, setSelected] = useState(false);
  const [direction, setDirection] = useState("");
  const [isMounted, setIsMounted] = useState(false);
  const [viewContainerHeight, setViewContainerHeight] = useState(0);

  const code = item.code;

  const ref = useRef(null);
  const directionRef = useRef("");
  const valueMapCardTextRef = useRef(null);

  directionRef.current = direction;

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsMounted(true);
    }, 100);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    setText(item.text);
    setSelected(item.selected);
  }, [item]);

  const clickHandler = (event) => {
    if (event.detail === 2) {
      setEditMode(true);
    }
  };

  const onDrop = (item) => {
    onReorder({
      code: item.code,
      destinationCode: code,
      direction: directionRef.current
    });

    sendUserPilotEvent({
      eventName:
        type === ItemTypes.VALUE_MAP_CHALLENGE
          ? UserPilotEventNames.DRAG_AND_DROP_CHALLENGE
          : UserPilotEventNames.DRAG_AND_DROP_OUTCOME,
      data: { discoveryId, code: item.code }
    });
  };

  const onCancel = () => {
    setEditMode(false);
  };

  const onSubmit = ({ text }) => {
    if (text && text.trim()) {
      setText(text.trim());
      onSave({ text: text.trim() });

      sendUserPilotEvent({
        eventName:
          type === ItemTypes.VALUE_MAP_CHALLENGE
            ? UserPilotEventNames.EDIT_TEXT_CHALLENGE
            : UserPilotEventNames.EDIT_TEXT_OUTCOME,
        data: { discoveryId, code: item.code }
      });
    }

    setEditMode(false);
  };

  const [{ isDragging }, dragRef, preview] = useDrag(() => ({
    type,
    item: { code },
    collect: (monitor) => ({
      isDragging:
        monitor.isDragging() &&
        monitor.getItemType() === type &&
        monitor.getItem()?.code === code
    })
  }));

  const [{ canDrop, isOver }, dropRef] = useDrop(() => ({
    accept: type,
    drop: onDrop,
    collect: (monitor) => ({
      canDrop: !!monitor.canDrop(),
      isOver: !!monitor.isOver()
    }),
    hover: (item, monitor) => {
      if (!ref.current || item.id === code) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      setDirection(
        hoverClientY > hoverMiddleY ? DropDirection.BOTTOM : DropDirection.TOP
      );
    }
  }));

  const dndRef = dragRef(ref);
  const dimensions = useComponentDimensions(ref);
  const { height: valueMapCardTextHeight } =
    useComponentDimensions(valueMapCardTextRef);

  const onToggleSelectInternal = () => {
    setSelected(!selected);
    onToggleSelect(!selected);
  };

  useEffect(() => {
    if (valueMapCardTextRef.current) {
      setViewContainerHeight(valueMapCardTextHeight);
    }
  }, [valueMapCardTextHeight]);

  return (
    <>
      <Container
        ref={dropRef}
        className={classNames({
          "active-bottom":
            canDrop && isOver && direction === DropDirection.BOTTOM,
          editing: editMode,
          mounted: isMounted
        })}
      >
        <HideDefaultPreview ref={preview} />
        <DropContainer />
        <ValueMapCardContainer
          className={classNames({
            selected,
            editing: editMode,
            dragging: isDragging,
            "active-top": canDrop && isOver && direction === DropDirection.TOP,
            "active-bottom":
              canDrop && isOver && direction === DropDirection.BOTTOM
          })}
          onClick={clickHandler}
          ref={dndRef}
          onMouseLeave={() => setIsGrabbed(false)}
          data-cy={`${type}-${code}`}
        >
          <TickContainer>
            <ValueMapCardTickButton
              data-cy={`${type}-${code}-tick`}
              className={
                type === ItemTypes.VALUE_MAP_CHALLENGE
                  ? "up-canvas-tick-challenges"
                  : "up-canvas-tick-outcomes"
              }
              onClick={!editMode ? onToggleSelectInternal : undefined}
            >
              <Icon name={"tick"} size={"large"} />
            </ValueMapCardTickButton>
          </TickContainer>
          {editMode ? (
            <ValueMapTextForm
              text={text}
              onCancel={onCancel}
              onSubmit={onSubmit}
              minHeight={viewContainerHeight}
            />
          ) : (
            <ValueMapCardText
              text={text}
              type={type}
              code={code}
              maxRows={2}
              ref={valueMapCardTextRef}
            />
          )}
          <CardMenu>
            <CommentsButton
              topic={`/discoveries/${discoveryId}/${type}s/${code}`}
              commentPanelMaxHeight={220}
              eventName={
                type === ItemTypes.VALUE_MAP_CHALLENGE
                  ? UserPilotEventNames.ADD_NEW_COMMENT_CANVAS_CHALLENGE
                  : UserPilotEventNames.ADD_NEW_COMMENT_CANVAS_OUTCOME
              }
            />
            <ValueMapCardDropdown
              onEdit={() => setEditMode(true)}
              onDelete={() => onDelete({ item })}
              onSelect={!editMode ? onToggleSelectInternal : undefined}
              isSelected={selected}
              item={item}
              isCBI={code === businessCriticalChallengeCode}
              isCBIPresent={Boolean(businessCriticalChallengeCode)}
              type={type}
              setCBI={onChangeKeyObjective}
              unSetCBI={onDrop}
            />
          </CardMenu>
        </ValueMapCardContainer>
      </Container>
      {isDragging && (
        <CustomDragLayer dimensions={dimensions} cardItem={item}>
          <ValueMapCardPreview
            text={text}
            type={type}
            code={code}
            selected={selected}
            isDragging={isDragging}
            canDrop={canDrop}
            isOver={isOver}
            direction={direction}
            DropDirection={DropDirection}
            discoveryId={discoveryId}
          />
        </CustomDragLayer>
      )}
    </>
  );
};

export const Container = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  position: relative;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.editing {
    max-height: fit-content;
    min-height: fit-content;
  }

  &.mounted {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
`;

export const HideDefaultPreview = styled.div`
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
`;

export const CardMenu = styled.div`
  display: flex;
`;

ValueMapCard.propTypes = {
  type: PropTypes.oneOf([
    ItemTypes.VALUE_MAP_CHALLENGE,
    ItemTypes.VALUE_MAP_KPI
  ]),
  item: PropTypes.object.isRequired,
  onToggleSelect: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
  setIsGrabbed: PropTypes.func.isRequired,
  onChangeKeyObjective: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    discoveryId: selectDiscoveryId(state),
    businessCriticalChallengeCode:
      selectDiscoveryBusinessCriticalChallengeCode(state)
  };
};

export default compose(connect(mapStateToProps))(ValueMapCard);
