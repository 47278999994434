import Form, { FormItem } from "../../../../components/Form";
import { useString as s } from "../../../../components/StringProvider";
import PropTypes from "prop-types";
import { validate } from "email-validator";
import { EmailInput } from "../../../../components/Input";
import Input from "../../../../components/Input";
import ButtonGroup from "../../../../components/ButtonGroup";
import NewButton from "../../../../components/NewButton";
import SelectRoles from "./SelectRoles";
import { useSetting } from "../../../../components/SettingsProvider";
import * as userTypes from "../../../../utils/user-types";
import Select from "../../../../components/Select";

const UserForm = ({
  form,
  onFinish,
  submitButtonText,
  children,
  emailDisabled,
  onValuesChange,
  disabled,
  userType,
  createMode,
  isSalesforceData,
  isLoading
}) => {
  const countries = useSetting("users.countries");

  const emailText = s("accessManagement.page.userForm.email", "Email");
  const emailPlaceholder = s(
    "accessManagement.page.userForm.email.placeholder",
    "Your email"
  );
  const emailValidation = s(
    "accessManagement.page.userForm.email.validation",
    "Please add a valid email"
  );
  const firstNameText = s(
    "accessManagement.page.userForm.first.name",
    "First Name"
  );
  const firstNamePlaceholder = s(
    "accessManagement.page.userForm.first.name.placeholder",
    "Enter first name"
  );
  const firstNameValidation = s(
    "accessManagement.page.userForm.first.name.validation",
    "Please add a first name"
  );
  const lastNameText = s(
    "accessManagement.page.userForm.last.name",
    "Last Name"
  );
  const lastNamePlaceholder = s(
    "accessManagement.page.userForm.last.name.placeholder",
    "Enter last name"
  );
  const lastNameValidation = s(
    "accessManagement.page.userForm.first.name.validation",
    "Please add a last name"
  );
  const jobTitleText = s(
    "accessManagement.page.userForm.job.title",
    "Job Title"
  );
  const jobTitlePlaceholder = s(
    "accessManagement.page.userForm.job.title.placeholder",
    "Enter job title"
  );
  const countryText = s(
    "accessManagement.page.userForm.country",
    "Country / Region"
  );
  const countryPlaceholder = s(
    "accessManagement.page.userForm.country.placeholder",
    "Choose country / region"
  );
  const roleText = s("accessManagement.page.userForm.role", "Role");

  const countriesOptions = countries?.map((country) => {
    return { key: country, value: country, label: country };
  });

  return (
    <Form
      form={form}
      layout={"vertical"}
      onFinish={onFinish}
      name={"userForm"}
      onValuesChange={onValuesChange}
    >
      <FormItem
        name={"email"}
        label={emailText}
        rules={[
          () => ({
            validator(_, value) {
              if (!validate(value)) {
                return Promise.reject(new Error(emailValidation));
              }

              return Promise.resolve();
            }
          })
        ]}
      >
        <EmailInput
          placeholder={emailPlaceholder}
          disabled={emailDisabled || isSalesforceData || isLoading}
        />
      </FormItem>
      {userType === userTypes.INTERNAL && (
        <>
          <FormItem
            label={firstNameText}
            name={"firstName"}
            rules={[
              {
                required: createMode,
                message: firstNameValidation,
                whitespace: true
              }
            ]}
          >
            <Input
              placeholder={firstNamePlaceholder}
              disabled={isSalesforceData || isLoading}
            />
          </FormItem>
          <FormItem
            label={lastNameText}
            name={"lastName"}
            rules={[
              {
                required: createMode,
                message: lastNameValidation,
                whitespace: true
              }
            ]}
          >
            <Input
              placeholder={lastNamePlaceholder}
              disabled={isSalesforceData || isLoading}
            />
          </FormItem>
          <FormItem label={jobTitleText} name={"jobTitle"}>
            <Input placeholder={jobTitlePlaceholder} disabled={isLoading} />
          </FormItem>
          {!!countries?.length && (
            <FormItem
              label={countryText}
              name={"country"}
              rules={[
                {
                  whitespace: true
                }
              ]}
            >
              <Select
                options={countriesOptions}
                placeholder={countryPlaceholder}
                disabled={isLoading}
              />
            </FormItem>
          )}
        </>
      )}
      <FormItem label={roleText} name={"roleIds"}>
        <SelectRoles disabled={isLoading} />
      </FormItem>
      <FormItem>
        <ButtonGroup>
          <NewButton
            type={"submit"}
            data-cy={"edit-form-save"}
            disabled={disabled}
            loading={isLoading}
          >
            {isLoading ? submitButtonText.loading : submitButtonText.default}
          </NewButton>
          {!isLoading && children}
        </ButtonGroup>
      </FormItem>
    </Form>
  );
};

UserForm.propTypes = {
  form: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
  submitButtonText: PropTypes.object.isRequired,
  emailDisabled: PropTypes.bool,
  onValuesChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  userType: PropTypes.string.isRequired,
  createMode: PropTypes.bool,
  isSalesforceData: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired
};

UserForm.defaultProps = {
  emailDisabled: false,
  createMode: false,
  isSalesforceData: false
};

export default UserForm;
