import types from "../actionTypes";
import LoadingState from "../../utils/loading-state";
import getDomain from "../../utils/get-domain";

const INITIAL_STATE = {
  loginLoadingState: LoadingState.UNINITIALIZED,
  tokenRefreshLoadingState: LoadingState.UNINITIALIZED,
  refreshCall: null,
  initializationError: null,
  isInitialLoginComplete: false,
  isAuthenticated: false,
  isLoginWithUnconfirmedUser: false,
  isSignupWithExistingUser: false,
  token: null,
  userInfoLoadingState: LoadingState.UNINITIALIZED,
  user: {},
  userInfoError: null,
  isUserRegistered: false,
  registrationLoadingState: LoadingState.UNINITIALIZED,
  registeredUsername: null,
  registrationError: null,
  isConfirmationResent: false,
  confirmationError: null,
  logoutLoadingState: LoadingState.UNINITIALIZED,
  logoutError: null,
  forgotPasswordEmail: null,
  forgotPasswordLoadingState: LoadingState.UNINITIALIZED,
  forgotPasswordError: null
};

const authentication = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.INITIAL_LOGIN_COMPLETE:
      console.log(action.type);
      return {
        ...state,
        isAuthenticated: action.payload?.token != null,
        token: action.payload?.token,
        isInitialLoginComplete: true
      };
    case types.INITIALIZATION_FAILURE:
      console.log(action.type);
      return {
        ...state,
        initializationError: action.payload.error
      };

    case types.LOGIN_REQUEST:
      console.log(action.type);
      return {
        ...state,
        isAuthenticated: false,
        loginLoadingState: LoadingState.IN_PROGRESS,
        isLoginWithUnconfirmedUser: false
      };
    case types.LOGIN_SUCCESS:
      console.log(action.type);
      return {
        ...state,
        token: action.payload?.token,
        isAuthenticated: true,
        loginLoadingState: LoadingState.COMPLETED
      };
    case types.LOGIN_FAILURE:
      console.log(action.type);
      return {
        ...state,
        isAuthenticated: false,
        loginLoadingState: LoadingState.FAILED,
        isLoginWithUnconfirmedUser: action.payload.isLoginWithUnconfirmedUser,
        registeredUsername: action.payload.username
      };

    case types.USER_INFO_REQUEST:
      console.log(action.type);
      return {
        ...state,
        userInfoLoadingState: LoadingState.IN_PROGRESS,
        userInfoError: null
      };
    case types.USER_INFO_SUCCESS:
      console.log(action.type);
      return {
        ...state,
        userInfoLoadingState: LoadingState.COMPLETED,
        user: action.payload.user
      };
    case types.UPDATE_USER_SUCCESS:
      console.log(action.type);

      return {
        ...state,
        user:
          state?.user?.userId === action.payload.userId
            ? { ...state.user, ...action.payload }
            : state.user
      };
    case types.USER_INFO_FAILURE:
      console.log(action.type);
      return {
        ...state,
        userInfoLoadingState: LoadingState.FAILED,
        user: {},
        userInfoError: action.payload.error
      };

    case types.TOKEN_REFRESH_REQUEST:
      console.log(action.type);
      return {
        ...state,
        tokenRefreshLoadingState: LoadingState.IN_PROGRESS,
        refreshCall: action.payload.refreshCall
      };
    case types.TOKEN_REFRESH_SUCCESS:
      console.log(action.type);
      return {
        ...state,
        tokenRefreshLoadingState: LoadingState.COMPLETED,
        token: action.payload?.token,
        refreshCall: null
      };
    case types.TOKEN_REFRESH_FAILURE:
      console.log(action.type);
      return {
        ...state,
        tokenRefreshLoadingState: LoadingState.FAILED,
        token: undefined,
        isAuthenticated: false,
        refreshCall: null
      };

    case types.REGISTER_USER_REQUEST:
      console.log(action.type);
      return {
        ...state,
        registeredUsername: null,
        isSignupWithExistingUser: false
      };
    case types.REGISTER_USER_SUCCESS:
      console.log(action.type);
      return {
        ...state,
        registeredUsername: action.payload.username
      };
    case types.REGISTER_USER_FAILURE:
      console.log(action.type);
      return {
        ...state
      };

    case types.RESEND_CONFIRMATION_REQUEST:
      console.log(action.type);
      return {
        ...state,
        isConfirmationResent: false,
        confirmationError: null
      };
    case types.RESEND_CONFIRMATION_SUCCESS:
      console.log(action.type);
      return {
        ...state,
        isConfirmationResent: true
      };
    case types.RESEND_CONFIRMATION_FAILURE:
      console.log(action.type);
      return {
        ...state,
        confirmationError: action.payload.error
      };

    case types.LOGOUT_REQUEST:
      console.log(action.type);
      return {
        ...state,
        logoutLoadingState: LoadingState.IN_PROGRESS,
        logoutError: null
      };

    case types.LOGOUT_FAILURE:
      console.log(action.type);
      return {
        ...state,
        logoutLoadingState: LoadingState.FAILED,
        logoutError: action.payload.error
      };

    case types.LOGOUT_SUCCESS:
      console.log(action.type);
      return {
        ...state,
        logoutLoadingState: LoadingState.COMPLETED,
        isAuthenticated: false,
        userSettings: {},
        user: null,
        token: null,
        roles: []
      };

    case types.FORGOT_PASSWORD_REQUEST:
      console.log(action.type);
      return {
        ...state,
        forgotPasswordLoadingState: LoadingState.IN_PROGRESS,
        forgotPasswordError: null
      };

    case types.FORGOT_PASSWORD_FAILURE:
      console.log(action.type);
      return {
        ...state,
        forgotPasswordLoadingState: LoadingState.FAILED,
        forgotPasswordError: action.payload.error
      };

    case types.FORGOT_PASSWORD_SUCCESS:
      console.log(action.type);
      return {
        ...state,
        forgotPasswordLoadingState: LoadingState.COMPLETED,
        forgotPasswordError: null,
        forgotPasswordEmail: action.payload.email
      };

    case types.RESET_PASSWORD_REQUEST:
      console.log(action.type);
      return {
        ...state
      };

    case types.RESET_PASSWORD_FAILURE:
      console.log(action.type);
      return {
        ...state
      };

    case types.RESET_PASSWORD_SUCCESS:
      console.log(action.type);
      return {
        ...state,
        resetPasswordLoadingState: LoadingState.COMPLETED,
        resetPasswordError: null
      };

    default:
      return state;
  }
};

export default authentication;

export const selectInitializationError = (authentication) =>
  authentication.initializationError;
export const selectRegistrationError = (authentication) =>
  authentication.registrationError;
export const isAuthenticated = (authentication) =>
  authentication.isAuthenticated;
export const selectLoginLoadingState = (authentication) =>
  authentication.loginLoadingState;
export const selectUser = (authentication) => authentication.user;
export const selectIsCuvamaUser = (authentication) => {
  const emailDomain = getDomain({ email: authentication?.user?.email });
  return emailDomain?.includes("cuvama.com");
};
export const selectUserId = (authentication) =>
  authentication.user ? authentication.user.userId : undefined;
export const selectAllPermissions = (authentication) =>
  authentication?.user?.permissions;
export const hasPermission = (authentication, permission) =>
  authentication?.user?.permissions
    ? authentication.user.permissions.includes(permission)
    : false;
export const selectLogoutLoadingState = (authentication) =>
  authentication.logoutLoadingState;
export const selectUserInfoLoadingState = (authentication) =>
  authentication.userInfoLoadingState;
export const selectUserInfoError = (authentication) =>
  authentication.userInfoError;
export const selectForgotPasswordLoadingState = (authentication) =>
  authentication.forgotPasswordLoadingState;
export const selectForgotPasswordError = (authentication) =>
  authentication.forgotPasswordError;
export const selectForgotPasswordEmail = (authentication) =>
  authentication.forgotPasswordEmail;
export const isLoginWithUnconfirmedUser = (authentication) =>
  authentication.isLoginWithUnconfirmedUser;
export const isSignupWithExistingUser = (authentication) =>
  authentication.isSignupWithExistingUser;
export const selectConfirmationError = (authentication) =>
  authentication.confirmationError;
export const selectRegisteredUsername = (authentication) =>
  authentication.registeredUsername;
export const selectRegistrationLoadingState = (authentication) =>
  authentication.registrationLoadingState;
export const isInitialLoginComplete = (authentication) =>
  authentication.isInitialLoginComplete;
