import IntegrationNames from "../../../../utils/constants/integration-names";
import logsService from "../../../../services/errors.service";

const handleOAuthRedirect = ({
  baseUrl,
  clientId,
  name,
  salesforceIntegrationV2
}) => {
  let url;

  switch (name) {
    case IntegrationNames.HUBSPOT:
      url = new URL(`${baseUrl}/oauth/authorize`);

      url.searchParams.append("response_type", "code");
      url.searchParams.append("client_id", clientId);
      url.searchParams.append(
        "scope",
        "crm.objects.deals.read crm.objects.deals.write"
      );
      url.searchParams.append(
        "redirect_uri",
        `${window.location.origin}/integrations/${name}/callback`
      );
      break;

    case IntegrationNames.SALESFORCE:
      if (salesforceIntegrationV2) {
        url = new URL(`https://test.salesforce.com/services/oauth2/authorize`);
      } else {
        url = new URL(`${baseUrl}/services/oauth2/authorize`);
      }

      url.searchParams.append("response_type", "code");
      url.searchParams.append("client_id", clientId);

      if (salesforceIntegrationV2) {
        url.searchParams.append(
          "redirect_uri",
          `${window.location.origin}/integrations/${name}/callback`
        );
      } else {
        url.searchParams.append(
          "redirect_uri",
          `${window.location.origin}/organizations/general/salesforce-connection-callback`
        );
      }
      break;
  }

  logsService.logInfo({
    category: "integration-connection",
    data: `handleOAuthRedirect: ${url}`
  });

  window.location.href = url.toString();
};

export default handleOAuthRedirect;
