import styled from "styled-components";
import { selectCategory } from "../../../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import React, { useContext } from "react";
import { SettingsContext } from "../../../../components/SettingsProvider";
import { getValue } from "../../value-hypothesis-util";
import { formatRangeNumber } from "../../../../utils/formatting";
import KPIImpactFormats from "../../../../utils/kpi-impact-formats";
import PropTypes from "prop-types";
import { TruncatedTitle } from "../../../../components/Typography";
import MetricTypes from "../../../../utils/metric-types";

const getText = ({ kpi, scenario, delayTimeline, currency }) => {
  const { settings } = useContext(SettingsContext);
  const { locale } = settings;
  const value = getValue({ kpi, scenario, delayTimeline });

  return kpi.hasImpactValue
    ? formatRangeNumber({
        value,
        type: MetricTypes.CURRENCY,
        options: { locale, currency, notation: "compact" }
      })
    : formatRangeNumber({
        value:
          kpi.definition.impactFormat === KPIImpactFormats.MULTIPLIER ||
          kpi.definition.impactFormat === KPIImpactFormats.PERCENTAGE
            ? Math.round(value)
            : value,
        type:
          kpi.definition.impactFormat === KPIImpactFormats.MULTIPLIER
            ? KPIImpactFormats.PERCENTAGE
            : kpi.definition.impactFormat,
        options: {
          locale,
          currency,
          showPlusSign: true,
          showUnits: true,
          customImpactFormat: kpi.definition.customImpactFormat,
          notation: "compact"
        }
      });
};

const KPIColumn = ({
  discoveryKPI,
  index,
  category,
  currency,
  scenario,
  delayTimeline
}) => {
  const color =
    category &&
    (discoveryKPI.hasImpactValue ? category.color : category.chartColor);
  const borderColor =
    category &&
    (discoveryKPI.hasImpactValue ? undefined : category.chartBorderColor);
  const text = getText({
    kpi: discoveryKPI,
    scenario,
    delayTimeline,
    currency
  });

  return (
    <>
      <TitleContainer index={index} className={"title"}>
        <TruncatedTitle
          fullText={discoveryKPI.definition.name}
          level={5}
          color={"gray3"}
        >
          {discoveryKPI.definition.name}
        </TruncatedTitle>
      </TitleContainer>
      <ColorContainer
        index={index}
        className={"color"}
        color={color}
        borderColor={borderColor}
      ></ColorContainer>
      <AmountContainer index={index} className={"amount"}>
        <TruncatedTitle fullText={text} level={4} rows={2} placement={"bottom"}>
          {text}
        </TruncatedTitle>
      </AmountContainer>
    </>
  );
};

const TitleContainer = styled.div`
  grid-row-start: 1;
  grid-row-end: span ${({ index }) => index + 1};
  grid-column-start: ${({ index }) => index + 1};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  & h5 {
    text-align: center;
    font-size: 11px;
  }

  & h5 .ant-typography {
    margin-bottom: 0.5em;
  }
`;

const ColorContainer = styled.div`
  grid-row-start: ${({ index }) => index + 2};
  grid-column-start: ${({ index }) => index + 1};
  background-color: ${(props) => props.color};
  border-radius: 4px;
  border: 1px dashed
    ${(props) => (props.borderColor ? props.borderColor : "transparent")};
`;

const AmountContainer = styled.div`
  grid-row-start: ${({ index }) => index + 3};
  grid-row-end: -1;
  grid-column-start: ${({ index }) => index + 1};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  & .ant-typography,
  & h4 {
    text-align: center;
    margin-bottom: 0;
  }
`;

KPIColumn.propTypes = {
  discoveryKPI: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  scenario: PropTypes.number.isRequired,
  delayTimeline: PropTypes.string.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    category: selectCategory(state, props.discoveryKPI.definition.categoryCode)
  };
};

export default compose(connect(mapStateToProps))(KPIColumn);
