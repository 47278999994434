import Form, { FormItem } from "../../../../components/Form";
import { useString as s } from "../../../../components/StringProvider";
import Input from "../../../../components/Input";
import { Space } from "antd";
import NewButton from "../../../../components/NewButton";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Icon from "../../../../components/Icon";
import PasswordInput from "../../../../components/Input/PasswordInput";

const SalesforceConnectionForm = ({
  salesforceConfiguration,
  readOnly,
  onFinish,
  onDisconnect,
  onCancel,
  onEdit
}) => {
  const [form] = Form.useForm();
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [baseUrlValid, setBaseUrlValid] = useState(false);
  const [clientIdValid, setClientIdValid] = useState(false);
  const [clientSecretValid, setClientSecretValid] = useState(false);
  const baseUrlLabel = s(
    "generalAdmin.page.salesforceConnection.baseUrl.label",
    "Salesforce Base URL"
  );
  const baseUrlValidation = s(
    "generalAdmin.page.salesforceConnection.baseUrl.validation",
    "Please input Salesforce Base URL"
  );
  const baseUrlPlaceholder = s(
    "generalAdmin.page.salesforceConnection.baseUrl.placeholder",
    "Add your URL"
  );
  const clientIdLabel = s(
    "generalAdmin.page.salesforceConnection.clientId.label",
    "Client ID"
  );
  const clientIdValidation = s(
    "generalAdmin.page.salesforceConnection.clientId.validation",
    "Please input Client ID"
  );
  const clientIdPlaceholder = s(
    "generalAdmin.page.salesforceConnection.clientId.placeholder",
    "Add your Client ID"
  );
  const clientSecretLabel = s(
    "generalAdmin.page.salesforceConnection.clientSecret.label",
    "Client Secret"
  );
  const clientSecretValidation = s(
    "generalAdmin.page.salesforceConnection.clientSecret.validation",
    "Please input Client Secret"
  );
  const clientSecretPlaceholder = s(
    "generalAdmin.page.salesforceConnection.clientSecret.placeholder",
    "Add your Client Secret"
  );
  const okCreate = s(
    "generalAdmin.page.salesforceConnection.create.label",
    "Connect Salesforce"
  );
  const okEdit = s(
    "generalAdmin.page.salesforceConnection.edit.label",
    "Update"
  );
  const disconnect = s(
    "generalAdmin.page.salesforceConnection.disconnect.label",
    "Disconnect"
  );
  const cancel = s(
    "generalAdmin.page.salesforceConnection.cancel.label",
    "Cancel"
  );
  const edit = s("generalAdmin.page.salesforceConnection.edit.label", "Edit");

  useEffect(() => {
    setSubmitEnabled(baseUrlValid && clientIdValid && clientSecretValid);
  }, [baseUrlValid, clientIdValid, clientSecretValid]);

  useEffect(() => {
    if (salesforceConfiguration) {
      form.setFieldsValue(salesforceConfiguration);
      onValuesChange(salesforceConfiguration);
    } else {
      form.resetFields();
      setBaseUrlValid(false);
      setClientIdValid(false);
      setClientSecretValid(false);
    }
  }, [salesforceConfiguration]);

  const onValuesChange = (values) => {
    if (values.hasOwnProperty("baseUrl")) {
      setBaseUrlValid(values["baseUrl"] && values["baseUrl"].trim());
    }

    if (values.hasOwnProperty("clientId")) {
      setClientIdValid(values["clientId"] && values["clientId"].trim());
    }

    if (values.hasOwnProperty("clientSecret")) {
      setClientSecretValid(
        values["clientSecret"] && values["clientSecret"].trim()
      );
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onValuesChange={onValuesChange}
    >
      <FormItem
        label={baseUrlLabel}
        name="baseUrl"
        rules={[
          {
            required: true,
            message: baseUrlValidation,
            whitespace: true
          }
        ]}
      >
        <Input block placeholder={baseUrlPlaceholder} disabled={readOnly} />
      </FormItem>
      <FormItem
        label={clientIdLabel}
        name="clientId"
        rules={[
          {
            required: true,
            message: clientIdValidation,
            whitespace: true
          }
        ]}
      >
        <Input block placeholder={clientIdPlaceholder} disabled={readOnly} />
      </FormItem>
      <FormItem
        label={clientSecretLabel}
        name="clientSecret"
        rules={[
          {
            required: true,
            message: clientSecretValidation,
            whitespace: true
          }
        ]}
      >
        <PasswordInput
          block
          placeholder={clientSecretPlaceholder}
          disabled={readOnly}
          visibilityToggle={false}
        />
      </FormItem>

      <FormItem>
        {readOnly ? (
          <NewButton type={"text"} onClick={onEdit}>
            <Icon name={"edit"} /> {edit}
          </NewButton>
        ) : salesforceConfiguration ? (
          <ButtonBar>
            <Space size={16}>
              <NewButton type={"submit"} disabled={!submitEnabled}>
                {okEdit}
              </NewButton>
              <NewButton type={"text"} onClick={onCancel}>
                {cancel}
              </NewButton>
            </Space>
            <NewButton type={"text"} onClick={onDisconnect}>
              <Icon name={"close"} /> {disconnect}
            </NewButton>
          </ButtonBar>
        ) : (
          <Space size={16}>
            <NewButton type={"submit"} disabled={!submitEnabled}>
              {okCreate}
            </NewButton>
          </Space>
        )}
      </FormItem>
    </Form>
  );
};

const ButtonBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export default SalesforceConnectionForm;
