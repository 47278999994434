import React from "react";
import PropTypes from "prop-types";
import { message } from "antd";
import { useClipboard } from "use-clipboard-copy";

// Browser Support: Chrome, Firefox, Edge, Safari, IE11

const useCopyClipboard = ({
  showNotification,
  copySuccessMessage,
  copyFailedMessage
}) => {
  const clipboard = useClipboard({
    onSuccess() {
      showNotification && message.success(copySuccessMessage);
    },
    onError() {
      showNotification && message.error(copyFailedMessage);
    }
  });

  const onCopy = React.useCallback(
    (content) => {
      clipboard.copy(content);
    },
    [clipboard]
  );

  return { onCopy };
};

useCopyClipboard.propTypes = {
  showNotification: PropTypes.bool,
  copySuccessMessage: PropTypes.string,
  copyFailedMessage: PropTypes.string
};

export default useCopyClipboard;
