import styled from "styled-components";
import Text from "../../components/Text";
import Color from "color";
import { useString as s } from "../../components/StringProvider";
import { themeProp } from "../../theme";
import PropTypes from "prop-types";
import Icon from "../../components/Icon";
import ValueMapCard from "./ValueMapCard";
import ItemTypes from "../../components/ItemTypes";
import React, { useState } from "react";
import CreateCard from "./CreateCard";
import LoadingState from "../../utils/loading-state";
import useLoadingState from "../../utils/use-loading-state";
import { DropContainer } from "./ValueMapCardUI";
import { Title } from "../../components/Typography";
import classNames from "classnames";
import NewButton from "../../components/NewButton";

const ValueMapList = ({
  type,
  title,
  callToAction,
  emptyText,
  unselectedHiddenText,
  items,
  totalItemsCount,
  keyObjectiveCode,
  editMode,
  showSelectedOnly,
  onToggleEdit,
  onToggleShowSelected,
  onSaveItem,
  onCreateItem,
  onDeleteItem,
  onReorder,
  createLoadingState,
  searchLibrary,
  searchLibraryData,
  children,
  onChangeKeyObjective,
  ...props
}) => {
  const clickText = s("valueMap.page.cta.subheader", "Click to capture");
  const addChallengeTooltip = s(
    "valueMap.page.challenges.add.tooltip",
    "Add a challenge"
  );
  const addOutcomeTooltip = s(
    "valueMap.page.outcomes.add.tooltip",
    "Add an outcome"
  );
  const showAllTooltip = s("valueMap.page.showAll.tooltip", "Show all");
  const showSelectedOnlyTooltip = s(
    "valueMap.page.showSelectedOnly.tooltip",
    "Show selected only"
  );
  const backArrowTooltip = s("valueMap.page.backArrow.tooltip", "Back");

  const [create, setCreate] = useState(false);
  const [isGrabbed, setIsGrabbed] = useState(false);

  const onCreate = () => {
    setCreate(true);
  };

  useLoadingState(createLoadingState, () => {
    setCreate(false);
  });

  const _classNames = classNames({
    edit: editMode,
    editShowAll: editMode && !showSelectedOnly,
    editShowSelectedOnly: editMode && showSelectedOnly,
    grab: isGrabbed
  });

  const renderTitle = () => (
    <ListNav editMode={editMode}>
      <ActionButton className={_classNames}>
        <NewButton
          type={"iconSecondary"}
          onClick={onToggleEdit}
          data-cy={"value-map-back-button"}
          className={
            type === ItemTypes.VALUE_MAP_CHALLENGE
              ? "up-canvas-back-button-challenges"
              : "up-canvas-back-button-outcomes"
          }
          tooltip={backArrowTooltip}
        >
          <Icon name={"arrowLeft"} size={"large"} />
        </NewButton>
      </ActionButton>
      <TitleContainer>
        <Text variant={"h2"} color={"gray4"}>
          {title}
        </Text>
      </TitleContainer>
      <ActionButtons className={_classNames}>
        <ActionButton>
          <NewButton
            type={"iconSecondary"}
            onClick={onCreate}
            data-cy={"value-map-create-button"}
            className={
              type === ItemTypes.VALUE_MAP_CHALLENGE
                ? "up-canvas-create-button-challenges"
                : "up-canvas-create-button-outcomes"
            }
            tooltip={
              type === ItemTypes.VALUE_MAP_CHALLENGE
                ? addChallengeTooltip
                : addOutcomeTooltip
            }
          >
            <Icon name={"plus"} size={"large"} />
          </NewButton>
          <NewButton
            type={"iconSecondary"}
            onClick={onToggleShowSelected}
            data-cy={"value-map-show-selected-only-button"}
            tooltip={
              showSelectedOnly ? showAllTooltip : showSelectedOnlyTooltip
            }
          >
            <Icon
              name={showSelectedOnly ? "angleDown" : "angleUp"}
              size={"large"}
            />
          </NewButton>
        </ActionButton>
      </ActionButtons>
    </ListNav>
  );

  const renderEmpty = () => {
    return (
      <Empty data-cy={"value-map-empty-list"}>
        {emptyText.split("\n").map((text, index) => (
          <Title level={3} color={"gray3"} key={index}>
            {text}
          </Title>
        ))}
      </Empty>
    );
  };

  const renderUnselectedHidden = () => {
    return (
      <Empty data-cy={"value-map-unselected-hidden-list"}>
        {unselectedHiddenText.split("\n").map((text, index) => (
          <Title level={3} color={"gray3"} key={index}>
            {text}
          </Title>
        ))}
      </Empty>
    );
  };

  return (
    <Container
      className={_classNames}
      {...props}
      type={type}
      onClick={editMode ? undefined : onToggleEdit}
      onMouseDown={() => setIsGrabbed(true)}
      onMouseUp={() => setIsGrabbed(false)}
    >
      {renderTitle()}
      {!!items.length && children}
      {editMode ? (
        <CardList>
          {create && (
            <CreateCard
              onCreate={onCreateItem}
              onCancel={() => setCreate(false)}
              searchLibrary={searchLibrary}
              searchLibraryData={searchLibraryData}
            />
          )}

          {items.map((item, index) => {
            return item.code === keyObjectiveCode ? null : (
              <ValueMapCard
                type={type}
                index={index}
                key={item.code}
                code={item.code}
                item={item}
                onSave={({ text }) => {
                  onSaveItem({ item, data: { text } });
                }}
                onToggleSelect={(selected) => {
                  onSaveItem({ item, data: { selected } });
                }}
                onDelete={onDeleteItem}
                onReorder={onReorder}
                setIsGrabbed={setIsGrabbed}
                onChangeKeyObjective={onChangeKeyObjective}
              />
            );
          })}

          <div>
            <DropContainer />
          </div>
          {totalItemsCount > 0 &&
            items.length === 0 &&
            renderUnselectedHidden()}
          {!items.length && !totalItemsCount && !create && renderEmpty()}
        </CardList>
      ) : (
        <CallToActionContainer>
          <CallToActionText
            onClick={onToggleEdit}
            className={
              type === ItemTypes.VALUE_MAP_CHALLENGE
                ? "up-canvas-challenges-cta"
                : "up-canvas-outcomes-cta"
            }
          >
            <Text variant={"h3"} color={"gray3"}>
              {callToAction}
            </Text>
            <Text variant={"small"} color={"gray3"}>
              {clickText}
            </Text>
          </CallToActionText>
        </CallToActionContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 2px solid ${themeProp("palette.gray1")};
  background: ${themeProp("palette.surface")};

  &.edit {
    background-color: ${(props) => {
      const color = themeProp(
        props.type === ItemTypes.VALUE_MAP_CHALLENGE
          ? "palette.secondary"
          : "palette.primary"
      )(props);

      const rgbArray = Color(color).rgb().array();
      return `rgba(${rgbArray[0]}, ${rgbArray[1]}, ${rgbArray[2]}, 0.08)`;
    }};
  }

  &.grab {
    cursor: grabbing;
  }
`;

export const Empty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  border-radius: 6px;
  background: ${themeProp("palette.surface")};

  & h3.ant-typography {
    margin: 0;
    text-align: center;
  }
`;

export const TitleContainer = styled.div`
  height: 50px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ActionButton = styled.div`
  margin-top: 28px;
  margin-left: 16px;
  margin-right: 16px;
  visibility: hidden;
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  cursor: default;
`;

export const ListNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: default;

  .editShowAll & > div {
    visibility: visible;
  }

  .editShowAll & > div > div {
    visibility: visible;
  }

  &:hover .editShowSelectedOnly {
    visibility: visible;
  }

  &:hover .editShowSelectedOnly > div {
    visibility: visible;
  }
`;

const CallToActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-around;
`;

const CallToActionText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-left: 100px;
  margin-right: 100px;
  cursor: pointer;
`;

const CardList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px 10px;
  overflow-y: auto;
  height: 100%;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${themeProp("palette.gray4")};
  }
`;

ValueMapList.propTypes = {
  type: PropTypes.oneOf([
    ItemTypes.VALUE_MAP_CHALLENGE,
    ItemTypes.VALUE_MAP_KPI
  ]),
  keyObjectiveCode: PropTypes.string,
  title: PropTypes.string.isRequired,
  callToAction: PropTypes.string.isRequired,
  items: PropTypes.array,
  editMode: PropTypes.bool.isRequired,
  onToggleEdit: PropTypes.func.isRequired,
  onSaveItem: PropTypes.func.isRequired,
  onCreateItem: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
  searchLibrary: PropTypes.func.isRequired,
  searchLibraryData: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        categoryCode: PropTypes.string.isRequired
      })
    ).isRequired,
    categories: PropTypes.array.isRequired,
    pagination: PropTypes.shape({
      start: PropTypes.number.isRequired,
      count: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired
    }).isRequired
  }).isRequired,
  createLoadingState: PropTypes.oneOf(LoadingState.ALL),
  onChangeKeyObjective: PropTypes.func
};

export default ValueMapList;
